import { lazy } from "react";
import { User } from "../app/models/domain/users/User";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

const Main = Loadable(lazy(() => import("../app/providers/Main")));
const Patient = Loadable(lazy(() => import("../app/providers/Patient")));
const PatientDetails = Loadable(lazy(() => import("../app/providers/PatientDetails")));
const CreateOrder = Loadable(lazy(() => import("../app/providers/CreateOrder")));
const PageNotFound = Loadable(lazy(() => import("../components/ErrorPage/PageNotFound")));
const CreateDrugOrder = Loadable(lazy(() => import("../app/providers/CreateDrugOrder")));
const CreateRecordExpensesItem = Loadable(lazy(() => import("../app/providers/CreateRecordExpensesItem")));
const SupportContact = Loadable(lazy(() => import("../app/providers/SupportContact")));
const SupportManual = Loadable(lazy(() => import("../app/providers/SupportManual")));

interface Props {
    user?: User;
    onLogout: () => void;
}

const MainRoutes = (props: Props) => {
    return (
        {
            path: "/",
            element: <MainLayout user={props.user} onLogout={props.onLogout} />,
            children: [
                {
                    path: "",
                    element: <Main />
                },
                {
                    path: "patient",
                    children: [
                        {
                            path: "",
                            element: <Patient />,
                        },
                        {
                            path: ":an",
                            element: <PatientDetails />,

                        },
                        {
                            path: ":an/create-order",
                            element: <CreateOrder />
                        },
                        {
                            path: ":an/create-order/create-drug-order",
                            element: <CreateDrugOrder />
                        },
                        {
                            path: ":an/create-record-expenses-item",
                            element: <CreateRecordExpensesItem />
                        },
                    ]

                },
                {
                    path: "support",
                    children: [
                        {
                            path: "contact",
                            element: <SupportContact />
                        },
                        {
                            path: "manual",
                            element: <SupportManual />
                        },

                    ]
                },
                {
                    path: "*",
                    element: <PageNotFound />
                },
            ]
        }
    );

};

export default MainRoutes;