/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";
export interface AdmissionNoteHistoryRequest {
    /** @format uuid */
    id?: string | null;
    an?: string | null;
    /** @format date-time */
    admissionDate?: string;
    chiefComplaint?: string | null;
    /** @format int32 */
    duration?: number,
    durationUnit?: string | null,
    presentIllness?: string | null,
    pastHistory_PreviousSurgery?: boolean,
    pastHistory_PreviousSurgery_Remark?: string | null,
    pastHistory_UnderlyingDisease?: boolean,
    pastHistory_UnderlyingDisease_Remark?: string | null,
    personalHistory_Allergy?: boolean,
    personalHistory_Allergy_Remark?: string | null,
    personalHistory_Smoking?: boolean,
    personalHistory_Smoking_Amount?: number,
    personalHistory_Smoking_Amount_Unit?: string | null,
    personalHistory_Smoking_Duration?: number,
    personalHistory_Smoking_Duration_Unit?: string | null,
    personalHistory_Alcohol?: boolean,
    // personalHistory_Alcohol_Remark ยังไม่ได้ถูกนำมาใช้
    personalHistory_Alcohol_Remark?: string | null,
    personalHistory_Alcohol_Amount?: number,
    personalHistory_Alcohol_Amount_Unit?: string | null,
    personalHistory_Alcohol_Duration?: number,
    personalHistory_Alcohol_Duration_Unit?: string | null,
    personalHistory_SubstanceAbuse?: boolean,
    personalHistory_SubstanceAbuse_Remark?: string | null,
    personalHistory_Female?: boolean,
    personalHistory_Female_Remark?: string | null,
    familyHistoryNormal?: boolean,
    familyHistoryNormal_Remark?: string | null,
    npO_Time?: string | null,
    npO_KindOfFood?: string | null,
    concurrentMedicine?: string | null,
    isReviewOfSystemic?: boolean,
    reviewOfSystemic_General?: string | null,
    reviewOfSystemic_Skin?: string | null,
    reviewOfSystemic_EyeENT?: string | null,
    reviewOfSystemic_Resp?: string | null,
    reviewOfSystemic_Cardiovascular?: string | null,
    reviewOfSystemic_GI?: string | null,
    reviewOfSystemic_GU?: string | null,
    reviewOfSystemic_Neuro?: string | null,
    reviewOfSystemic_Hemato?: string | null,
    reviewOfSystemic_Endocrine?: string | null,
    reviewOfSystemic_Musculoskeletal?: string | null,
    reviewOfSystemic_Psyscho?: string | null,
    updateBy?: string,
    updateDate?: string,
    objectData?: string,
}
export interface AdmissionNoteHistoryDto {
    Id?: string;
    an: string | null;
    AdmissionDate: string;
    ChiefComplaint: string | null;
    Duration: number;
    DurationUnit: string | null;
    PresentIllness: string | null;
    PastHistory_PreviousSurgery: boolean;
    PastHistory_PreviousSurgery_Remark: string | null;
    PastHistory_UnderlyingDisease: boolean;
    PastHistory_UnderlyingDisease_Remark: string | null;
    PersonalHistory_Allergy: boolean;
    PersonalHistory_Allergy_Remark: string | null;
    PersonalHistory_Smoking: boolean;
    PersonalHistory_Smoking_Amount: number | null;
    PersonalHistory_Smoking_Amount_Unit: string | null;
    PersonalHistory_Smoking_Duration: number | null;
    PersonalHistory_Smoking_Duration_Unit: string | null;
    PersonalHistory_Alcohol: boolean;
    PersonalHistory_Alcohol_Remark: string | null;
    PersonalHistory_Alcohol_Amount: number | null;
    PersonalHistory_Alcohol_Amount_Unit: string | null;
    PersonalHistory_Alcohol_Duration: number | null;
    PersonalHistory_Alcohol_Duration_Unit: string | null;
    PersonalHistory_SubstanceAbuse: boolean;
    PersonalHistory_SubstanceAbuse_Remark: string | null;
    PersonalHistory_Female: boolean;
    PersonalHistory_Female_Remark: string | null;
    FamilyHistoryNormal: boolean;
    FamilyHistoryNormal_Remark: string | null;
    NPO_Time: string | null;
    NPO_KindOfFood: string | null;
    ConcurrentMedicine: string | null;
    IsReviewOfSystemic: boolean;
    ReviewOfSystemic_General: string | null;
    ReviewOfSystemic_Skin: string | null;
    ReviewOfSystemic_EyeENT: string | null;
    ReviewOfSystemic_Resp: string | null;
    ReviewOfSystemic_Cardiovascular: string | null;
    ReviewOfSystemic_GI: string | null;
    ReviewOfSystemic_GU: string | null;
    ReviewOfSystemic_Neuro: string | null;
    ReviewOfSystemic_Hemato: string | null;
    ReviewOfSystemic_Endocrine: string | null;
    ReviewOfSystemic_Musculoskeletal: string | null;
    ReviewOfSystemic_Psyscho: string | null;
    CreatedDate: string;
    CreatedBy: string | null;
    UpdatedDate: string | null;
    UpdatedBy: string | null;
}


export interface CurrentAdmissionNoteHistory {
    fistIndex: number; data: AdmissionNoteHistoryDto, lastIndex: number, indexAmount: number;
}

export class AdmissionRecordHistoryModel {
    private client: Client<any>;
    private state: AdmissionNoteHistoryDto[] = [];
    constructor() {
        this.state = [];
        makeAutoObservable(this);
        this.client = new Client("");
    }
    public get(an: string): Promise<AdmissionNoteHistoryDto[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("admission-record-history");
            this.client.getQuery({ query: _params }).then((res: AdmissionNoteHistoryDto[]) => {
                if (res && res.length > 0) {
                    res.forEach((x) => {
                        if (this.state.find((y) => y.an === x.an) === undefined) {
                            this.state = [...this.state, x];
                        }
                        else {
                            this.state = this.state.map((y) => {
                                if (y.an === x.an) {
                                    return x;
                                }
                                return y;
                            });
                        }
                    });
                    resolve(this.state.filter((x) => x.an === an));
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public create(data: AdmissionNoteHistoryDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client = new Client("admission-record-history");
            this.client.create({ request: data }).then(async (res: AdmissionNoteHistoryDto) => {
                await this.get(data.an!);
                resolve();
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public update(data: AdmissionNoteHistoryDto): Promise<void> {
        return new Promise((resolve, reject) => {
            const Id = data.Id;
            delete data.Id;
            this.client = new Client("admission-record-history");
            this.client
                .update({ id: Id ?? '', request: data })
                .then(async (res: any) => {
                    if (res.message) {
                        await this.get(data.an!);
                        resolve();
                    }
                }).catch((err: any) => {
                    reject(err);
                });
        });
    }
    // computed state
    public get AdmissionRecordHistories(): AdmissionNoteHistoryDto[] {
        return this.state;
    }

    // clear state has is null
    public clearState() {
        this.state = [];
    }

}
