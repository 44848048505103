import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import Client from "@/app/api/client";
export type ORREQ_SIDES = "0" | "1" | "2" | "3" | null;

/** อ้างอิงจาก SQL สำหรับสร้าง OR Request */
export interface ORREQ_H_Dto {
    REGNO?: string | null;
    RXNO?: string | null;
    REQNO?: string | null;

    LAST_DETNO?: number | 0;

    /** วันที่คำขอ => date for UI/string for graphql */
    REQDATE?: Date | string | null;
    /** เวลาคำขอ ไม่มีใน UI แต่ใช่เป็น current time */
    REQTIME?: string | null;
    /** ประเภทคำขอ */
    REQTYPE?: "E" | "N" | "U" | null;

    REQ_STAT?: string | null;
    REQWARD?: string | null;
    REQSOURCE?: string | null;

    /** วันที่ที่ต้องการผ่าตัด + วันที่ผ่าตัด (ใช้ร่วมกัน) => date for UI/string for graphql */
    REQUIRE_DATE?: Date | string | null;
    /** เวลาที่ที่ต้องการผ่าตัด */
    REQUIRE_TIME?: string | null;
    /** เวลาที่ต้องการ */
    REQUIRE_TYPE?: "S" | "F" | "T" | "C" | null;

    CFM_STAT?: string | null;
    /** ประมาณเวลา (ชม.) */
    EST_TIME?: number | null;

    HN?: string | null;
    REG_DATE?: Date | null;
    PATSTATUS?: string | null;

    /** รหัสผ่าตัด */
    ORCODE?: string | null;
    /** ชื่อผ่าตัด */
    ORDESC?: string | null;
    /** ราคารวม */
    ORPRICE?: number | null;
    /** หมายเหตุ */
    ORNOTE?: string | null;

    /** วิธีระงับความรู้สึก: n.text เช่น 0.Choice */
    ANES_TYPE?: string | null;

    /** เลขที่ห้องผ่าตัด */
    ROOM_NO?: string | null;
    /** ค่าห้องผ่าตัด */
    ROOM_PRICE?: number | null;

    OR_STDCOST?: number | null;

    /** แพทย์ผ่าตัด */
    MAINDOC?: string | null;

    /** NPO */
    NPOTEXT?: string | null;
    /** ผู้ทำรายการ */
    MAKER?: string | null;

    /** ข้างผ่าตัด */
    ORREQ_SIDE?: "0" | "1" | "2" | "3" | null;
    /** Status */
    MAJOR_FLAG?: "0" | "1" | "2" | null;
    /** รายละเอียดการผ่าตัด */
    ORREQ_HDETAIL?: string | null;
    /** เปล: N=เปลธรรมดา, O=เปลออกซิเจน, I=เปลนั่ง, S=เปลตัก */
    SURG_BED?: string | null;
    /** วิสัญญีแพทย์ */
    CHK_ANES?: string | null;
    /** การวินิจฉัยโรค */
    ORDIAGNOSISNOTE?: string | null;

    /** ในเวลา */
    IN_HOUR?: string | null;
    /** นอกเวลา */
    OFF_HOUR?: string | null;
    /** ในนอกเวลา */
    ONOFF_HOUR?: string | null;
    /** Trauma */
    TRUMAFLAG?: string | null;
    /** การจองเลือด */
    Blood_booking?: string | null;
    /** Prepaid */
    PREPAID?: string | null;
    doctorName?: string | null;
}

export interface ORREQ_HD {
    REQNO?: string | null;
    REQDET_NO?: number | null;
    ORCODE?: string | null;
    ORDESC?: string | null;
    ORPRICE?: string | null;
    OR_STDCOST?: number | null;
    EST_TIME?: number | null;
    REV?: string | null;
    REVNO?: number | null;
    ORREQ_SIDE?: string | null;
    BILL_D_NOHD?: string | null;
    ORREQ_DDETNO?: number | null;
}

export interface reqORREQ_HD extends ORREQ_HD {
    _not_use?: string | null;
}

export interface ORREQ_D {
    REQNO?: string | null;
    DETNO?: number | null;
    SYSCODE?: string | null;
    PRDTYPE?: string | null;
    PRDCODE?: string | null;
    PRDPREFIX?: string | null;
    CHG_DESC?: string | null;
    QUANT?: number | null;
    PRICE?: number | null;
    COST?: number | null;
    REVSTAT?: string | null;
    REVNO?: number | null;
    BILL_DETNO?: string | null;
    REFUND?: string | null;
    amountBFdynamic?: number | null;
    amountDynamicFlag?: string | null;
}

/** Table ICDCM1 */
export interface ICDCM1 {
    DES?: string | null;
    CODE?: string | null;
}

export interface ORDEF_KW {
    ORCODE?: string | null;
    ORKW_CODE?: string | null;
}

/** ตาราง ORDEF */
export interface OperationDto {
    DF_CODE?: string;
    DOCTOR_FEE?: string;
    CHGCODE?: number;
    ORCODE?: string;
    ORDESC?: string;
    ORPRICE?: string;
    ICDCODE?: string;
    ICDDESC?: string;
    OR_GROUP?: string | null;
    OR_TYPECODE?: string | null;
    OR_STDCOST?: number | null;
    ICD_CODE?: string | null;
    ORINST_SET?: string | null;
    ORBASIC?: string | null;
    EST_TIME?: number | null;
    FIX_PRICE?: string | null;
    OP_TYPE?: string | null;
    REFUND?: string | null;
    OR_SIDE?: string | null;
    ORHIDE_SELECT?: string | null;
    PRHIDE_SELECT?: string | null;
    MAJOR_FLAG?: string | null;
    ORDESC_OLD?: string | null;
    ICD10TM?: string | null;
    EXAM_FEE?: string | null;
    ICDCM1_Relation?: ICDCM1;
    ORDEF_KW_Relation?: ORDEF_KW;
}

export interface ORROOM {
    ROOM_NO: string;
    ROOM_DESC?: string | null;
    OR_TYPECODE?: string | null;
    ROOM_RATE?: number | null;
    ROOM_STATUS?: string | null;
    CHGCODE?: string | null;
    ROOM_COST?: number | null;
    REFUND?: string | null;
}

export interface AUX_CODE {
    AUX_TYPE?: string;
    AUX_CODE?: string;
    AUX_DESC?: string | null;
    AUX_SUBTYPE?: string | null;
}

export type ORSearchType = "ORCODE" | "ICD" | "TYPE" | "KEYWORD" | "DESC";

export const makeFullORReqNo = (lastReqNo: number) => {
    const leadingZero: string = lastReqNo.toString().padStart(6, '0');
    const prefix = dayjs(new Date()).format("BBMMDD");
    return prefix + leadingZero;
};

export class RequestSurgeryModel {
    private _orRequests: ORREQ_H_Dto[] = [];

    //#region can edit this not sure what field u want
    private _operations: OperationDto[] = [];
    private _anesTypes: AUX_CODE[] = [];
    private _orRooms: ORROOM[] = [];
    private _ORREQ_HD: ORREQ_HD[] = [];
    private _ORREQ_D: ORREQ_D[] = [];
    private _notSure: ORREQ_H_Dto = {};
    private _lastReq: ORREQ_H_Dto = {};
    //#endregion
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getORRooms(): Promise<ORROOM[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("or-room");
            this.client.getAll().then((datas: any[]) => {
                if (datas && datas.length > 0) {
                    this._orRooms = datas;
                    resolve(this._orRooms);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    // -- Get OR
    public getORRequests(hn: string, regNo: string, searchText: string, dateStart: Date | null, dateEnd: Date | null): Promise<ORREQ_H_Dto[]> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("or-request");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    this._orRequests = datas;
                    resolve(this._orRequests);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    // TODO : add HN for search
    public getLastRequest(hn: string): Promise<ORREQ_H_Dto | null> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("or-last-request");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    this._lastReq = datas[0];
                    resolve(this._lastReq);
                } else {
                    this._lastReq = {};
                    resolve(this._lastReq);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    // -- ไม่แน่ใจ qry นี้ใช้ตรงไหน
    // -- อาจจะใช้ตอน select OR request
    public getORByRegNo(regNo: string): Promise<ORREQ_H_Dto> {
        return new Promise((resolve, reject) => {
            const _params = { regNo: regNo };
            this.client = new Client("or-reg-no");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    this._notSure = datas[0];
                    resolve(this._notSure);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    // OR Detail
    public getORREQ_D(regNo: string): Promise<ORREQ_D[]> {
        return new Promise((resolve, reject) => {
            const _params = { regNo: regNo };
            this.client = new Client("or-req-d");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    this._ORREQ_D = datas;
                    resolve(this._ORREQ_D);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    // OR HD ช่อง data table ข้างล่าง
    public getORREQ_HD(regNo: string): Promise<ORREQ_HD[]> {
        return new Promise((resolve, reject) => {
            const _params = { regNo: regNo };
            this.client = new Client("or-req-hd");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    this._ORREQ_HD = datas;
                    resolve(this._ORREQ_HD);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getAnesTypes(): Promise<AUX_CODE[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("anes-types");
            this.client.getAll().then((datas: any[]) => {
                if (datas && datas.length > 0) {
                    this._anesTypes = datas;
                    resolve(this._anesTypes);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getORByType(keyword: string, type: ORSearchType): Promise<OperationDto[]> {
        const data = { keyword, type };
        return new Promise((resolve, reject) => {
            this.client = new Client("or-by-types");
            this.client.postGet({ request: data }).then((datas: OperationDto[]) => {
                if (datas && datas.length > 0) {
                    this._operations = datas;
                    resolve(this._operations);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    //#region Create PreRequest
    public getORLASTREQNO(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.client = new Client("or-last-req-no");
            this.client.getAll().then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public insertNewORCON(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("insert-or-con");
            this.client.create({ request: {} }).then((data: any) => {
                if (data) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public increaseORLastReqNo(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.client = new Client("increase-or-last-req-no");
            this.client.create({ request: {} }).then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public updateORLASTREQNO(prevORLASTREQNO: number, lastORLASTREQNO: number): Promise<number> {
        return new Promise((resolve, reject) => {
            this.client = new Client("update-or-last-req-no");
            const datas = { prevORLASTREQNO, lastORLASTREQNO };
            this.client.postUpdate({ request: datas }).then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }
    //#endregion

    public createORRequest(ORREQ_H: ORREQ_H_Dto, ORREQ_HD: reqORREQ_HD): Promise<boolean> {
        return new Promise((resolve, reject) => {
            delete ORREQ_H.doctorName;
            const datas = { ORREQ_H, ORREQ_HD };
            this.client = new Client("create-or-requests");
            this.client.create({ request: datas }).then((data: any) => {
                resolve(true);
            }).catch((err: any) => {
                console.error(err);
                resolve(false);
                reject(err);
            });
        });
    }

    public updateORRequest(ORREQ_H: ORREQ_H_Dto, ORREQ_HD: reqORREQ_HD): Promise<boolean> {
        return new Promise((resolve, reject) => {
            delete ORREQ_H.doctorName;
            const datas = { ORREQ_H, ORREQ_HD };
            this.client = new Client("update-or-requests");
            this.client.update({ id: "", request: datas }).then((data: any) => {
                if (data) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public get orRequests(): ORREQ_H_Dto[] {
        return this._orRequests;
    }

    public get operations(): OperationDto[] {
        return this._operations;
    }

    public get ORREQ_HD(): ORREQ_HD[] {
        return this._ORREQ_HD;
    }

    public get ORREQ_D(): ORREQ_D[] {
        return this._ORREQ_D;
    }

    public get Notsure(): ORREQ_H_Dto {
        return this._notSure;
    }

    public get anesTypes(): AUX_CODE[] {
        return this._anesTypes;
    }

    public get orRooms(): ORROOM[] {
        return this._orRooms;
    }

    public get LastRequest(): ORREQ_H_Dto {
        return this._lastReq;
    }
}
