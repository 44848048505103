import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import configApi from "../../config";
export default class FirebaseClient {
    public db;
    constructor() {
        firebase.initializeApp(configApi.firebaseConfig);
        let user = localStorage.getItem("user") as any;
        if (user) {
            //TODO: please resolve this issue
            user = JSON.parse(user) as any;
        } else {
            // console.log("No user");
        }
        this.db = firebase.firestore();
    }
    collection(collection: string) {
        return this.db.collection(collection);
    }
}