import { makeAutoObservable } from "mobx";
import { PatientDto } from "../patients/Patient";
import { v4 as uuid4 } from "uuid";
import dayjs from "dayjs";
import { convertTimeFomat } from "@/helper/utils";
import Client from "../../../api/client";
export interface DoctorDto {
    docCode: string;
    docDept: string;
    docStat: string;
    specCode: string;
    startExamDate: string;
    endExamDate: string;
    FullName: string;
    docName: string;
    docLName: string;
    deptDesc: string;
    doctorID: string;
}

export interface reqCreateFeverDoctor {
    cancelDate: Date;
    cancelTime: string;
    hn: string;
    docCode: string;
    usrName: string;
    RegNo: string;
    IpdDoccReq: IpdDoccReqObj;
}

export interface reqIpdDoccConsultant {
    IpdDoccReq: IpdDoccReqObj[];
}

export interface IpdDoccReqObj {
    hn: string;
    DocType: string;
    docRun: number;
    docCode: string;
    stWorkDate: Date;
    stWorkTime: string;
    cancelDate?: Date;
    cancelTime?: string;
    usrName: string;
    writeDate: Date;
    note: string;
    RegNo: string;
}

export interface consultDoctorDisplay {
    Id: string;
    docCode: string;
    docName: string;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    note: string;
}

export interface DisplayDoctorFever {
    docCode: string;
    docFirstName: string;
    docLastName: string;
    expert: string;
    docStatus: string;
    deptName: string;
    startCheckDate: Date;
    startCheckTime: string;
    usrName: string;
    createDate: Date;
    note: string;
}
export interface DisplayDoctorForDataTable {
    code: string;
    doctorName: string;
    doctorID: string;
    deptCode: string;
    deptName: string;
    status: string;
    expert: string;
    startLesson: string;
    endLesson: string;
}

export interface PatientFeverDoctor {
    docName: string;
    docSpec: string;
}

export interface DisplayFeverDoctorHistory {
    docCode: string;
    docName: string;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    DocType: string;
    docRun: number;
    RegNo: string;
    hn: string;
}

export interface DocCert {
    hn?: string;
    docuNo?: number;
    docuType?: string;
    docCode?: string;
    docLicense?: string;
    patAge?: number;
    patCardID?: string;
    visitType?: string;
    ward?: string;
    /** example 2022-10-19 00?:00?:00 */
    visitDateFrom?: Date;
    /** example 2022-10-19 00?:00?:00 */
    visitDateTo?: Date;
    docuText?: string;
    visitStatus?: string;
    contSickDays?: number;
    /** 2023-05-12 00?:00?:00 current date? */
    certDate?: Date;
    /** AN */
    refNo?: string;
    usrName?: string;
    regNo?: string;
    /** flag TH/EN */
    certify_eng?: string;
    patHIS?: string | null;
    resultLab?: string | null;
    resultRad?: string | null;
    DiagDes?: string | null;
    Treatment?: string | null;
    ReferResson?: string | null;
    PatientAddress?: string;
    Otherdisease?: string;
    accidentCause?: string | null;
    accidentDate?: Date | null;
    accidentTime?: string | null;
    callSickFrom?: Date | null;
    callSickTo?: Date | null;
    comment?: string | null;
    followup?: string | null;
    followupdate?: string | null;
    docName?: string;
}
export class DoctorModel {
    private client: Client<any>;
    private state: DoctorDto[] = [];
    private consultDoctor: consultDoctorDisplay[] = [];
    private displayDoctorForDataTable: DisplayDoctorForDataTable[] = [];
    private feverDoctor: DisplayDoctorFever | undefined;
    private doctorCert: DocCert | undefined;
    private doctorSickCert: DocCert | undefined;
    private doctorData: PatientFeverDoctor | undefined;
    private doctorHistory: DisplayFeverDoctorHistory[] = [];
    private docCertRunno: number = 0;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getDoctorWithLimit(limit: number): Promise<DoctorDto[]> {
        return new Promise((resolve, rejects) => {
            const _params = { limit: limit.toString() };
            this.client = new Client("doctor/limit");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    resolve(this.state = result);
                } else {
                    resolve(this.state = []);
                }
            }).catch((error: any) => {
                rejects(error);
            });
        });
    }

    public handlePrepareFeverDoctor(docCode: string, userName: string) {
        return new Promise((resolve, rejects) => {
            const _params = { code: docCode };
            this.client = new Client("doctor-docc/code");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    this.feverDoctor = {
                        docCode: result[0].docCode,
                        docFirstName: result[0].docName,
                        docLastName: result[0].docLName,
                        docStatus: result[0].docStat,
                        // expert: "",
                        startCheckDate: new Date(),
                        startCheckTime: dayjs().format("HH:mm"),
                        expert: result[0].specDesc ? result[0].specDesc : "",
                        deptName: result[0].deptDesc ? result[0].deptDesc : "",
                        usrName: userName,
                        note: ""
                    } as DisplayDoctorFever;
                    resolve(this.feverDoctor);
                } else {
                    this.feverDoctor = {} as DisplayDoctorFever;
                }
            }).catch((error: any) => {
                rejects(error);
            });
        });
    }

    public getFeverDoctorData(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            const _params = {
                hn: hn.trim(),
                regNo: regNo.trim()
            };
            this.client = new Client("doctor-fever");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    const resultData = result[0];
                    this.feverDoctor = {
                        docCode: resultData.docCode ? resultData.docCode.trim() : "",
                        docFirstName: resultData.docName ? resultData.docName.trim() : "",
                        docLastName: resultData.docLName ? resultData.docLName.trim() : "",
                        docStatus: resultData.docStat,
                        // expert: "",
                        expert: resultData.specDesc ? resultData.specDesc : "",
                        deptName: resultData.deptDesc ? resultData.deptDesc : "",
                        startCheckDate: resultData.stWorkDate,
                        startCheckTime: resultData.stWorkTime,
                        usrName: resultData.usrName,
                        createDate: resultData.writeDate,
                        note: resultData.note
                    };
                    resolve(this.feverDoctor);
                } else {
                    this.feverDoctor = {} as DisplayDoctorFever;
                }

            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getConsultDoctorData(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("doctor-consult");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    this.consultDoctor = result.map((item: any) => {
                        return {
                            Id: uuid4(),
                            docCode: item.docCode ? item.docCode : "",
                            docName: item.docName ? item.docCode.trim() + " " + item.docName.trim() + " " + item.docLName.trim() : "",
                            startDate: item.stWorkDate,
                            startTime: item.stWorkTime,
                            endDate: item.cancelDate,
                            endTime: item.cancelTime,
                            note: item.note
                        };
                    });
                    this.consultDoctor.push({ Id: uuid4() } as consultDoctorDisplay);
                    resolve(this.consultDoctor);
                } else {
                    this.consultDoctor = [] as consultDoctorDisplay[];
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getFeverDoctorHistory(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("doctor-fever-history");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    this.doctorHistory = result.map((item: any) => {
                        return {
                            docCode: item.docCode ? item.docCode : "",
                            docName: item.docName ? item.docCode.trim() + "" + item.docName.trim() + " " + item.docLName.trim() : "",
                            startDate: item.stWorkDate ? dayjs(item.stWorkDate).format("DD/MM/BBBB") : "",
                            startTime: item.stWorkTime ? convertTimeFomat(item.stWorkTime) : "",
                            endDate: item.cancelDate ? dayjs(item.cancelDate).format("DD/MM/BBBB") : "",
                            endTime: item.cancelTime ? convertTimeFomat(item.cancelTime) : "",
                            DocType: item.DocType,
                            docRun: item.docRun,
                            RegNo: item.RegNo,
                            hn: item.hn
                        };
                    });
                    resolve(this.doctorHistory);
                } else {
                    this.doctorHistory = [] as DisplayFeverDoctorHistory[];
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getDocRunNo(hn: string, regNo: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("doctor-runno");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }

    public createFeverDoctor(reqFeverDoctor: reqCreateFeverDoctor, patient: PatientDto): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            const data = reqFeverDoctor.IpdDoccReq;
            const datas = {
                data: data,
                cancelDate: reqFeverDoctor.cancelDate,
                cancelTime: reqFeverDoctor.cancelTime,
                hn: reqFeverDoctor.hn,
                docCode: reqFeverDoctor.docCode,
                usrName: reqFeverDoctor.usrName,
                RegNo: patient.regNo,
                ladmit_n: patient.ladmitN,
                current_room: patient.currentRoom,
            };
            this.client = new Client("doctor-fever");
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                console.log(error);
                resolve(false);
            });
        });
    }

    // NOT SURE 
    public deleteDoctorConsultant(hn: string, docRun: number, regNo: string, docCode: string) {
        return new Promise((resolve, reject) => {
            // const params = `${hn.trim()}/${docRun}/${regNo.trim()}/${docCode.trim()}`;
            const data = { hn: hn.trim(), docRun: docRun, regNo: regNo, docCode: docCode };
            this.client = new Client("doctor-consult-delete");
            this.client.postDeleteObject({ request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }

    public createDoctorConsultant(IpdDoccReq: IpdDoccReqObj): Promise<boolean> {
        if (!IpdDoccReq.cancelDate) {
            IpdDoccReq.cancelDate = new Date();
        }
        if (!IpdDoccReq.cancelTime) {
            IpdDoccReq.cancelTime = dayjs().format("HHmm");
        }
        return new Promise((resolve, rejects) => {
            this.client = new Client("doctor-consult");
            this.client.create({ request: IpdDoccReq }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                console.log(error);
                resolve(false);
                rejects(error);
            });
        });
    }

    public getDoctorByCode(code: string): Promise<DoctorDto[]> {
        return new Promise((resolve, rejects) => {
            if (code) {
                const _params = { code: code };
                this.client = new Client("doctor/code");
                this.client.getQuery({ query: _params }).then((result: any) => {
                    if (result.length > 0) {
                        // this.state = [];
                        this.state = result;
                        resolve(result);
                    } else {
                        resolve([]);
                    }
                }).catch((error: any) => {
                    rejects(error);
                });
            }
        });
    }

    public getDoctorByName(name: string): Promise<DoctorDto[]> {
        return new Promise((resolve, rejects) => {
            const _params = { name: name };
            this.client = new Client("doctor/name");
            this.client.getQuery({ query: _params }).then((result: any[]) => {
                if (result.length > 0) {
                    this.displayDoctorForDataTable = result.map((value: any) => {
                        return {
                            code: value.docCode,
                            doctorName: value.FullName,
                            deptCode: value.docDept,
                            deptName: value.deptDesc,
                            status: value.docStat,
                            expert: value.specCode,
                            startLesson: value.startExamDate,
                            endLesson: value.endExamDate,
                            doctorID: value.docCode,
                        };
                    });
                    this.state = result;
                    resolve(this.state = result);
                } else {
                    this.state = [];
                    resolve([]);
                }
            }).catch((error: any) => {
                rejects(error);
            });
        });
    }

    public getDocCert(hn: string, regNo: string, docCode: string) {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim(), docCode: docCode.trim() };
            this.client = new Client("doctor-cert");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    this.doctorCert = {
                        hn: result.hn,
                        docuNo: result.docuNo,
                        docCode: result.docCode,
                        refNo: result.refNo,
                        docuText: result.docuText,
                        regNo: result.regNo,
                        PatientAddress: result.PatientAddress,
                        certify_eng: result.certify_eng,
                    } as DocCert;
                    resolve(this.doctorCert);
                } else {
                    this.doctorCert = {} as DocCert;
                    resolve(this.doctorCert);
                }
            }).catch((error: any) => {
                console.log(error);
                this.doctorCert = {} as DocCert;
                rejects(error);
            });
        });
    }

    public getDocSickCert(hn: string, regNo: string, docCode: string) {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim(), docCode: docCode.trim() };
            this.client = new Client("doctor-sick-cert");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    const data = result[0];
                    this.doctorSickCert = data;
                } else {
                    this.doctorSickCert = {} as DocCert;
                }
            }).catch((error: any) => {
                console.log(error);
                this.doctorCert = {} as DocCert;
                rejects(error);
            });
        });
    }

    public getDocCertRunno(hn: string, regNo: string): Promise<number> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("doctor-cert-runno");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    this.docCertRunno = result;
                    resolve(result);
                } else {
                    resolve(0);
                }
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }

    public updateDocCert(req: DocCert): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            this.client = new Client("doctor-cert");
            this.client.update({ id: "", request: req }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                console.log(error);
                resolve(false);
                rejects(error);
            });
        });
    }

    public createDocCert(req: DocCert): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            this.client = new Client("doctor-cert");
            this.client.create({ request: { data: req } }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                console.log(error);
                resolve(false);
                rejects(error);
            });
        });
    }

    public getDocSpecByDocCode(docCode: string) {
        return new Promise((resolve, rejects) => {
            const _params = { docCode: docCode.trim() };
            this.client = new Client("doctor-spec");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    this.doctorData = {
                        docName: result.docName ? result.docName.trim() + "  " + result.docLName.trim() : "",
                        docSpec: result.specDesc,
                    } as PatientFeverDoctor;
                    resolve(this.doctorData);
                } else {
                    resolve({});
                }
            }).catch((error: any) => {
                console.log(error);
                resolve(false);
                rejects(error);
            });
        });
    }

    public get doctors() {
        return this.state;
    }

    public get DisplayDoctorForDataTable() {
        return this.displayDoctorForDataTable;
    }

    public get FeverDoctor() {
        return this.feverDoctor;
    }

    public get ConsultDoctor() {
        return this.consultDoctor;
    }

    public get DoctorHistory() {
        return this.doctorHistory;
    }

    public get DoctorCert() {
        return this.doctorCert;
    }

    public get DoctorSickCert() {
        return this.doctorSickCert;
    }

    public get DoctorData() {
        return this.doctorData;
    }

    public get DocCertRunno() {
        return this.docCertRunno;
    }
}