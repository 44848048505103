import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";
import { User } from "../users/User";


export class AuthModel {
    private state?: User;
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("auth");

    }
    public login(username: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.auth("authenticate", { username, password }).then((res: any) => {
                this.state = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public verifyToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.get({ id: "verifytoken" }).then((res: any) => {
                this.state = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public get auths() {
        return this.state;
    }
}