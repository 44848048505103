/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ControllerProps } from "../@types/App";
import { User } from "../models/domain/users/User";
import AppView from "../views/Apps";

const AppController = observer((props: ControllerProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user")!) as User;
    const userData = (props.viewModel.users) ? props.viewModel.users : user as User;
    useEffect(() => {
        if (user) {
            props.viewModel.verifyToken().catch((err) => {
                if (err.response?.status === 401) {
                    if (location.pathname.split("/")[1] !== "" && location.pathname.split("/")[1] !== "login" && location.pathname.split("/")[1] !== "selectward") { onLogout(); }
                }
            });
        }
        getAllMaster();
        if (props.viewModel.selectWards.length === 0) { props.viewModel.getAllWard(); }

        if (userData?.username && !userData.selectWard && location.pathname.split("/")[1] === "") {
            navigate("/selectward");
        } else if (userData?.username && userData?.selectWard && location.pathname.split("/")[1] === "") {
            navigate("/patient");
        } else if (!userData && location.pathname.split("/")[1] === "") {
            navigate("/login");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate, userData]);

    // useEffect(() => {


    const getAllMaster = () => {
        const labType = props.viewModel.labType;
        if (labType.length <= 0) { props.viewModel.getLabType(); }
        const howMaster = props.viewModel.howMasters;
        if (howMaster.length <= 0) { props.viewModel.getAllHowMaster(); }
        const howsWithDose = props.viewModel.howsWithDose;
        if (howsWithDose.length <= 0) { props.viewModel.getDose(); }
        const xrayPart = props.viewModel.xrayPartState;
        if (xrayPart.length <= 0) { props.viewModel.getXrayPart(); }
        const xrayType = props.viewModel.xrayType;
        if (xrayType.length <= 0) { props.viewModel.getXrayType(); }
        const masterXrayItems = props.viewModel.masterXrayItems;
        if (masterXrayItems.length <= 0) { props.viewModel.getAllXrayItems(); }
        const masterReligion = props.viewModel.masterReligion;
        if (masterReligion.length <= 0) { props.viewModel.getMasterReligion(); }
    };

    const onLogout = () => {
        props.viewModel.logout();
        navigate("/login");
    };
    if (process.env.NODE_ENV === "test") {
        return <>
            <button data-testid="logout" onClick={onLogout}>Logout</button>
            <AppView user={userData} onLogout={onLogout} /></>;
    }
    else
        return <AppView user={userData} onLogout={onLogout} />;
});
export default AppController;