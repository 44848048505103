
// TODO: declare this model in index.tsx
import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import { convertTimeFomat } from "@/helper/utils";
import Client from "../../../api/client";
export interface LabsDto {
    id: string;
    an: string;
    orderDate: string;
    labOrders: LabOrder[];
}

export interface LabsRequest {
    id: string;
    an: string;
    orderDate: string;
    labOrders: LabOrder[];
}

export interface Labreq_h {
    username: string;
    hn: string;
    /**
     * lab type code
     */
    lab_type: string;
    /**
     * total request count (number of lab) in this order
     * format (ex. 01, 02, 03, 04, 05, 06, 07, 08, 09, 10)
     */
    tot_req: string;
    /**
     * total price of this order
     */
    tot_prc: string;
    /**
     * request date in BE format (ex. 25650101)
     */
    req_date: string;
    /**
     * request time (ex. 1010)
     */
    req_time: string;
    /**
     * request no in BE format  year 2 digit is prefix with 0 6 digit
     * (ex. 65000001)
     */
    req_no: string;
    /**
     * result ok
     * @default 'N'
     */
    res_ok: string;
    /**
     * diagnosis description
     * @MaxLength 32
     */
    diagnos: string;
    /**
     * total result count (number of lab) in this order
     * format (ex. 01, 02, 03, 04, 05, 06, 07, 08, 09, 10)
     * @default '00'
     * @MaxLength 2
     */
    tot_res: string;
    /**
     * doctor code
     */
    docCode: string;
    /**
     * lab confirm
     * @default 'N'
     */
    lconfirm: string;
    /**
     * lab unit code
     * @default 'lab'  กลุ่มงานเทคนิคการแพทย์
     */
    labUnit: string;
    /**
     * speciment id
     */
    specimenID: string;
    /**
     * speciment detail เพิ่มเติม
     */
    specimenDet: string;
    /**
     * สถานะการรับผล
     * @default '0'
     */
    waitStat: string;
    /**
     * @default ''
     */
    rxno: string;
    /**
     * @default 'I'
     */
    ipdstat: string;
    /**
     * ward code for request
     * @required
     */
    reqFrom: string;
    /**
     * @default 'N'
     */
    cmdSent: string;
    /**
     * @default 'Y'
     */
    endReq: string;
    /**
     * @default 'IPD'
     */
    reqSysID: string;
    /**
     * @default 'on web'
     */
    req_compname: string;
    /**
     * @default '2020.1.4'
     */
    RelVersion: string;
    /**
     * 0012
     */
    reg_flag: string;
    remark: string;
    reqPrinted: string;
    printdate: Date;
    printcomputer: string;
    items?: Labreq_d[];

}
export interface LabOrder {
    an: string;
    LabReqNo: string;
    order: Labreq_h;
    orderId?: string;
}
export interface Labreq_d {
    /**
     * ipd price of this lab
     * format float (ex. 100.00)
     * @required
     */
    lab_prc: string;
    /**
     * number of request item
     * format (ex. 01, 02, 03, 04, 05, 06, 07, 08, 09, 10)
     * @required
     */
    num_req: string;
    /**
     * hospital number
     */
    hn: string;
    /**
     * lab code
     */
    lab_code: string;
    /**
     * @default ''
     */
    reverse_flag: string;
    /**
     * bill_d runing no format (ex. 002200)
     * @required
     */
    bill_d_run_no: string;
    /**
     * lab name
     */
    lab_name: string;
    /**
     * request no
     * @required
     */
    req_no: string;
    /**
     * @default 'N'
     */
    res_ready: string;
    /**
     * @default 'N'
     */
    now_in_out: string;
    /**
     * request date in BE format (ex. 25650101)
     * @required
     */
    date: string;
    /**
     * request time (ex. 1010)
     * @required
     */
    time: string;
    /**
     * lab_type code
     */
    lab_type: string;
    /**
     * @default 'N'
     */
    waitstat: string;
    /**
     * @default 'Y'
     */
    refund: string;
    /**
     * @default 'N'
     */
    cmdSend: string;
    /**
     * specimen id
     */
    specimenID: string;
    /**
     * @default 1
     */
    orderQty: number;
    /**
     * @default ''
     */
    labRtnCode: string;
    /**
     * user name
     */
    usrName: string;
    /**
     * @default 'N'
     */
    app_flag: string;
    /**
     * @default ''
     */
    chgCode: string;
    /**
     * register flag
     * @default '0012' VN's reg no of opd_h
     */
    reg_flag: string;

    disc: string;
    cost: number | null;
    labSeqNo: number | null;
    lab_type_des?: string;
    xprice_code?: string;
}
export interface Lab {
    labRoutine: string | null;
    labCode: string;
    labName: string | null;
    opdPrice: string | null;
    ipdPrice: string | null;
    chgCode: string | null;
    resultCount: string | null;
    inOutFlag: string | null;
    specimen: string | null;
    organism: string | null;
    otherFlag: string | null;
    disc: string | null;
    labType: string;
    labVolume: string | null;
    opdCount: string | null;
    ipdCount: string | null;
    lastUpdate: string | null;
    priceSum: string | null;
    swpQtyFlag: string | null;
    resultTime: string | null;
    driverName: string | null;
    cmdOffset: string | null;
    instrumenID: number | null;
    refund: string | null;
    color: number | null;
    hiPrice: string | null;
    secLevel: number | null;
    cost: string | null;
    qtysticker: number | null;
    sequence: number | null;
    noResult: string | null;
    extratube: string | null;
    method: string | null;
    LabcodehideSelect: string | null;
    lab_prc: string | null;
    reqColumns: number | null;
    labDesc_th: string | null;
    labDes_th: string | null;
    splitHPackReq: string | null;
    HPackcmdOffset: string | null;
    canRevResult: string | null;
    foreign_opd_prc: number | null;
    foreign_ipd_prc: number | null;
    srth_sequence: number | null;
    AutoConfirm: string | null;
    LAB_TM: string | null;
    Code_chi: string | null;
    Code_tml: string | null;
    SRTH_SIMBCode: string | null;
}

export interface Labtype {
    lab_type: string;
    lab_type_des: string;
    specimen_dft: string;
    labUnit: string;
    seriesNo: number;
    lastSampleID: number;
    lastSampleDate: Date;
    yearlySampleID: string;
    hideSelect: string;
    typesequence: number;
    resFormName: string;
    reqColumns: number;
    AppointLimit: number;
    AutoConfirm: string;
    SpecimenRequired: string;
}

export interface Labspcm {
    specimenID: string;
    lab_type: string;
    specimen: string;
}

export interface PATRXDET {
    reqDesc: string;
    runNo: number;
    IOStat: string;
    reqCat: string;
    reqCode: string;
    reqRef: string;
    reqRefDet: string;
    hn: string;
    reqDocCode: string;
    reqMaker: string;
    reqDate: string;
    reqTime: Date;
    result: string;
    revStat: string;
    reqUnit: string;
    reqQty: number;
    reqAmt: number;
    rxNo: string;
    regNo: string;
}

// exec sp_executesql N'insert into Bill_d (usrname, running_no, ioflag, charge_code, charge_des, amount, date, time, hn, disc_flag, ref_no, doccode, invsite, sysID, refund, reverseFlag, paidFlag, rxno, serviceUnit, cost, ucost, vat_flag, rev_itm, RefID, itemCompName, itemChargeDate, itemCode, itemPrefix, itemType, itemQuant, itemActionUnit, cancel_itm_no, itemSysID, regist_flag, itemChargeUnit) values (@P1, @P2, @P3, @P4, @P5, @P6, @P7, @P8, @P9, @P10, @P11, @P12, @P13, @P14, @P15, @P16, @P17, @P18, @P19, @P20, @P21, @P22, @P23, @P24, @P25, @P26, @P27, @P28, @P29, @P30, @P31, @P32, @P33, @P34, @P35)',N'@P1 varchar(8),@P2 varchar(6),@P3 varchar(1),@P4 varchar(3),@P5 varchar(39),@P6 varchar(11),@P7 varchar(8),@P8 varchar(4),@P9 varchar(7),@P10 varchar(1),@P11 varchar(14),@P12 varchar(6),@P13 varchar(1),@P14 varchar(3),@P15 varchar(1),@P16 varchar(1),@P17 varchar(1),@P18 varchar(1),@P19 varchar(3),@P20 float,@P21 float,@P22 varchar(1),@P23 varchar(1),@P24 varchar(1),@P25 varchar(15),@P26 datetime,@P27 varchar(5),@P28 varchar(1),@P29 varchar(1),@P30 varchar(10),@P31 varchar(1),@P32 varchar(1),@P33 varchar(8),@P34 varchar(4),@P35 varchar(1)','innofive','003100','I','007','ATK:SARS Coronavirus2 Ag,in Respiratory','     150.00','25660314','1420',' 414271','N','36596 66000006',' 35218','','LAB','Y','','N','','023',0,0.0001,'N','N','','DESKTOP-65ISGRB','2023-03-15 14:20:22.547','36596','I','I','      1.00','','','innofive','0010',''

export interface Bill_d {
    usrname: string;
    running_no: string;
    ioflag: string;
    charge_code: string;
    charge_des: string;
    amount: string;
    date: string;
    time: string;
    hn: string;
    disc_flag: string;
    ref_no: string;
    doccode: string;
    invsite: string;
    sysID: string;
    refund: string;
    reverseFlag: string;
    paidFlag: string;
    rxno: string;
    serviceUnit: string;
    cost: number;
    ucost: number;
    vat_flag: string;
    rev_itm: string;
    RefID: string;
    itemCompName: string;
    itemChargeDate: Date;
    itemCode: string;
    itemPrefix: string;
    itemType: string;
    itemQuant: string;
    itemActionUnit: string;
    cancel_itm_no: string;
    itemSysID: string;
    regist_flag: string;
    itemChargeUnit: string;
    // บันทึกค่าใช้จ่าย
    paidAmt?: number;
}

//#region Lab Result
export interface LabResult {
    req_no: string;
    hn: string;
    lab_code: string;
    res_date: string;
    res_time: string;
    result_name: string;
    lab_type: string;
    lastHistNo: number;
    // result
    resNormal: string;
    real_res: string;
    remark: string;
    low_normal: string;
    high_normal: string;
    lis_res: string;
    higher_normal: string;
    username: string;
    result_unit: string;
}

export interface LabResultHeader {
    req_no: string;
    hn: string;
    reg_flag: string;
    lab_type: string;
    name: string;
    detail: LabResultDetail[];
}

export interface LabResultDetail {
    lab_code: string;
    lab_name: string;
    hn: string;
    reg_flag: string;
    res_ready: string;
    req_no: string;
    num_req: string;
    resNormal: string;
}
//#endregion

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockLabreqData: Labreq_d = {
    lab_prc: '100.00',
    num_req: '01',
    hn: '1496776',
    lab_code: '22105',
    reverse_flag: '',
    bill_d_run_no: '002200',
    lab_name: 'Bl.group (ABO)-Tube test',
    req_no: '66000001',
    res_ready: 'N',
    now_in_out: '',
    date: '25660119',
    time: '1331',
    lab_type: 'B',
    waitstat: 'N',
    refund: 'Y',
    cmdSend: 'N',
    specimenID: '01',
    orderQty: 1,
    labRtnCode: '',
    usrName: 'innofive',
    app_flag: 'N',
    chgCode: '',
    reg_flag: '0012',
    disc: 'N',
    cost: 0,
    labSeqNo: 66666,
};

export const mockLabHistory = [
    {
        req_no: '66000001',
        hn: '1496776',
        items: [
            {
                lab_prc: '100.00',
                num_req: '01',
                hn: '1496776',
                lab_code: '22105',
                reverse_flag: '',
                bill_d_run_no: '002200',
                lab_name: 'Bl.group (ABO)-Tube test',
                req_no: '66000001',
                res_ready: 'N',
                now_in_out: '',
                date: '25660119',
                time: '1331',
                lab_type: 'B',
                waitstat: 'N',
                refund: 'Y',
                cmdSend: 'N',
                specimenID: '01',
                orderQty: 1,
                labRtnCode: '',
                usrName: 'innofive',
                app_flag: "0",
                chgCode: '',
                reg_flag: '0012',
            }
        ],
    },
    {
        req_no: '66000002',
        hn: '1496776',
        items: [
            {
                lab_prc: '100.00',
                num_req: '01',
                hn: '1496776',
                lab_code: '22105',
                reverse_flag: '',
                bill_d_run_no: '002201',
                lab_name: 'Bl.group (ABO)-Tube test 2',
                req_no: '66000002',
                res_ready: 'N',
                now_in_out: '',
                date: '25660119',
                time: '1331',
                lab_type: 'B',
                waitstat: 'N',
                refund: 'Y',
                cmdSend: 'N',
                specimenID: '01',
                orderQty: 1,
                labRtnCode: '',
                usrName: 'innofive',
                app_flag: "0",
                chgCode: '',
                reg_flag: '0012',
            }
        ],
    },
    {
        req_no: '66000003',
        hn: '1496776',
        items: [
            {
                lab_prc: '100.00',
                num_req: '01',
                hn: '1496776',
                lab_code: '22105',
                reverse_flag: '',
                bill_d_run_no: '002200',
                lab_name: 'Bl.group (ABO)-Tube test',
                req_no: '66000003',
                res_ready: 'N',
                now_in_out: '',
                date: '25660119',
                time: '1331',
                lab_type: 'B',
                waitstat: 'N',
                refund: 'Y',
                cmdSend: 'N',
                specimenID: '01',
                orderQty: 1,
                labRtnCode: '',
                usrName: 'innofive',
                app_flag: "1",
                chgCode: '',
                reg_flag: '0012',
            }
        ],
    },
];

export class LabModel {
    private client: Client<any>;
    private labOrders: LabOrder[] = [];
    private labTypes: Labtype[] = [];
    private labSpecimens: Labspcm[] = [];
    private labs: Lab[] = [];
    private labHistory: Labreq_h[] = [];
    private prepareList: Labreq_h[] = [];
    private prepareSaveLabOrder: Labreq_h | undefined;
    private prepareLabOrder: Labreq_h | undefined;
    // lab result state
    private labResultHeader: LabResultHeader[] = [];
    private labItemResult: LabResult[] = [];
    private subLabItemResult: LabResult[] = [];
    private currentReqNo: string = "";
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("labs");
    }
    //#region  Action

    public getLastReqNo(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.client = new Client("lastreqno");
            this.client.getAll().then((result: any) => {
                if (result.length > 0) {
                    resolve(result[0].lcon_run_no);
                } else {
                    resolve(0);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getLastPatraxdetNo(hn: string, regNo: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("lastpatraxdetno");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(0);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getLastDetailBillH(hn: string, regNo: string): Promise<string> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("lastdetailbillh");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    resolve(result[0].lastDetail);
                }
                else {
                    resolve("");
                }
            }).catch((error: any) => {
                rejects(error);
            });
        });
    }

    public getWlabFromIpdH(hn: string, regist_flag: string): Promise<string> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regist_flag: regist_flag.trim() };
            this.client = new Client("wlabfromipdh");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    resolve(result[0].wlab);
                }
                else {
                    resolve("");
                }
            }).catch((error: any) => {
                rejects(error);
            });
        });
    }

    public updateLastReqNo(prevNo: string, lastNo: string) {
        return new Promise((resolve, reject) => {
            this.client = new Client("lastreqno");
            this.client.update({ id: "", request: { prevNo, lastNo } }).then((result: any) => {
                if (result.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public create(data: LabOrder, lastUpdateDate: Date, newWlab: string, registFlag: string, lastDetailRx: number, lastBillRunNo: string, patrxdet: PATRXDET[], billDetail: Bill_d[], orderId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const user = localStorage.getItem("user");
            const items = data.order.items;
            items && items.forEach((item: any) => {
                delete item.disc;
            });
            delete data.order.items;
            const datas = {
                data: data,
                lastUpdateDate: lastUpdateDate,
                newWlab: newWlab,
                registFlag: registFlag,
                lastDetailRx: lastDetailRx,
                lastBillRunNo: lastBillRunNo,
                patrxdet: patrxdet,
                billDetail: billDetail,
                mapOrder: {
                    an: data.an,
                    LabReqNo: data.order.req_no,
                    orderId: orderId,
                    CreateBy: user ? JSON.parse(user).username : ""
                },
                items: items,
            };
            this.client = new Client("labreqh");
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public updateLabReqH(billNo: string, hn: string, reqNo: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("labreqh");
            this.client.update({ id: "", request: { billNo: billNo, hn: hn.trim(), reqNo: reqNo } }).then((result: any) => {
                if (result.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getOrder(an: string, flag: string): Promise<LabOrder[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("lab-orders");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    let _datas = [] as LabOrder[];
                    if (this.prepareList.length > 0) {
                        this.prepareList.map((item: Labreq_h) => {
                            _datas = [..._datas, { an: an, order: item, LabReqNo: item.req_no }];
                            return true;
                        });
                    }
                    if (flag) {
                        data.forEach((item: LabOrder) => {
                            if (this.labOrders.findIndex(x => x.LabReqNo === item.LabReqNo) === -1) {
                                _datas = [..._datas, item];
                            }
                        });
                    }
                    _datas = _datas.sort((a, b) => a.LabReqNo.localeCompare(b.LabReqNo));
                    resolve(_datas);
                } else {
                    let _datas = [] as LabOrder[];
                    this.prepareList.map((item: Labreq_h) => {
                        _datas = [..._datas, { an: an, order: item, LabReqNo: item.req_no }];
                        return true;
                    });
                    _datas = _datas.sort((a, b) => a.LabReqNo.localeCompare(b.LabReqNo));
                    resolve(_datas);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getLabHistoryByHN(hn: string) {
        this.labHistory = [];
        this.LabHistory.push(...mockLabHistory as Labreq_h[]);
        // TODO : Handle this when have data 
    }


    public searchLabName(labName: string, labType: string) {
        return new Promise((resolve, reject) => {
            const _params = { labName: labName.trim(), labType: labType.trim() };
            this.client = new Client("labname");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    this.labs = result;
                    resolve(this.labs);
                } else {
                    resolve([]);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getLabTypesByCode(typeCode: string) {
        return new Promise((resove, reject) => {
            const _params = { labTypeCodes: typeCode.trim() };
            this.client = new Client("labtypes");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    resove(result[0]);
                } else {
                    resove([]);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getLabTypes() {
        return new Promise((resolve, reject) => {
            this.client = new Client("labtypes");
            this.client.getAll().then((res: Labtype[]) => {
                this.labTypes = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getLabSpecimensByType(typeCode: string) {
        return new Promise((resolve, reject) => {
            const _params = { typeCode: typeCode.trim() };
            this.client = new Client("labspec");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    result.forEach((item: Labspcm) => {
                        if (this.labSpecimens.findIndex(x => x.lab_type === item.lab_type && x.specimen === item.specimen && x.specimenID === item.specimenID) === -1) {
                            this.labSpecimens = [...this.labSpecimens, item];
                        }
                    });
                    resolve(result);
                } else {
                    resolve([]);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public clearLabOrder() {
        this.labOrders = [];
        this.clearPrepareSaveLabOrder();
    }

    public clearPrepareSaveLabOrder() {
        this.prepareList = [];
    }

    public prepareSaveLab(data: Labreq_h) {
        if (data) {
            this.prepareList.push(data);
        }
    }
    //#endregion

    //#region get Lab Result

    public getLabResultHeader(hn: string, regNo: string) {
        return new Promise((resove, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("labresult");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.headers && result.items) {
                    const labItems = result.items;
                    this.labResultHeader = result.headers.map((data: any) => {

                        const date = data.req_date ? dayjs(data.req_date).format("DD/MM/YYYY") : "";
                        const time = data.req_time ? convertTimeFomat(data.req_time) : "";
                        const reqNo = `Req.No.:${data.req_no}`;
                        const specimen = `Specimen: Sample: ${data.lab_type} /`;
                        const doctorCode = `แพทย์: ${data.docCode}`;
                        const result = `ผล ${data.res_ok}`;

                        const value = data as LabResultHeader;
                        value.detail = labItems.filter((x: { req_no: any; }) => x.req_no === data.req_no);
                        value.name = `${date} ${time} ${reqNo} ${specimen} ${doctorCode} ${result}`;
                        return value;
                    });
                    resove(result);
                } else {
                    resove([]);
                }
                this.labItemResult = [];
                this.subLabItemResult = [];
            }).catch((error: any) => {
                reject(error);
            });
            // }`, { hn, regNo }).then((res: { data: { Labreq_h: any[]; }; }) => {
            //     if (res.data.Labreq_h && res.data.Labreq_h.length > 0) {
            //         this.labResultHeader = res.data.Labreq_h.map((item: any) => {
            //             const date = item.req_date ? dayjs(item.req_date).format("DD/MM/YYYY") : "";
            //             const time = item.req_time ? convertTimeFomat(item.req_time) : "";
            //             const reqNo = `Req.No.:${item.req_no}`;
            //             const specimen = `Specimen: Sample: ${item.lab_type} /`;
            //             const doctorCode = `แพทย์: ${item.docCode}`;
            //             const result = `ผล ${item.res_ok}`;
            //             return {
            //                 reg_flag: item.reg_flag,
            //                 req_no: item.req_no,
            //                 hn: item.hn,
            //                 lab_type: item.lab_type,
            //                 name: `${date} ${time} ${reqNo} ${specimen} ${doctorCode} ${result}`,
            //                 detail: item.items ? item.items : [],
            //             } as LabResultHeader;
            //         });
            //         resove(this.labResultHeader);
            //     } else {
            //         this.labResultHeader = [];
            //         resove(this.labResultHeader);
            //     }

            // }).catch((err: any) => {
            //     reject(err);
            // });
        });
    }

    public getLabItemResult(hn: string, reg_flag: string, req_no: string) {
        return new Promise((resove, reject) => {
            this.currentReqNo = req_no;
            const _params = { hn: hn.trim(), reg_flag: reg_flag.trim(), req_no: req_no.trim() };
            this.client = new Client("labitem");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result.length > 0) {
                    this.labItemResult = result;
                    resove(result);
                } else {
                    resove([]);
                }
                this.subLabItemResult = [];
            }).catch((error: any) => {
                reject(error);
            });
        });
    }


    public getSubLabItemResult(hn: string, result_name: string, lab_code: string) {
        return new Promise((resove, reject) => {
            const _params = { hn: hn.trim(), resultName: result_name.trim(), labCode: lab_code.trim() };
            this.client = new Client("sublabitem");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result && result.length > 0) {
                    this.subLabItemResult = result;
                    resove(result);
                } else {
                    resove([]);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }
    //#endregion
    //#region Getter
    public get LabOrders(): LabOrder[] {
        return this.labOrders;
    }

    public get prepareSaveLabOrders() {
        return this.prepareList;
    }

    public get LabHistory(): Labreq_h[] {
        return this.labHistory;
    }

    public get prepareLabOrders() {
        return this.prepareLabOrder;
    }

    public get LabTypes(): Labtype[] {
        return this.labTypes;
    }
    public get LabSpecimens(): Labspcm[] {
        return this.labSpecimens;
    }
    public get Labs(): Lab[] {
        return this.labs;
    }
    //#endregion 

    //#region Lab Result
    public get LabResultHeader() {
        return this.labResultHeader;
    }

    public get LabItemResult() {
        return this.labItemResult;
    }

    public get SubLabItemResult() {
        return this.subLabItemResult;
    }

    public get CurrentReqNo() {
        return this.currentReqNo;
    }
    //#endregion
}

export const labService = new LabModel();