/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable security/detect-object-injection */
import axios, { AxiosError, AxiosResponse } from "axios";

import configApi from "../../config";
export default class Client<T> {

    private client = axios.create(configApi.api);
    private controller: string;
    private responseBody = <T>(response: AxiosResponse<T>) => response.data;
    constructor(_controller: string) {
        this.controller = _controller;

        this.client.interceptors.request.use((config) => {
            let user = localStorage.getItem("user") as any;

            if (user) {
                user = JSON.parse(user) as any;
                const token = user.token;
                if (token && config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                    // config.headers.ContentType = 'application/json';
                }
            }


            return config;
        });

        this.client.interceptors.response.use(
            async (response) => {
                // set sleep for testing
                //await sleep(1000);
                return response;
            },
            (error: AxiosError) => {

                if (error?.request) {
                    const { data, status } = error.response!;
                    switch (status) {
                        case 400:
                            if (typeof data === "string") {
                                //TODO: set alert here
                            }
                            // TODO: id not found
                            // if (config.method === "get" && false) {
                            // //   history.push("/not-found");
                            // }
                            if (data.errors) {
                                const modalStateErrors = [];
                                for (const key in data.errors) {
                                    if (data.errors[key]) {
                                        modalStateErrors.push(data.errors[key]);
                                    }
                                }
                                throw modalStateErrors.flat();
                            }
                            break;
                        case 401:
                            //TODO: set alert here
                            break;
                        case 404:
                            // history.push("/not-found");
                            break;
                        case 500:
                            // store.commonStore.setServerError(data);
                            // history.push("/server-error");
                            break;
                    }
                }
                return Promise.reject(error);
            }
        );
    }
    getAll(): Promise<Array<T>> {
        return this.client.get(`${this.controller}`).then(this.responseBody);

    }
    get(param: { id: string; }): Promise<T> {
        return this.client.get(`${this.controller}/${param.id}`).then(this.responseBody);

    }
    getQuery(params: { query: any; }): Promise<T> {
        const { query } = params;
        let url = `${this.controller}`;

        if (query) {
            const queryString = new URLSearchParams(query).toString();
            url += `?${queryString}`;
        }

        return this.client.get(url).then(this.responseBody);
    }
    postGet(param: { request: any; }): Promise<T> {
        return this.client.post(`${this.controller}`, param.request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(this.responseBody);

    }
    create(param: { request: any; }): Promise<T> {
        return this.client.post(`${this.controller}`, param.request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(this.responseBody);

    }
    update(param: { id: string, request: any; }): Promise<T> {
        return this.client.put(`${this.controller}/${param.id}`, param.request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(this.responseBody);
    }

    postUpdate(param: { request: any; }): Promise<T> {
        return this.client.post(`${this.controller}`, param.request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(this.responseBody);
    }
    delete(param: { id: string; }): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.delete(`${this.controller}/${param.id}`).then(() => resolve()).catch(err => reject(err));
        });
    }
    deleteObject(param: { request: any; }): Promise<void> {
        return this.client.delete(`${this.controller}`, param.request).then(this.responseBody);
    }

    postDeleteObject(param: { request: any; }): Promise<void> {
        return this.client.post(`${this.controller}`, param.request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(this.responseBody);
    }
    blobToBase64(blob: any, callback: (base64: string) => void) {
        const reader = new FileReader();
        reader.onload = function () {
            const dataUrl = reader.result;

            const base64 = (dataUrl as string).split(',')[1];
            callback(base64);

        };
        reader.readAsDataURL(blob);
    }
    toArrayBuffer(buf: any) {
        const ab = new ArrayBuffer(buf.length);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
            view[i] = buf[i];
        }
        return ab;
    }
    download(method: string, request: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.post(`${this.controller}${method}`, request, { responseType: 'arraybuffer' }).then((response) => {


                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    auth(method: string, param: { username: string, password: string; }): Promise<T> {

        return this.client.post(`${this.controller}/${method}`, param).then(this.responseBody);

    }



}        
