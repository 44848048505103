
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import { MedicineDto } from "../drugs/Drug";
import { AdmissionNotePhysicalExaminationDto } from "../admissionRecordPhysical/AdmissionRecordPhysical";
import Client from "../../../api/client";
export interface MedicationReconciliationRequest {
    Id: string;
    AN?: string;
    AllergyFlag?: number;
    AllergyDetail?: string | null;
    UnderlyDiseaseFlag?: number;
    UnderlyDetail?: string | null;
    DrugFlag?: number;
    drugDetail?: string;
    // Date
    CreatedDate?: string;
    CreatedBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
    items?: MedicationReconciliationOderItem[];
}

export interface MedicationReconciliationDto {
    Id: string;
    AN?: string;
    AllergyFlag?: number;
    AllergyDetail?: string | null;
    UnderlyDiseaseFlag?: number;
    UnderlyDetail?: string | null;
    DrugFlag?: number;
    drugDetail?: string;
    // Date
    CreatedDate?: string;
    CreatedBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
    items?: MedicationReconciliationOderItem[];
}

export interface MedicationReconciliationOderItem {
    [key: string]: any;
    Id: string;
    MedCode: string;
    MedName: string;
    code_time?: string;
    time_name?: string;
    code_how?: string;
    how_name?: string;
    code_unit?: string;
    unit_name?: string;
    code_spec?: string;
    spec_name?: string;
    LastTime: string;
    DrugAction: number;
    Note: string;
    MedicationReconciliationId: string;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    // new
    Canceled?: boolean;
    Quantity?: number;
    EachTime: number;
    Day: number;
    HowTimeDocPerDay: number;
    HowHourDocPerDay: string;
    UnitTimeDocPerDay: number;
    UnitHourDocPerDay: string;
    TimeTimeDocPerDay: number;
    TimeHourDocPerDay: string;
    HourPeriod: number;
    IpdCost?: number;
    amount?: number;
    // past visit
    qtyPerFeed?: number;
    invCode?: string;
}

export interface MedicationReconciliationOderContinueItem {
    id: string;
    startUseTime: string | null;
    code: string | null;
    name: string | null;
    prodType: string | null;
    equiUnit: number;
    unitCode: string;
    unit: string | null;
    perTime: number;
    sequenceCode: string;
    sequenceName: string;
    day: string | null;
    lastTime: string | null;
    drugUse: string | null;
    howUseCode: string;
    howUse: string | null;
    drugAction: number;
    note: string;
}
export interface PastVisit {
    regNo: string;
    visitNo: number;
    registDate: string;
    deptCode: string;
    clinic: string;
    room: string;
    medicines?: MedicineDto[];
}


export class MedicationReconciliationModel {
    private state?: MedicationReconciliationDto;
    private drugMrItem?: MedicineDto[] = [];
    private drugItems: MedicineDto[] = [];
    private historyVisit?: AdmissionNotePhysicalExaminationDto[] = [];
    private pastVisit?: PastVisit[] = [];
    private medLastVisit?: MedicineDto[] = [];
    private currentMRItems?: MedicationReconciliationOderItem[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("Medicine");
    }
    public get(AN: string): Promise<MedicationReconciliationDto> {
        let _data = {} as MedicationReconciliationDto;
        return new Promise((resolve, reject) => {
            const _params = { an: AN.trim() };
            this.client = new Client("medicines-reconciliation");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result[0] && result.mrOrder) {
                    if (result.mrOrder.length > 0) {
                        _data = result[0];
                        _data.items = result.mrOrder;
                        this.currentMRItems = _data.items;
                        resolve(this.state = _data);
                    } else {
                        const itemId = { Id: uuidv4() } as MedicationReconciliationOderItem;
                        _data.items = [itemId];
                        this.state = _data;
                        this.currentMRItems = _data.items;
                        resolve(this.state);
                    }
                } else {
                    const itemId = { Id: uuidv4() } as MedicationReconciliationOderItem;
                    _data.items = [itemId];
                    this.state = _data;
                    this.currentMRItems = _data.items;
                    resolve(this.state = _data);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public create(data: MedicationReconciliationRequest): Promise<MedicationReconciliationDto> {
        const mrOrders = data.items;
        const user = localStorage.getItem("user");
        const userCreate = user ? JSON.parse(user).username : "";
        return new Promise((resolve, reject) => {
            const object: Partial<MedicationReconciliationRequest> = data;
            object.CreatedBy = userCreate;
            delete object.Id;
            delete object.drugDetail;
            delete object.items;
            this.client = new Client("medicines-reconciliation");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let objects: Partial<MedicationReconciliationOderItem> = mrOrders!;
            objects = objects.map((item: any) => {
                const mrOrder = {
                    MedCode: item.MedCode,
                    code_time: item.code_time,
                    code_how: item.code_how,
                    code_unit: item.code_unit,
                    code_spec: item.code_spec,
                    LastTime: item.LastTime,
                    DrugAction: item.DrugAction,
                    Quantity: item.Quantity,
                    EachTime: item.EachTime,
                    Note: item.Note,
                    Day: item.Day,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    MedicationReconciliationId: "",
                    UpdatedDate: item.UpdatedDate,
                    UpdateBy: item.UpdateBy,
                    Canceled: false,
                    HowTimeDocPerDay: item.HowTimeDocPerDay,
                    HowHourDocPerDay: item.HowHourDocPerDay,
                    UnitTimeDocPerDay: item.UnitTimeDocPerDay,
                    UnitHourDocPerDay: item.UnitHourDocPerDay,
                    TimeTimeDocPerDay: item.TimeTimeDocPerDay,
                    TimeHourDocPerDay: item.TimeHourDocPerDay,
                    HourPeriod: item.HourPeriod,
                    CreatedBy: userCreate,
                };
                return (
                    mrOrder
                );
            });
            const datas = { object, objects };
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(this.state = data as MedicationReconciliationDto);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public update(id: string, data: MedicationReconciliationRequest) {
        return new Promise((resolve, reject) => {
            const user = localStorage.getItem("user");
            const currentDate = new Date();
            const userCreate = user ? JSON.parse(user).username : "";
            const mrItems = data.items;
            let updateStatus = true;
            let insertStatus = true;
            const object: Partial<MedicationReconciliationRequest> = data;
            object.UpdatedBy = userCreate;
            delete object.Id;
            delete object.items;
            // eslint-disable-next-line prefer-const
            let objectsUpdate = mrItems?.filter(x => x.MedicationReconciliationId === id);
            // eslint-disable-next-line prefer-const
            let objectsInsert = mrItems?.filter(x => x.MedicationReconciliationId !== id);
            this.client = new Client("medicines-reconciliation");
            this.client.update({ id: id ?? "", request: object }).then(async (result: any) => {
                if (objectsUpdate && objectsUpdate.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    const values = objectsUpdate.map((item: any) => {
                        const mrOrder = {
                            Id: item.Id,
                            MedCode: item.MedCode,
                            code_time: item.code_time,
                            code_how: item.code_how,
                            code_unit: item.code_unit,
                            code_spec: item.code_spec,
                            LastTime: item.LastTime,
                            DrugAction: item.DrugAction,
                            Quantity: item.Quantity,
                            EachTime: item.EachTime,
                            Note: item.Note,
                            Day: item.Day,
                            Canceled: item.Canceled,
                            MedicationReconciliationId: item.MedicationReconciliationId,
                            UpdatedDate: currentDate,
                            UpdatedBy: userCreate,
                            HowTimeDocPerDay: item.HowTimeDocPerDay,
                            HowHourDocPerDay: item.HowHourDocPerDay,
                            UnitTimeDocPerDay: item.UnitTimeDocPerDay,
                            UnitHourDocPerDay: item.UnitHourDocPerDay,
                            TimeTimeDocPerDay: item.TimeTimeDocPerDay,
                            TimeHourDocPerDay: item.TimeHourDocPerDay,
                            HourPeriod: item.HourPeriod,
                        };
                        return mrOrder;
                    });
                    this.client = new Client("medicines-reconciliation/mr-item");
                    await this.client.update({ id: id ?? "", request: { data: values } }).then((result: any) => {
                        if (result) {
                            updateStatus = true;
                        }
                    }).catch((err: any) => {
                        updateStatus = false;
                        reject(err);
                    });
                } else {
                    updateStatus = true;
                }
                if (objectsInsert && objectsInsert.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    const values = objectsInsert.map((item: any) => {
                        const objects = {
                            MedCode: item.MedCode,
                            code_time: item.code_time,
                            code_how: item.code_how,
                            code_unit: item.code_unit,
                            code_spec: item.code_spec,
                            LastTime: item.LastTime,
                            DrugAction: item.DrugAction,
                            Quantity: item.Quantity,
                            EachTime: item.EachTime,
                            Note: item.Note,
                            Day: item.Day,
                            Canceled: item.Canceled,
                            MedicationReconciliationId: id,
                            UpdatedDate: item.UpdatedDate,
                            UpdatedBy: item.UpdateBy,
                            HowTimeDocPerDay: item.HowTimeDocPerDay,
                            HowHourDocPerDay: item.HowHourDocPerDay,
                            UnitTimeDocPerDay: item.UnitTimeDocPerDay,
                            UnitHourDocPerDay: item.UnitHourDocPerDay,
                            TimeTimeDocPerDay: item.TimeTimeDocPerDay,
                            TimeHourDocPerDay: item.TimeHourDocPerDay,
                            HourPeriod: item.HourPeriod,
                            CreatedBy: userCreate,
                        };
                        return objects;
                    });
                    this.client = new Client("medicines-reconciliation/mr-item");
                    await this.client.create({ request: { data: values, mrID: id } }).then((result: any) => {
                        if (result) {
                            insertStatus = true;
                        }
                    }).catch((err: any) => {
                        insertStatus = false;
                        reject(err);
                    });
                } else {
                    insertStatus = true;
                }
                if (updateStatus && insertStatus) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: any) => {
                reject(err);
            });

        });
    }

    public deleteOrderItem(id: string) {
        if (this.state && id) {
            const filter = this.state?.items?.filter(x => x.Id !== id);
            this.state = { ...this.state, items: filter };
            // this.state = { ...this.state, items: filter as  MedicationReconciliationOderItem[]}
        }
    }


    public getPastVisit(hn: string, strDate: string, endDate: string, clinic: string): Promise<PastVisit[]> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), strDate: strDate, endDate: endDate, clinic: clinic };
            this.client = new Client("medicines-reconciliation/past-visit");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas) {
                    let data: PastVisit[] = [];
                    // eslint-disable-next-line prefer-const
                    data = datas.map((value: any) => {
                        return {
                            regNo: value.regNo,
                            visitNo: value.VisitNo,
                            registDate: value.registDate,
                            deptCode: value.dept
                        };
                    });
                    this.pastVisit = data;
                    resolve(this.pastVisit);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getMedPastVisit(visitNo: number): Promise<MedicineDto[]> {
        return new Promise((resolve, reject) => {
            const _params = { visitNo: visitNo };
            this.medLastVisit = [] as MedicineDto[];
            this.client = new Client("medicines-reconciliation/med-past-visit");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas) {
                    let data: MedicineDto[] = [];
                    // eslint-disable-next-line prefer-const
                    data = datas.map((value: any) => {
                        return {
                            invCode: value.invCode,
                            qty: value.qty,
                            lamedHow: value.lamedHow,
                            lamedUnit: value.lamedUnit,
                            lamedTime: value.lamedTime,
                            lamedSpecial: value.lamedSpecial,
                            amount: value.amount,
                            lamedCpy: value.lamedCpy,
                            lamedQty: value.lamedQty,
                            lamedDays: value.lamedDays,
                            qtyPerFeed: value.qtyPerFeed,
                            periodPerDay: value.periodPerDay,
                            name: value.name ?? '',
                            howUse: value.howUse ?? '',
                            unit: value.unit ?? '',
                            sequenceName: value.sequenceName ?? '',
                        } as any;
                    });
                    this.medLastVisit = data;
                    resolve(this.medLastVisit);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getMedIpdPriceByCode(code: string): Promise<number> {
        return new Promise((resolve, reject) => {
            if (code) {
                const _params = { code: code };
                this.client = new Client("medicines-reconciliation/med-ipd-price");
                this.client.getQuery({ query: _params }).then((result: any) => {
                    if (result && result.length > 0) {
                        let data: number = 0;
                        // eslint-disable-next-line prefer-const
                        data = result[0].ipd_prc;
                        resolve(parseFloat(data.toString().trim()));
                    } else {
                        resolve(0);
                    }
                }).catch((err: any) => {
                    reject(err);
                });
            }

        });
    }

    public updateOrderItem(data: MedicationReconciliationOderItem) {
        data.Canceled = false;
        if (this.state?.items) {
            this.state.items.push(data);
            this.currentMRItems?.push(data);
        }

    }

    public updateMRItemsFromPastVisit(data: MedicationReconciliationOderItem[]) {
        if (this.currentMRItems && this.currentMRItems.length > 0) {
            this.currentMRItems.push(...data);
        } else {
            this.currentMRItems = data;
        }
    }

    public updateMRItems(data: MedicationReconciliationOderItem, index: number) {
        if (data && this.currentMRItems && this.currentMRItems.length > 0) {
            // eslint-disable-next-line security/detect-object-injection
            this.currentMRItems[index] = data;
        } else {
            this.currentMRItems = [data];
        }
    }

    public deleteMRItems(id: string) {
        if (this.currentMRItems && this.currentMRItems.length > 0) {
            const filter = this.currentMRItems?.filter(x => x.Id !== id);
            this.currentMRItems = filter;
        }
    }

    public setMrItems(data: MedicationReconciliationOderItem[]) {
        this.currentMRItems = data;
    }

    public get medicationReconciliations() {
        return this.state;
    }

    public get mrItems() {
        return this.drugMrItem;
    }

    public get meds() {
        return this.drugItems;
    }

    public get medLastVisits() {
        return this.medLastVisit;
    }

    public get CurrentMRItems() {
        return this.currentMRItems;
    }

    public clearState() {
        this.state = {} as MedicationReconciliationDto;
    }
}
