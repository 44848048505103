/* eslint-disable security/detect-object-injection */
import { labService } from "@/app/models/domain/labs/lab";
import dayjs from "dayjs";
export function required<T>(
  value: T,
  errMsg: string = "Value is Required"
): boolean {
  if (value === undefined || value === null || value === "") {
    throw new Error(errMsg);
  }
  return true;
}

export function trimObject(obj: any) {
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      obj[index] = trimObject(item);
    });
  } else if (typeof obj === "object") {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object") {
        obj[key] = trimObject(obj[key]);
      }
    }
  }
  return obj;
}

export function convertValueToThaiDate(value: string) {
  let date = "";
  if (value === "MON") {
    date = "จันทร์";
  } else if (value === "TUE") {
    date = "อังคาร";
  } else if (value === "WED") {
    date = "พุธ";
  } else if (value === "THU") {
    date = "พฤหัสบดี";
  } else if (value === "FRI") {
    date = "ศุกร์";
  } else if (value === "SAT") {
    date = "เสาร์";
  } else if (value === "SUN") {
    date = "อาทิตย์";
  }
  return date;
}

export async function getLabType(code: string) {
  let typeName = "";
  await labService.getLabTypesByCode(code).then((res: any) => {
    typeName = res[0].lab_type_des;
  });
  return typeName;
}

export function convertTimeFomat(time: string) {
  const timeObj = dayjs(time, "HHmm");
  const value = dayjs(timeObj).format("HH:mm");
  return value.toString();
}

export const shouldLabelShrinkForNumber = (value: any) => {
  if (typeof value !== "number" || Number.isNaN(value)) {
    return false;
  }

  return true;
};

export const shouldLabelShrinkForString = (value: any) => {
  if (typeof value !== "string") {
    return false;
  }

  return value.length > 0;
};

/**
 *
 * @param value value from getValues('key')
 * @returns
 */
export const checkBoxCheckedStatus = (
  value: boolean | string | undefined | null
): boolean => {
  if (`${value}` === "true" || `${value}` === "Y") {
    return true;
  }

  return false;
};

export const booleanToStr = (value: boolean, isYesNo = true): string => {
  if (isYesNo) {
    return value ? "Y" : "N";
  }
  return value ? "true" : "false";
};

export const strToBoolean = (value: string, isYesNo = true): boolean => {
  if (isYesNo) {
    return value === "Y";
  }

  return value === "true";
};

export const filterObjectAttributes = (
  obj: Record<string, any>,
  allowList: string[]
): Record<string, any> => {
  const filteredObj: Record<string, any> = {};

  for (const key in obj) {
    if (allowList.includes(key)) {
      // eslint-disable-next-line security/detect-object-injection
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
};

export const setObjectAttributesWithCondition = (
  obj: Record<string, any>,
  setCondition: (_value: any, _key: string) => boolean,
  setValue: (_vaue: any) => any
): Record<string, any> => {
  const filteredObj: Record<string, any> = {};

  for (const key in obj) {
    const value = obj[key];
    filteredObj[key] = setCondition(value, key) ? setValue(value) : value;
  }

  return filteredObj;
};

export const gqlDateToStr = (date: Date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};
