import { makeAutoObservable } from "mobx";
import { DrugOrder } from "../drugs/Drug";
import { LabOrder } from "../labs/lab";
import { ProgressNoteDto } from "../progressNote/ProgressNote";
import { DoctorOrderForReport } from "@/components/Reports/ReportDoctorOrder";
import { OtherOrder } from "../otherOrders/Other";
import { XrayOrders } from "../xrays/Xray";
import Client from "../../../api/client";
export interface DoctorOrder {
    Id: string;
    an: string;
    data?: any;
    CreateAt?: string;
    CreateBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
}

export interface DoctorOrderView {
    Id: string;
    CreateAt?: string;
    CreateBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
    progressNote: ProgressNoteDto[];
    drugOrders: DrugOrder[];
    labOrders: LabOrder[];
    otherOrders: OtherOrder[];
    xrayOrders: XrayOrders[];
}
export class DoctorOrderModel {
    private state: DoctorOrder[] = [];
    private doctorOrderView: DoctorOrderView[] = [];
    private reportData: DoctorOrderForReport[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public create(data: DoctorOrder): Promise<boolean> {
        delete data.data;
        delete data.CreateAt;
        const user = localStorage.getItem("user");
        data.CreateBy = user ? JSON.parse(user).username : "";
        return new Promise((resolve, reject) => {
            this.client = new Client("create-doctor-order");
            this.client.create({ request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getDoctorOrders(an: string, startDate?: string, endDate?: string): Promise<DoctorOrder[]> {
        return new Promise((resolve, reject) => {
            let _params = {};
            if (startDate && endDate) {
                _params = { an: an.trim(), startDate: startDate, endDate: endDate };
            } else {
                _params = { an: an.trim() };
            }
            this.client = new Client("doctor-order");
            this.client.getQuery({ query: _params }).then((result: any) => {
                if (result) {
                    let doctorOrder = [] as DoctorOrder[];
                    doctorOrder = result;
                    resolve(this.state = doctorOrder);
                } else {
                    resolve([]);
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }


    public renderDoctorOrderView(data: DoctorOrderView[]) {
        if (data && data.length > 0) {
            this.doctorOrderView = data.sort((a: DoctorOrderView, b: DoctorOrderView) => {
                if (a.CreateAt && b.CreateAt) {
                    return new Date(b.CreateAt).getTime() - new Date(a.CreateAt).getTime();
                }
                return 0;
            });
        }
    }

    public setReportData(data: DoctorOrderForReport[]) {
        if (data && data.length > 0) {
            this.reportData = data.sort((a: DoctorOrderForReport, b: DoctorOrderForReport) => {
                if (a.orderDate && b.orderDate) {
                    return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime();
                }
                return 0;
            });
        }
    }

    public get DoctorOrders(): DoctorOrder[] {
        return this.state;
    }

    public get DoctorOrdersView(): DoctorOrderView[] {
        return this.doctorOrderView;
    }

    public get ReportData(): DoctorOrderForReport[] {
        return this.reportData;
    }


}
