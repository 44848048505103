
import { configure, makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import Client from "../../../api/client";
export interface PatientDto {
    ladmitN?: string | null;
    hn?: string | null;
    vn?: string | null;
    registFlag2?: string | null;
    codename?: string | null;
    /** รหัสแพทย์ */
    doctorCode?: string | null;
    backupLadmitN?: string | null;
    unused?: string | null;
    /** ห้อง */
    currentRoom?: string | null;
    runno?: string | null;
    wardId?: string | null;
    oldRoom?: string | null;
    oldRoomRunno?: string | null;
    wardOldRoom?: string | null;
    /** วันที่ Admit */
    admitDate?: string | null;
    admitTime?: string | null;
    firstDetailRec?: string | null;
    totAmount?: string | null;
    wlab?: string | null;
    wrad?: string | null;
    wmed?: string | null;
    wphy?: string | null;
    transferAckDate?: string | null;
    printFlag?: string | null;
    dischargeDate?: string | null;
    dischargeTime?: string | null;
    dischargeStatus?: string | null;
    noteText?: string | null;
    deptCode?: string | null;
    age?: string | null;
    legalFlag?: string | null;
    doccode?: string | null;
    discountAmount?: string | null;
    usrname?: string | null;
    diag?: string | null;
    lastChrFlag?: string | null;
    lastPrunno?: string | null;
    lastReqFlag?: string | null;
    respname?: string | null;
    resplaname?: string | null;
    respstat?: string | null;
    respadd1?: string | null;
    respadd2?: string | null;
    respphone?: string | null;
    resptitle?: string | null;
    backupFname?: string | null;
    backupLname?: string | null;
    /** @format int32 */
    lastDetailRx?: number | null;
    /** @format date-time */
    lastUpd?: string | null;
    dischargeType?: string | null;
    currentBed?: string | null;
    usrDischarge?: string | null;
    undousrDischarge?: string | null;
    borrowFlag?: string | null;
    /** @format date-time */
    borrowDate?: string | null;
    /** @format double */
    admWeight?: number | null;
    admHeight?: number | null;
    admDay?: string | null;
    /** @format date-time */
    wardDisctime?: string | null;
    wardDiscMaker?: string | null;
    currentRoomRate?: string | null;
    sicKlvl?: string | null;
    drgSend?: string | null;
    /** @format int32 */
    leaveDay?: number | null;
    dischargecode?: string | null;
    lastChargedate?: string | null;
    lastChargetime?: string | null;
    bedNo?: string | null;
    /** @format date-time */
    editdatetime?: string | null;
    editCompname?: string | null;
    regNo?: string | null;
    hhc?: string | null;
    shift?: string | null;
    sicKlvlStatus?: string | null;
    qIdCode?: string | null;
    usrAdmit?: string | null;
    wFood?: string | null;
    setBedBy?: string | null;
    /** @format date-time */
    retDate?: string | null;
    /** @format date-time */
    brwDate?: string | null;
    reAdmit?: string | null;
    /** @format int32 */
    reAdmitday?: number | null;
    currentRoomNew?: string | null;
    unpainIcu?: string | null;
    titleName?: string | null;
    /** ชื่อคนไข้ */
    fullName?: string | null;
    titleCode?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    sex?: string | null;
    lastRegister?: string | null;
    birthDay?: string | null;
    pOutStatus?: string | null;
    class?: string | null;
    opdMeter?: string | null;
    ipdMeter?: string | null;
    regDay?: string | null;
    special?: string | null;
    staff?: string | null;
    outStatus?: string | null;
    ipdStatus?: string | null;
    dept?: string | null;
    registDate?: string | null;
    borrowFlag1?: string | null;
    note?: string | null;
    totalAmt?: string | null;
    totalAmtPaid?: string | null;
    nonDiscAmt?: string | null;
    contCode?: string | null;
    lastDetail?: string | null;
    docName?: string | null;
    bsa?: string | null;
    blood?: string | null;
    /** แพทย์เจ้าของไข้ */
    admitDocName?: string | null;
    roomDes?: string | null;
    roomNo?: string | null;
    /** สิทธิ */
    payTypedes?: string | null;
    /** สังกัด */
    contName?: string | null;
    resStatus?: string | null;
    emerCode?: string | null;
    emerDesc?: string | null;
    emerStatus?: string | null;
    /** @format int32 */
    color?: number | null;
    syscode?: string | null;
    /** อายุ */
    bds?: string | null;
    ptHomed?: string | null;
    foodName?: string | null;
    /**
     * แพ้ยา
     * N=ไม่แพ้,Y=แพ้
     */
    ptMl?: string | null;
    foreigns?: string | null;
    waitLabXrayStat?: string | null;
    /** อาการ */
    symtom?: string | null;
    /** วงเงิน */
    limitAmt?: string | null;
    limitRemain?: string | null;

}

export interface PatientPersonalInfo {
    Id: string;
    addr1: string;
    addr2: string;
    mobilephone: string;
    phone: string;
    phoneExt: string;
    religion: string;
    mother: string;
}

export interface Religion {
    REGCODE: string;
    REGDES: string;
}

export interface DisplayPatientDrugAllergyDetail {
    drugCode: string;
    drugName: string;
    class: string;
    score: string;
    symptom: string;
    reportType: string;
    note: string;
    fatalLevel: string;
    causality: string;
    type: string;
    deleted?: string;
}

export interface DisplayContactHistory {
    hn: string;
    qtyComing: string;
    an: string;
    dateComing: string;
    status: string;
}

export interface DisplayVitalSignHistory {
    hn: string;
    visitDate: string;
    visitSignNo: number;
    RegNo: string;
    Symtom: string;
    Weight: string;
    Height: string;
    Lbloodpress: string;
    Hbloodpress: string;
    Temperature: string;
    Breathe: string;
    WHR: string;
    Pulse: string;
    maker: string;
}

export interface DisplayCurrentVisit1 {
    currentStatus: string;
    from: string;
    deptDesc: string;
    doctor: string;
    comingDate: string;
    comingTime: string;
    dischargeDate: string;
    dischargeTime: string;
    sendXray: string;
    sendLab: string;
    sendPharmacy: string;
    rxNo: string;
}

export interface DisplayVisitHistory {
    regNo: string;
    vistiDate: string;
    status: string;
    recieve: string;
    send: string;
    sendNote: string;
    recieveNote: string;
    hn: string;
}

export interface DisplayRxHistory {
    rxNo: string;
    doctorName: string;
    deptDesc: string;
    visitDate: string;
    visitTime: string;
    dischargeDate: string;
    dischargeTime: string;
    doctorCode: string;
    deptCode: string;
    maker: string;
}

export interface DisplayIPDResident {
    roomNo: string;
    roomType: string;
    wardId: string;
    wardName: string;
    roomStatus: string;
    dateIn: string;
    timeIn: string;
    dateOut: string;
    timeOut: string;
    transferFrom: string;
    hn: string;
    comingNo: string;
}

export interface PersonalData {
    CardID: string;
    AddrCardId: string;
}

export const mockPatient = Array.from({ length: 10 }, (_, i) => {
    return {
        id: i.toString(),
        room: "011/0" + (1 + i),
        type: "สามัญ",
        hn: "165883" + i,
        an: "655555" + i,
        level: "",
        name: "นาย สมชาย ชาตรี",
        totalAmount: 7000,
        paidAmount: 0,
        balanceAmount: 7000,
        finanAmount: 0,
        balanceFinanAmount: 0,
        reAdmit: false,
        reAdminDay: 0,
        unpainICU: false,
        allergic: false,
        detailAllergic: "Penicillin, Cephalosporin",
        levelAllergic: "Unclassified(อาการไม่ร้ายแรง)",
        birthdate: "01/31/1991",
        age: "61 ปี 25 วัน",
        doctor: "พญ. สุพัตรา",
        rightOfTreatment: "บัตรทอง",
        beUnder: "สุราษฎร์ธานี,รพศ.",
        note: "",
        sentLab: 0,
        sentXray: 0,
        admitDate: "11/01/2022",
        admitTime: "01:30",
        dischargeDate: "",
        dischargeTime: "",
        daig: "",
        numberOfCome: 2,
        color: "",
        condition: "เวียนศีรษะ อาเจียน",
        sex: "ชาย",
        weight: 65,
        height: 175,
        class: "ผู้ป่วยมะเร็ง",
        blood: "AB",
        bsa: "....."
    };
});
export class PatientModel {
    private client: Client<any>;
    private state: PatientDto[] = [];
    private personalInfo = {} as PatientPersonalInfo;
    private patientDrugAllergy: DisplayPatientDrugAllergyDetail[] = [];
    private masterReligion: Religion[] = [];
    private ipdHData: any[] = [];
    private visitHistory: DisplayVisitHistory[] = [];
    private rxHistory: DisplayRxHistory[] = [];
    private ipdResident: DisplayIPDResident[] = [];
    private patientQTY: number = 0;
    private checkedFlag: boolean = false;
    constructor() {
        this.client = new Client("");
        makeAutoObservable(this);
        configure({
            enforceActions: "never",
        });
    }

    public getPersonalDataByHn(hn: string): Promise<PatientPersonalInfo> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("patient-personal");
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res && res.length > 0) {
                    resolve(res);
                } else {
                    resolve({} as PatientPersonalInfo);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getReligion(): Promise<Religion[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("religions");
            this.client.getAll().then((res: any[]) => {
                this.masterReligion = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getPatientPersonalData(hn: string): Promise<PersonalData> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("patient-card");
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res && res.length > 0) {
                    const PersonalData = {
                        CardID: res[0].CardID,
                        AddrCardId: res[0].AddrCardID
                    } as PersonalData;
                    resolve(PersonalData);
                } else {
                    resolve({} as PersonalData);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getAll(ward: string) {
        return new Promise((resolve, reject) => {
            const currentDate = dayjs().format("BBBBMMDD");
            this.client = new Client("patients");
            const res_status = "R";
            const discharge_date_from = currentDate;
            const discharge_date_to = currentDate;
            const _params = { wardId: ward, res_status: res_status, discharge_date_from: discharge_date_from, discharge_date_to: discharge_date_to };
            this.client.getQuery({ query: _params }).then((res: any[]) => {
                if (res && res.length > 0) {
                    let patients: PatientDto[] = [];
                    patients = res.map((value: any) => {
                        return {
                            fullName: value.FullName,
                            currentRoom: value.room_no,
                            wardId: value.ward_id,
                            hn: value.hn,
                            bds: value.BDS,
                            ladmitN: value.ladmit_n,
                            payTypedes: value.pay_typedes,
                            admitDocName: value.DocName,
                            roomDes: value.room_des,
                            contName: value.contName,
                            birthDay: value.birthDay,
                            totalAmt: value.totalAmt,
                            totalAmtPaid: value.totalAmtPaid,
                            remainAmt: value.nonDiscAmt,
                            sex: value.sex,
                            // don't have Height from database
                            admWeight: value.adm_weight,
                            symtom: value.Symtom,
                            admitDate: value.admit_date,
                            lastChargedate: value.lastChargedate,
                            lastChargetime: value.lastChargetime,
                            class: value.class,
                            opdMeter: value.opdMeter,
                            ipdMeter: value.ipdMeter,
                            foreigns: value.Foreigns,
                            registDate: value.registDate,
                            regNo: value.RegNo,
                            admitTime: value.admit_time,
                            dischargeDate: value.discharge_date,
                            dischargeTime: value.discharge_time,
                            // dont' have
                            // Blodd,BAS,Drug Allergy,วงเงิน ทั้ง 2
                            resStatus: value.res_status,
                            doccode: value.doccode,
                        } as PatientDto;
                    });
                    localStorage.setItem("patient", JSON.stringify(patients));
                    this.state = patients;
                    this.patientQTY = patients.length;
                    resolve(this.state);
                } else {
                    this.state = [];
                    this.patientQTY = 0;
                    resolve(this.state);
                }
                localStorage.setItem("patientQTY", JSON.stringify(this.patientQTY));
            }).catch((err: any) => {
                reject(err);
            });
        });


    }

    public getVisitHistory(hn: string) {
        const _params = { hn: hn.trim() };
        this.client = new Client("patient-visits");
        this.client.getQuery({ query: _params }).then((res: any) => {
            if (res && res.length > 0) {
                this.visitHistory = res.map((value: any) => {
                    return {
                        regNo: value.regNo,
                        vistiDate: value.registDate,
                        status: value.ipdStatus,
                        recieve: "",
                        send: "",
                        sendNote: "",
                        recieveNote: "",
                        hn: value.hn,
                    };
                });
                this.getRxHistory(hn, this.visitHistory[0].regNo);
                this.getIPDResident(hn, this.visitHistory[0].regNo);
            } else {
                this.visitHistory = [];
                this.rxHistory = [];
                this.ipdResident = [];
            }
        });
    }

    public getRxHistory(hn: string, regNo: string) {
        const _params = { hn: hn.trim(), regNo: regNo.trim() };
        this.client = new Client("patient-rxs");
        this.client.getQuery({ query: _params }).then((res: any) => {
            if (res && res.length > 0) {
                this.rxHistory = res.map((value: any) => {
                    return {
                        rxNo: value.rxNo,
                        docName: value.docName && value.docLName ? value.docName + "" + value.docLName : "",
                        deptDesc: value ? value.deptDesc : "",
                        visitDate: value.qDateIn,
                        visitTime: value.qTimeIn,
                        dischargeDate: value.qDateOut,
                        dischargeTime: value.qTimeOut,
                        deptCode: value.deptCode,
                        doctorCode: value.docCode,
                        maker: value.maker
                    };
                });
            } else {
                this.rxHistory = [];
            }
        });
    }

    public getIPDResident(hn: string, regNo: string) {
        const _params = { hn: hn.trim(), regNo: regNo.trim() };
        this.client = new Client("patient-res");
        this.client.getQuery({ query: _params }).then((res: any) => {
            if (res && res.length > 0) {
                this.ipdResident = res.map((value: any) => {
                    return {
                        roomNo: value.room_no,
                        roomType: value.room_des,
                        wardId: value.ward_id ? value.ward_id : "",
                        wardName: value.ward_name ? value.ward_name : "",
                        resStatus: value.res_status === "R" ? "พักอยู่" : value.res_status === "C" ? "ออกแล้ว" : value.res_status === "N" ? "จองไว้" : "ย้าย",
                        dateIn: value.check_in_date,
                        timeIn: value.check_in_time,
                        dateOut: value.check_out_date,
                        timeOut: value.check_out_time,
                        transferFrom: value.fromroom,
                        hn: value.hn,
                        comingNo: value.regist_flag
                    };
                });
            } else {
                this.ipdResident = [];
            }
        });
    }

    public getPatientDrugAllergy(hn: string) {
        const _params = { hn: hn.trim() };
        this.client = new Client("patient-drug-allergies");
        this.client.getQuery({ query: _params }).then((res: any) => {
            if (res && res.length > 0) {
                this.patientDrugAllergy = res.map((value: any) => {
                    return {
                        drugCode: value.medCode,
                        drugName: value.name ? value.name : "",
                        class: value.classDesc ? value.classDesc : "",
                        score: value.medScore ? value.medScore : "",
                        symptom: value.Symptom.trim(),
                        reportType: value.ADRtype ? value.ADRtype : "",
                        note: value.alergyNote ? value.alergyNote : "",
                        fatalLevel: value.AllergDesc ? value.AllergDesc : "",
                        causality: value.causality ? value.causality : "",
                        delFlag: value.delFlag ? value.delFlag : "",
                        type: value.alergyType ? value.alergyType : ""
                    };
                });
            } else {
                this.patientDrugAllergy = [];
            }
        });
    }


    public getByAN(an: string) {
        if (this.state.length > 0) {
            return this.state.find(a => a.ladmitN === an);
        }
        else {
            const data = localStorage.getItem("patient");
            const patients = JSON.parse(data ?? '[]') as PatientDto[];
            if (patients) {
                const _patient = patients.find(x => x.resStatus === "R" && x.ladmitN === an);
                const promise = [];
                promise.push(this.getUpdateAmount(_patient?.hn ?? "", _patient?.regNo ?? ""));
                Promise.all(promise).then((result) => {
                    if (result && result.length > 0) {
                        const updateAmount = result[0];
                        if (updateAmount && _patient) {
                            _patient.totalAmt = updateAmount.totalAmt;
                            _patient.totalAmtPaid = updateAmount.totalAmtPaid;
                        }
                    }
                });
                return _patient ?? {} as PatientDto;
            }
        }
    }


    public getUpdateAmount(hn: string, regNo: string): Promise<PatientDto> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("patient-bill-amounts");
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve({} as PatientDto);
                }
            });
        });
    }

    public getByValue(value: string, dischargeFlage: boolean) {
        this.checkedFlag = dischargeFlage;
        return new Promise((resolve, reject) => {
            const data = localStorage.getItem("patient");
            const patients = JSON.parse(data ?? '[]') as PatientDto[];
            if (dischargeFlage) {
                const patientDatas = this.ipdHData.map((discharge: any) => {
                    const data = patients.find((data: any) => data.ladmitN === discharge.ladmit_n && data.currentRoom === discharge.current_room);
                    if (!data) {
                        return {};
                    }
                    return {
                        fullName: data.fullName,
                        currentRoom: data.currentRoom,
                        wardId: data.wardId,
                        hn: data.hn,
                        bds: data.bds,
                        ladmitN: data.ladmitN,
                        payTypedes: data.payTypedes,
                        admitDocName: data.admitDocName,
                        roomDes: data.roomDes,
                        contName: data.contName,
                        birthDay: data.birthDay,
                        totalAmt: data.totalAmt,
                        totalAmtPaid: data.totalAmtPaid,
                        remainAmt: data.nonDiscAmt,
                        sex: data.sex,
                        // don't have Height from database
                        admWeight: data.admWeight,
                        symtom: data.symtom,
                        admitDate: data.admitDate,
                        lastChargedate: data.lastChargedate,
                        lastChargetime: data.lastChargetime,
                        class: data.class,
                        opdMeter: data.opdMeter,
                        ipdMeter: data.ipdMeter,
                        foreigns: data.foreigns,
                        registDate: data.registDate,
                        regNo: data.regNo,
                        admitTime: data.admitTime,
                        dischargeDate: discharge.discharge_date,
                        dischargeTime: discharge.discharge_time,
                    } as PatientDto;
                }).filter(patient => patient !== null);
                if (patientDatas && patientDatas.length > 0) {
                    if (value) {
                        this.state = patientDatas.filter(a => a?.ladmitN?.trim() === value || a?.hn?.trim() === value || a?.fullName?.trim().includes(value));
                    } else {
                        this.state = patientDatas;
                    }
                    resolve(this.state);
                }
            } else {
                if (patients && patients.length > 0) {
                    if (value) {
                        this.state = patients.filter(a => (a.ladmitN?.trim() === value.trim() || a.hn?.trim() === value.trim() || a.fullName?.trim().includes(value)) && a.resStatus === "R");
                    } else {
                        this.state = patients.filter(a => a.resStatus === "R");
                    }
                }
                resolve(this.state);
            }
        });

    }


    public getByFilterDischarged(discharged: boolean, startDate: string, endDate: string, ward: string) {
        this.checkedFlag = discharged;
        return new Promise((resolve, reject) => {
            this.client = new Client("patients");
            const res_status = "C";
            const discharge_date_from = startDate;
            const discharge_date_to = endDate;
            // const patients = localStorage.getItem("patient");
            // const jsonPatient = JSON.parse(patients ?? '[]') as PatientDto[];
            const _params = { wardId: ward, res_status: res_status, discharge_date_from: discharge_date_from, discharge_date_to: discharge_date_to };
            if (discharged) {
                this.client.getQuery({ query: _params }).then((res: any[]) => {
                    if (res && res.length > 0) {
                        let patients: PatientDto[] = [];
                        patients = res.map((value: any) => {
                            return {
                                fullName: value.FullName,
                                currentRoom: value.room_no,
                                wardId: value.ward_id,
                                hn: value.hn,
                                bds: value.BDS,
                                ladmitN: value.ladmit_n,
                                payTypedes: value.pay_typedes,
                                admitDocName: value.DocName,
                                roomDes: value.room_des,
                                contName: value.contName,
                                birthDay: value.birthDay,
                                totalAmt: value.totalAmt,
                                totalAmtPaid: value.totalAmtPaid,
                                remainAmt: value.nonDiscAmt,
                                sex: value.sex,
                                // don't have Height from database
                                admWeight: value.adm_weight,
                                symtom: value.Symtom,
                                admitDate: value.admit_date,
                                lastChargedate: value.lastChargedate,
                                lastChargetime: value.lastChargetime,
                                class: value.class,
                                opdMeter: value.opdMeter,
                                ipdMeter: value.ipdMeter,
                                foreigns: value.Foreigns,
                                registDate: value.registDate,
                                regNo: value.RegNo,
                                admitTime: value.admit_time,
                                dischargeDate: value.discharge_date,
                                dischargeTime: value.discharge_time,
                                // dont' have
                                // Blodd,BAS,Drug Allergy,วงเงิน ทั้ง 2
                                resStatus: value.res_status,
                                doccode: value.doccode,
                            } as PatientDto;
                        });
                        localStorage.setItem("patient", JSON.stringify(patients));
                        this.state = patients;
                    } else {
                        this.state = [];
                    }
                    resolve(this.state);
                }).catch((err: any) => {
                    console.log(err);
                    reject(err);
                });
            } else {
                const user = localStorage.getItem("user");
                const ward = JSON.parse(user ?? "{}").selectWard;
                if (ward) {
                    resolve(this.getAll(ward.id));
                }
                // this.state = jsonPatient.filter(x => x.resStatus === "R");
                // resolve(this.state);
            }

        });
    }

    public getPatientRxNo(hn: string, regNo: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("lastpatraxdetno");
            this.client.getQuery({ query: _params }).then((res: any[]) => {
                if (res) {
                    resolve(res[0]);
                } else {
                    resolve("");
                }
            });
        });
    }

    public get patients() {
        return this.state;
    }

    public get PersonalInfo() {
        return this.personalInfo;
    }

    public get MasterReligion() {
        return this.masterReligion;
    }

    public get PatientDrugAllergy() {
        return this.patientDrugAllergy;
    }

    public get VisitHistory() {
        return this.visitHistory;
    }

    public get RxHistory() {
        return this.rxHistory;
    }

    public get IpdResident() {
        return this.ipdResident;
    }

    public get PatientQTY() {
        return this.patientQTY;
    }

    public get CheckedFlag() {
        return this.checkedFlag;
    }
}


