import { makeAutoObservable } from "mobx";

import Client from "../../../api/client";

export interface LearnTimeDto {
    lamedCode?: string;
    lamedEng?: string;
    lamedName?: string;
    translate?: string;
    alias?: string;
    dose?: number;
    timePerday?: number;
    hourperiod?: number;
    hrPerday?: string;
    calcQty?: string;
    hideselect?: string;
    docHideselect?: string;
    timeDocperday?: number;
    lamedNameNew?: string;
    hourDocperiod?: string;
    docCalcQty?: string;
}

export const mockTimeUse = [
    //ของยา 1
    {
        id: "1", name: "เช้า-กลางวัน", drugId: "1"
    },
    {
        id: "2", name: "ก่อนนอน", drugId: "1"
    },
    //ของยา 2
    {
        id: "3", name: "หลังอาหาร", drugId: "2"
    },
    //ของยา 3
    {
        id: "4", name: "เช้า-เย็น-ก่อนนอน", drugId: "3"
    },
    {
        id: "5", name: "กลางวัน-ก่อนนอน", drugId: "3"
    },
];

export class LearnTimeModel {
    private state: LearnTimeDto[] = [];
    private client: Client<LearnTimeDto>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("LearnTime");
    }
    //TODO: dont use param
    public getTimeUse(drugId: string): Promise<LearnTimeDto[]> {
        return new Promise((resolve, reject) => {
            // this.client.getAll("lameds").then(data => {
            //     this.state = data;
            //     resolve(data);
            // }).catch(err => {
            //     reject(err);
            // });
        });
    }

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }

        // TODO: uncomment if use api, remove if not
        // this.client.getAll().then(data => {
        //     this.state = data;
        // }).catch(err => {
        //     throw err;
        // });
    }
    public get learnTimes() {
        return this.state;
    }
}
