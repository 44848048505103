import { Box, CssBaseline, CSSObject, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Theme, Toolbar, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";
import LogoutIcon from '@mui/icons-material/Logout';
import menuItems from "../../menu-items";
import { User } from "../../app/models/domain/users/User";
import { theme } from "../../themes";
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';

// import { PatientDto } from "@/app/models/domain/patients/Patient";


interface Props {
    user?: User;
    onLogout: () => void;
}
const MainLayout = (props: Props) => {
    const { an } = useParams();
    const drawerWidth = 240;

    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: (an) ? 0 : `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
            width: (an) ? 0 : `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
    })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                "& .MuiDrawer-paper": closedMixin(theme),
            }),
        }),
    );

    const location = useLocation();
    const [open, setOpen] = useState(useMediaQuery(theme.breakpoints.up('md')) ? true : false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const onLogout = () => {
        props.onLogout();
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileOpen = Boolean(anchorEl);
    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (an) {
            handleDrawerClose();
        }
    }, [an]);


    const getPatientQuantity = () => {
        const data = localStorage.getItem("patientQTY");
        if (data) {
            return data;
        } else {
            return "";
        }
    };

    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ bgcolor: "white", }} elevation={1}>
                <Toolbar sx={{ color: "text.primary", justifyContent: "space-between" }}>
                    <Box component={"div"} display="flex" alignItems={"center"}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="close drawer"
                            onClick={handleDrawerClose}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(!open && { display: "none" }),
                            }}>
                            <MenuOpenIcon />
                        </IconButton>
                        <Typography variant="h4" noWrap component="div">
                            {props.user?.selectWard?.name}
                        </Typography>
                        <Typography variant="h4" noWrap component="div" sx={{ paddingLeft: '20px' }}>
                            {`จำนวนผู้ป่วยทั้งหมด ${getPatientQuantity()} คน`}
                        </Typography>
                    </Box>
                    <Box component={"div"} display="flex" alignItems={"center"}>
                        <Box component={"div"} alignItems={"center"} display={useMediaQuery(theme.breakpoints.up('md')) ? "flex" : "none"}>
                            <AccountCircleIcon sx={{ mr: 1 }} />
                            <Typography mr={2}>{props.user?.name || props.user?.username}</Typography>
                            <Tooltip title="ออกจากระบบ">
                                <IconButton onClick={onLogout}>
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box component={"div"} alignItems={"center"} display={useMediaQuery(theme.breakpoints.up('md')) ? "none" : "flex"}>
                            <IconButton onClick={handleProfileClick}>
                                <AccountCircleIcon sx={{ mr: 1 }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={profileOpen}
                                onClose={handleProfileClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem> <Typography>{props.user?.name || props.user?.username}</Typography></MenuItem>
                                <MenuItem onClick={onLogout}><LogoutIcon sx={{ mr: 2 }} />Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <LocalHospitalTwoToneIcon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h3" alignSelf={"center"}>IPD System</Typography>
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.items.map((item, index) => {
                        const children = item.children;
                        return (
                            <Box component={"div"} key={index}>
                                <Typography key={item.id} variant="caption" sx={{ p: 2.5, display: open ? "initial" : "none" }}>
                                    {item.title}
                                </Typography>
                                {children.map((menu: any) => {
                                    let itemTarget = "_self";
                                    if (menu.target) {
                                        itemTarget = "_blank";
                                    }
                                    const listItemProps = { component: forwardRef<HTMLAnchorElement>((props, ref) => <Link ref={ref} {...props} to={menu.url} target={itemTarget} />) };
                                    return (<ListItem key={menu.id} disablePadding sx={{ display: "block" }}>
                                        <ListItemButton
                                            {...listItemProps}
                                            selected={location.pathname.split("/")[1] === menu.url.split("/")[1]}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? "initial" : "center",
                                                px: 2.5,
                                            }}
                                        >
                                            {menu.icon && <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : "auto",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {<menu.icon />}
                                            </ListItemIcon>}
                                            <ListItemText primary={menu.title} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                    );
                                })}
                            </Box>
                        );
                    })}
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <List dense>
                    <Box component={"div"}>
                        <Typography variant="caption" sx={{ p: 2.5, display: open ? "initial" : "none" }}>
                            Supports
                        </Typography>
                    </Box>
                    <ListItemButton onClick={() => { navigate('support/manual'); }} >
                        <ListItemIcon><ArticleIcon sx={{ color: 'black' }} /></ListItemIcon>
                        <ListItemText primary="คู่มือการใช้งาน" />
                    </ListItemButton>
                    <ListItemButton onClick={() => { navigate('support/contact'); }} >
                        <ListItemIcon><HelpIcon sx={{ color: 'black' }} /></ListItemIcon>
                        <ListItemText primary="แจ้งปัญหาการใช้งาน" />
                    </ListItemButton>
                </List>
            </Drawer>
            <Box component="main" sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;