import { CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";

const MinimalLayout = () => (
    <>
        <CssBaseline />
        <Outlet />
    </>
);

export default MinimalLayout;