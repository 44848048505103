import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface MedicineMRDto {
    abbr?: string;
    dform?: string;
    strgth?: string;
    site?: string;
    package?: string;
    code?: string;
    strgthU?: string;
    subCnt?: number;
    genShname?: string;
    genName?: string;
    unit?: string;
    packsize?: string;
    discF?: string;
    chargeC?: string;
    opdPrc?: string;
    ipdPrc?: string;
    buyPrc?: number;
    prodType?: string;
    equiUnit?: number;
    medClassCode?: string;
    foreignOpdPrc?: string;
    foreignIpdPrc?: string;
    staffOpdPrc?: string;
    staffIpdPrc?: string;
    color?: number;
    midPrc?: number;
    defDose?: string;
    terminate?: string;
    hideSelect?: string;
    termReason?: string;
    specialMed?: string;
    manufac?: string;
    canFree?: string;
    refund?: string;
    sysId?: string;
    ipdChargeC?: string;
    homeChargeC?: string;
    medKw?: string;
    name?: string;
    abbrName?: string;
    packForm?: string;
    maxuses?: string;
    maxdose?: string;
    curBal?: number;
    vatFlag?: string;
    pairCode?: string;
    opdSaleU?: number;
    sname?: string;
    hasinfo?: string;
    lastUprice?: string;
    sKw?: string;
    sequence?: number;
    // ราคาขาย
    ipd_prc?: number;
    foreign_ipd_prc?: number;
    // ราคาต้นทุน
    buy_prc?: number;
    doses?: DoseDto[];
}



interface DoseDto {
    codeLamed?: string;
    codeHow?: string;
    unitLamedC?: string;
    codeUnit?: string;
    period?: string;
    codeTime?: string;
    codeSpec?: string;
    timePerday?: number;
    unitChoice?: string;
    unitPack?: string;
    howName?: string;
    howEng?: string;
    unitName?: string;
    unitEng?: string;
    timeName?: string;
    timeEng?: string;
    specName?: string;
    specEng?: string;
    timeperday1?: number;
    hrPerday?: string;
}

export interface MedicineItem {
    code: string;
    name: string;
    prodType?: string;
    packsize?: string;
    unit?: string;
    time?: string;
    how?: string;
}

export class MedicineModel {
    private state: MedicineMRDto[] = [];
    private allMedicine: MedicineMRDto[] = [];
    private masterMedicine: MedicineMRDto[] = [];
    private client: Client<MedicineMRDto>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("Medicine");
    }

    public async getMedicineByCode(code: string): Promise<MedicineMRDto> {
        return new Promise((resolve, reject) => {
            const _params = { code: code };
            this.client = new Client("medicine/code");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    const medicine = data.map((value: any) => {
                        return {
                            name: value.name,
                            prodType: value.prod_type,
                            packsize: value.packsize,
                            unit: value.unit,
                            sequence: value.sequence,
                            code: value.code,
                            refund: value.refund,
                            doses: []
                        } as MedicineMRDto;
                    });
                    resolve(medicine[0]);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }


    public getMedicineByNames(name: string) {
        if (name.length < 1) {
            this.state = [] as MedicineMRDto[];
        } else {
            const _params = { name: name };
            this.client = new Client("medicine/name");
            setTimeout(() => {
                this.client.getQuery({ query: _params }).then((data: any) => {
                    if (data) {
                        const medicine = data.map((value: any) => {
                            return {
                                name: value.name,
                                prodType: value.prod_type,
                                packsize: value.packsize,
                                unit: value.unit,
                                sequence: value.sequence,
                                code: value.code,
                                ipd_prc: value.ipd_prc,
                                buy_prc: value.buy_prc,
                                foreign_ipd_prc: value.foreign_ipd_prc,
                                doses: []
                            } as MedicineMRDto;
                        });
                        this.state = medicine;
                    }
                }).catch((error: any) => {
                    console.log(error);
                });
            }, 500);
        }
    }

    public getAllMedicine(): Promise<MedicineMRDto[]> {
        return new Promise((resolve, reject) => {
            if (this.allMedicine.length > 0) {
                resolve(this.allMedicine);
            } else {
                this.client = new Client("medicine");
                setTimeout(() => {
                    this.client.getAll().then((data: any) => {
                        if (data) {
                            const medicine = data.map((value: any) => {
                                return {
                                    name: value.name,
                                    prodType: value.prod_type,
                                    packsize: value.packsize,
                                    unit: value.unit,
                                    sequence: value.sequence,
                                    code: value.code,
                                    ipd_prc: value.ipd_prc,
                                    buy_prc: value.buy_prc,
                                    foreign_ipd_prc: value.foreign_ipd_prc,
                                    doses: []
                                } as MedicineMRDto;
                            });
                            resolve(this.allMedicine = medicine);
                        }
                    }).catch((error: any) => {
                        reject(error);
                    });
                }, 500);
            }
        });
    }

    public getMasterMedicine() {
        if (this.masterMedicine.length === 0) {
            this.client = new Client("medicine");
            setTimeout(() => {
                this.client.getAll().then((data: any) => {
                    if (data) {
                        const medicine = data.map((value: any) => {
                            return {
                                name: value.name,
                                prodType: value.prod_type,
                                packsize: value.packsize,
                                unit: value.unit,
                                sequence: value.sequence,
                                code: value.code,
                                ipd_prc: value.ipd_prc,
                                buy_prc: value.buy_prc,
                                foreign_ipd_prc: value.foreign_ipd_prc,
                                doses: []
                            } as MedicineMRDto;
                        });
                        this.masterMedicine = medicine;
                    }
                }).catch((error: any) => {
                    console.log(error);
                });
            }, 500);
        }
    }

    //#region utils function
    public getMedicineByCodeFromAllMedicine(code: string) {
        // eslint-disable-next-line prefer-const
        let drugName = "";
        // eslint-disable-next-line prefer-const
        this.getMedicineByCode(code).then((data) => {
            drugName = data.name ? data.name : "";
        });
        return drugName;
    }
    //#endregion

    public get masterMedicines() {
        return this.masterMedicine;
    }


    public get allMedicines() {
        return this.allMedicine;
    }

    public get medicines() {
        return this.state;
    }

    public clearMedicine() {
        this.state = [] as MedicineMRDto[];
    }
}

export const medicineService = new MedicineModel();