
import GroupsIcon from "@mui/icons-material/Groups";
const icons = {
    GroupsIcon
};
const patients = {
    id: "patients",
    title: "ผู้ป่วย",
    type: "group",
    children: [
        {
            id: "patient-list",
            title: "รายชื่อผู้ป่วย",
            type: "item",
            url: "/patient",
            icon: icons.GroupsIcon,
            target: false
        },
    ]
};

export default patients;