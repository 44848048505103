
import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";
// import Other Model
import { MedicationReconciliationOderItem } from "../medicationReconciliation/MedicationReconciliation";


export interface DrugLastVisitDto {
    id?: string;
    room: string | null;
    dischargeDate: string | null;
    clinic: string;
    drugsOrder: MedicationReconciliationOderItem[];
}

export class DrugLastVisitModel {
    private state?: DrugLastVisitDto[] = [];
    private client: Client<DrugLastVisitDto>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("DrugLastVisit");
    }

    // TODO: add some function for work


    public get drugLastVisits() {
        return this.state;
    }
}
