import { makeAutoObservable } from "mobx";
import { SelectWard } from "../selectWards/SelectWard";

export interface User {
    username?: string;
    firstName?: string;
    lastName?: string;
    user_id?: string;
    dept_id?: string;
    pos_id?: string;
    department?: string;
    selectWard?: SelectWard;
    token?: string;
    firebaseToken?: string;
    error?: string;
    lastname?: string;
    name?: string;
    position?: string;
    status?: number;
}

export class UserModel {
    private state?: User;
    constructor() {
        const seluser = localStorage.getItem("user");
        if (seluser) {
            this.state = JSON.parse(seluser);
        }
        makeAutoObservable(this);
    }

    public add(user?: User) {
        if (user) {
            localStorage.setItem("user", JSON.stringify(user));
            this.state = user;
        }
    }

    public selectWard(selectWard?: SelectWard) {
        localStorage.setItem("user", JSON.stringify({ ...this.state, selectWard: selectWard }));
        this.state = { ...this.state, selectWard: selectWard };
    }

    public delete() {
        localStorage.removeItem("user");
        this.state = {};
    }

    public get users() {
        return this.state;
    }
}