import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";
import { Labreq_d } from "../labs/lab";
import { Xreq_d } from "../xrays/Xray";
export interface AppointmentDto {
    id: string;
    doctorAppointment: string;
    appointmentDate: string;
    appointmentStartTime: string;
    appointmentEndTime: string;
    appointmentNote: string;
    appointmentSpecialNote: string;
    department: string;
    an: string;
    hn: string;
    diag: string;
    labAppointmentOrder: Labreq_d[];
    xrayAppointmentOrder: Xreq_d[];
}

export interface AppointmentRequest {
    id: string;
    doctorAppointment: string;
    appointmentDate: string;
    appointmentStartTime: string;
    appointmentEndTime: string;
    appointmentNote: string;
    appointmentSpecialNote: string;
    department: string;
    an: string;
    hn: string;
    diag: string;
    labAppointmentOrder: Labreq_d[];
    xrayAppointmentOrder: Xreq_d[];

}


export interface ICD10 {
    id: number;
    code: string;
    name: string;
}

export interface Appoint {
    /**
     * app_type ประเภทการนัด
     * @default "A"
     */
    app_type: string;
    doctor: string;
    hn: string;
    /**
     * appoint_date วันที่นัด ในรูปแบบ BBBBMMDD
     * @example 25660428
     */
    appoint_date: string;
    /**
     * appoint_time_from เวลาที่นัด ในรูปแบบ HH:mm
     * @example 08:00
     */
    appoint_time_from: string;
    /**
     * appoint_time_to เวลาที่นัด ในรูปแบบ HH:mm
     * @example 09:00
     */
    appoint_time_to: string;
    /**
     * appoint_note หมายเหตุ
     * 
     */
    appoint_note: string;
    pre_dept_code: string;
    maker: string;
    /**
     * keyin_time วันเวลาที่สร้างข้อมูล ในรูปแบบ Datetime
     * @example 2023-04-08 17:34:03.650
     */
    keyin_time: string;
    lastDetail: number;
    /**
     * remark ข้อความพิเศษ
     * 
     */
    remark: string;
    /**
     * pt_status
     * @default "I"
     */
    pt_status: string;
    reference_phone: string;
    reference_person: string;
    appoint_dept: string;
    appoint_regNo: string;
    endReqUser: string;
    userToken: string;
    deptDesc: string;
}

export interface AppointmentData {
    id: string;
    appoint: Appoint;
    PatName: string;
    DocName: string;
    deptDesc: string;
}

export const mockAppointment = [

    {
        id: '1',
        doctorAppointment: 'Dr. Smith',
        // appointmentDate: '2022/01/01',
        appointmentDate: '2023-03-25',
        appointmentStartTime: '0900',
        appointmentEndTime: '1000',
        appointmentNote: 'Patient has a broken arm',
        appointmentSpecialNote: 'Patient is allergic to penicillin',
        department: 'Orthopedics',
        an: '6543088',
        hn: '654321',
        diag: 1,
        labAppointmentOrder: [
            {
                id: '1',
                lab_code: 'c001',
                lab_name: 'CBC',
                lab_prc: '2500'
            },
            {
                id: '2',
                lab_code: 'c002',
                lab_name: 'CBC2',
                lab_prc: '2500'
            }
        ],
        xrayAppointmentOrder: [],
    },
    {
        id: '2',
        doctorAppointment: 'Dr. Johnson',
        // appointmentDate: '2022/01/02',
        appointmentDate: '2023-03-25',
        appointmentStartTime: '1400',
        appointmentEndTime: '1500',
        appointmentNote: 'Patient has a cold',
        appointmentSpecialNote: 'Patient is diabetic',
        department: 'General Medicine',
        an: '6543088',
        hn: '765432',
        diag: 2,
        labAppointmentOrder: [],


        xrayAppointmentOrder: [],
    },
    {
        id: '3',
        doctorAppointment: 'Dr. Garcia',
        // appointmentDate: '2022/01/03',
        appointmentDate: '2023-03-27',
        appointmentStartTime: '1030',
        appointmentEndTime: '1130',
        appointmentNote: 'Patient has a back pain',
        appointmentSpecialNote: 'Patient is pregnant',
        department: 'Physical Therapy',
        an: '6543088',
        hn: '876543',
        diag: 3,
        labAppointmentOrder: [],
        xrayAppointmentOrder: [],

    },
    {
        id: '4',
        doctorAppointment: 'Dr. Martinez',
        // appointmentDate: '2022/01/04',
        appointmentDate: '2023-03-25',
        appointmentStartTime: '0930',
        appointmentEndTime: '1030',
        appointmentNote: 'Patient has a headache',
        appointmentSpecialNote: '',
        department: 'Neurology',
        an: '6543088',
        hn: '987654',
        diag: 4,
        labAppointmentOrder: [],
        xrayAppointmentOrder: [],
    }
];

export const mockDiagICD10 = [
    {
        id: 1,
        code: "A00.0",
        name: "Cholera due to Vibrio cholerae"
    },
    {
        id: 2,
        code: "B20.1",
        name: "Human immunodeficiency virus [HIV] disease resulting in oral candidiasis"
    },
    {
        id: 3,
        code: "C00.0",
        name: "Malignant neoplasm of lip, unspecified"
    },
    {
        id: 4,
        code: "D03.1",
        name: "Melanoma in situ of right upper limb, including shoulder"
    },
    {
        id: 5,
        code: "E11.9",
        name: "Type 2 diabetes mellitus without complications"
    },
    {
        id: 6,
        code: "F01.50",
        name: "Dementia in Alzheimer disease, unspecified"
    },
    {
        id: 7,
        code: "G89.11",
        name: "Chronic pain due to trauma"
    },
    {
        id: 8,
        code: "H35.29",
        name: "Other proliferative diabetic retinopathy, unspecified eye"
    },
    {
        id: 9,
        code: "I25.10",
        name: "Atherosclerotic heart disease of native coronary artery, unspecified"
    },
    {
        id: 10,
        code: "J44.9",
        name: "Chronic obstructive pulmonary disease, unspecified"
    },
    {
        id: 11,
        code: "K50.90",
        name: "Crohn's disease, unspecified, without complications"
    },
    {
        id: 12,
        code: "L03.90",
        name: "Cellulitis, unspecified, without mention of complication"
    }
];

export class AppointmentModel {
    private state: AppointmentDto[] = [];
    private icd10: ICD10[] = [];
    private client: Client<any>;
    private appoint: Appoint[] = [];
    private appointmentData: AppointmentData[] = [];
    private docCode = "";
    private date = "";
    private currentTab = "patient";
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("Appointment");
    }
    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }
    }

    public searchAppointment(an: string, date: string): Promise<AppointmentDto[]> {
        return new Promise((resolve, rejact) => {
            resolve(this.state);
        });
    }

    public create(data: Appoint): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("appointments");
            this.client.create({ request: data }).then(async (res: any) => {
                resolve(true);
            }).catch((err: any) => {
                resolve(false);
                reject(err);
            });
        });
    }


    public cancelAppointment(hn: string, keyTime: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const data = { hn: hn.trim(), keyTime: keyTime };
            this.client = new Client("appointments/cancel");
            this.client
                .update({ id: '', request: data })
                .then(async (res: any) => {
                    resolve(true);
                }).catch((err: any) => {
                    reject(err);
                });
        });
    }

    public getAppointByHn(hn: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("appointments");
            this.client.getQuery({ query: _params }).then((res: any[]) => {
                if (res && res.length > 0) {
                    const appPoints = [] as AppointmentData[];
                    res.forEach((item: any) => {
                        appPoints.push({ appoint: item, deptDesc: item.deptDesc, DocName: item.DocName, PatName: item.patient_name, id: item.id });
                    });
                    this.appointmentData = appPoints;
                    resolve(true);
                } else {
                    this.appointmentData = [];
                    this.appoint = [];
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getAppointByHnAndDate(hn: string, appointmentDate: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let _params = {};
            if (this.docCode) {
                _params = { hn: hn.trim(), date: this.date, docCode: this.docCode };
            } else {
                _params = { hn: hn.trim(), date: this.date };
            }
            this.client = new Client("appointments-date");
            this.client.getQuery({ query: _params }).then((res: any[]) => {
                if (res && res.length > 0) {
                    const appPoints = [] as AppointmentData[];
                    res.forEach((item: any) => {
                        appPoints.push({ appoint: item, deptDesc: item.deptDesc, DocName: item.DocName, PatName: item.patient_name, id: item.id });
                    });
                    this.appointmentData = appPoints;
                    resolve(true);
                } else {
                    this.appointmentData = [];
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getAppointmentsByWardAndDate(wardId: string, date: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const params = { wardId: wardId.trim(), date: this.date };
            this.client = new Client("appointments-warddate");
            this.client.getQuery({ query: params }).then((res: any[]) => {
                if (res && res.length > 0) {
                    const appPoints = [] as AppointmentData[];
                    res.forEach((item: any) => {
                        appPoints.push({ appoint: item, deptDesc: item.deptDesc, DocName: item.DocName, PatName: item.patient_name, id: item.id });
                    });
                    this.appointmentData = appPoints;
                    resolve(true);
                } else {
                    this.appointmentData = [];
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getDOCCLimitAppointment(docCode: string, startTime: string, endTime: string, day: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = { docCode, startTime, endTime, day };
            this.client = new Client("doctor-limit-appoint");
            this.client.getQuery({ query: params }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve({});
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getAppointDurations(docCode: string, hn: string, regNo: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const params = { docCode: docCode, hn: hn.trim(), regNo: regNo };
            this.client = new Client("doctor-appoint-duration");
            this.client.getQuery({ query: params }).then((res: any) => {
                if (res) {
                    resolve(res.appointDuration);
                } else {
                    resolve("1");
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public setCurrentDoccode(docCode: string) {
        this.currentTab = "doctor";
        this.docCode = docCode;
    }

    public setCurrentDate(date: string) {
        this.date = date;
    }

    public setCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    public getICD10() {
        this.icd10 = mockDiagICD10 as ICD10[];
        return this.icd10;
    }

    public get appointments() {
        return this.state;
    }

    public get appoints() {
        return this.appoint;
    }
    public get appointmentDatas() {
        return this.appointmentData;
    }

    public get DocCode() {
        return this.docCode;
    }

    public get CurrentTab() {
        return this.currentTab;
    }

    public get CurrentDate() {
        return this.date;
    }

}
