import { AdmissionRecordHistoryModel } from "./domain/admissionRecordHistory/AdmissionRecordHistory";
import { AdmissionRecordPhysicalModel } from "./domain/admissionRecordPhysical/AdmissionRecordPhysical";
import { AuthModel } from "./domain/auths/Auth";
import { DrugModel } from "./domain/drugs/Drug";
import { MedicationReconciliationModel } from "./domain/medicationReconciliation/MedicationReconciliation";
import { PatientModel } from "./domain/patients/Patient";
import { SelectWardModel } from "./domain/selectWards/SelectWard";
import { UserModel } from "./domain/users/User";
import { DrugLastVisitModel } from "./domain/drugLastVisit/drugLastVisit";
import { DischargeModel } from "./domain/discharges/Discharge";
import { ProgressNoteModel } from "./domain/progressNote/ProgressNote";
import { LabModel } from "./domain/labs/lab";
import { XrayModel } from "./domain/xrays/Xray";
import { OtherModel } from "./domain/otherOrders/Other";
import { AppointmentModel } from "./domain/appointments/Appointment";
import { LearnHowModel } from "./domain/learnHows/LearnHow";
import { LearnUnitModel } from "./domain/learnUnits/LearnUnit";
import { LearnTimeModel } from "./domain/learnTimes/LearnTime";
import { MedicineModel } from "./domain/medicines/Medicine";
import { DepartmentModel } from "./domain/departments/Department";
import { DoctorOrderModel } from "./domain/doctorOrders/DoctorOrder";
import { DoctorModel } from "./domain/doctors/doctor";
import { RequestConsultOpdModel } from "./domain/requestConsultOpds/RequestConsultOpd";
import { TransferModel } from "./domain/transfers/Transfer";
import { VitalSignModel } from "./domain/vitalSigns/VitalSign";
import { DiagModel } from "./domain/diags/Diag";
import { RequestSurgeryModel } from "./domain/requestSurgeries/RequestSurgery";
import { RecordExpensesModel } from "./domain/recordExpenses/RecordExpenses";

export default class Store {
        static type = {
                AUTH_MODEL: 'auth',
                USER_MODEL: 'user',
                PATIENT_MODEL: "patient",
                SELECT_WARD_MODEL: 'selectWard',
                ADMISSION_RECORD_HISTORY_MODEL: "admissionRecordHistory",
                ADMISSION_RECORD_PHYSICAL_MODEL: "admissionRecordPhysical",
                MEDICATION_RECONCILIATION_MODEL: "medicationReconciliation",
                DRUG_MODEL: "drug",
                DRUG_LAST_VISIT_MODEL: "drugLastVisit",
                DOCTOR_ORDER_MODEL: "doctorOrder",
                DISCHARGE_MODEL: "discharge",
                PROGRESS_NOTE_MODEL: "progressNote",
                LAB_MODEL: "lab",
                XRAY_MODEL: "xray",
                OTHER_MODEL: "other",
                APPOINTMENT_MODEL: "appointment",
                LEARN_HOW_MODEL: "learnHow",
                LEARN_UNIT_MODEL: "learnUnit",
                LEARN_TIME_MODEL: "learnTime",
                MEDICINE_MODEL: "medicine",
                DEPARTMENT_MODEL: "department",
                DOCTOR_MODEL: "doctor",
                REQUEST_CONSULT_OPD_MODEL: "requestConsultOpd",
                TRANSFER_MODEL: "transfer",
                VITAL_SIGN_MODEL: "vitalSign",
                DIAG_MODEL: "diag",
                REQUEST_SURGERY_MODEL: "requestSugery",
                RECORD_EXPENSES_MODEL: "recordExpenses",
        };

        private authModel = new AuthModel();
        private userModel = new UserModel();
        private patientModel = new PatientModel();
        private selectWardModel = new SelectWardModel();
        private admissionRecordHistory = new AdmissionRecordHistoryModel();
        private admissionRecordPhysical = new AdmissionRecordPhysicalModel();
        private medicationReconciliation = new MedicationReconciliationModel();
        private drugModel = new DrugModel();
        private drugLastVisitModel = new DrugLastVisitModel();
        private discharge = new DischargeModel();
        private progressNote = new ProgressNoteModel();
        private lab = new LabModel();
        private xray = new XrayModel();
        private other = new OtherModel();
        private appointment = new AppointmentModel();
        private learnHow = new LearnHowModel();
        private learnUnit = new LearnUnitModel();
        private learnTime = new LearnTimeModel();
        private medicineModel = new MedicineModel();
        private departmentModel = new DepartmentModel();
        private doctorOrderModel = new DoctorOrderModel();
        private doctorModel = new DoctorModel();
        private requestConsultOpd = new RequestConsultOpdModel();
        private tranferModel = new TransferModel();
        private vitalSign = new VitalSignModel();
        private diagModel = new DiagModel();
        private requestSurgery = new RequestSurgeryModel();
        private recordExpenses = new RecordExpensesModel();

        constructor() {
                this.authModel = new AuthModel();
                this.userModel = new UserModel();
                this.patientModel = new PatientModel();
                this.selectWardModel = new SelectWardModel();
                this.admissionRecordHistory = new AdmissionRecordHistoryModel();
                this.admissionRecordPhysical = new AdmissionRecordPhysicalModel();
                this.medicationReconciliation = new MedicationReconciliationModel();
                this.drugModel = new DrugModel();
                this.drugLastVisitModel = new DrugLastVisitModel();
                this.discharge = new DischargeModel();
                this.progressNote = new ProgressNoteModel();
                this.lab = new LabModel();
                this.xray = new XrayModel();
                this.other = new OtherModel();
                this.appointment = new AppointmentModel();
                this.learnHow = new LearnHowModel();
                this.learnUnit = new LearnUnitModel();
                this.learnTime = new LearnTimeModel();
                this.medicineModel = new MedicineModel();
                this.departmentModel = new DepartmentModel();
                this.doctorOrderModel = new DoctorOrderModel();
                this.doctorModel = new DoctorModel();
                this.requestConsultOpd = new RequestConsultOpdModel();
                this.tranferModel = new TransferModel();
                this.vitalSign = new VitalSignModel();
                this.diagModel = new DiagModel();
                this.requestSurgery = new RequestSurgeryModel();
                this.recordExpenses = new RecordExpensesModel();
        }

        public getStores = () => ({
                [Store.type.AUTH_MODEL]: this.authModel,
                [Store.type.USER_MODEL]: this.userModel,
                [Store.type.PATIENT_MODEL]: this.patientModel,
                [Store.type.SELECT_WARD_MODEL]: this.selectWardModel,
                [Store.type.ADMISSION_RECORD_HISTORY_MODEL]: this.admissionRecordHistory,
                [Store.type.ADMISSION_RECORD_PHYSICAL_MODEL]: this.admissionRecordPhysical,
                [Store.type.MEDICATION_RECONCILIATION_MODEL]: this.medicationReconciliation,
                [Store.type.DRUG_MODEL]: this.drugModel,
                [Store.type.DRUG_LAST_VISIT_MODEL]: this.drugLastVisitModel,
                [Store.type.DISCHARGE_MODEL]: this.discharge,
                [Store.type.PROGRESS_NOTE_MODEL]: this.progressNote,
                [Store.type.LAB_MODEL]: this.lab,
                [Store.type.XRAY_MODEL]: this.xray,
                [Store.type.OTHER_MODEL]: this.other,
                [Store.type.APPOINTMENT_MODEL]: this.appointment,
                [Store.type.LEARN_HOW_MODEL]: this.learnHow,
                [Store.type.LEARN_UNIT_MODEL]: this.learnUnit,
                [Store.type.LEARN_TIME_MODEL]: this.learnTime,
                [Store.type.MEDICINE_MODEL]: this.medicineModel,
                [Store.type.DEPARTMENT_MODEL]: this.departmentModel,
                [Store.type.DOCTOR_ORDER_MODEL]: this.doctorOrderModel,
                [Store.type.DOCTOR_MODEL]: this.doctorModel,
                [Store.type.REQUEST_CONSULT_OPD_MODEL]: this.requestConsultOpd,
                [Store.type.TRANSFER_MODEL]: this.tranferModel,
                [Store.type.VITAL_SIGN_MODEL]: this.vitalSign,
                [Store.type.DIAG_MODEL]: this.diagModel,
                [Store.type.REQUEST_SURGERY_MODEL]: this.requestSurgery,
                [Store.type.RECORD_EXPENSES_MODEL]: this.recordExpenses,
        });
}

export { UserModel, SelectWardModel };