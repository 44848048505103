import { makeAutoObservable } from "mobx";
import { PatientDto } from "../patients/Patient";
import Client from "../../../api/client";
export interface Diag {
    id: string;
}

export interface DisplayVisitDiag {
    deptCode: string;
    deptDesc: string;
    rxNo: string;
    docCode: string;
    docName: string;
    qDateIn: string;
    qTimeIn: string;
    qDateOut: string;
    qTimeOut: string;
    hn: string;
}

export interface DisplayPatientDiagData {
    DocCode: string;
    docName: string;
    DiagType: string;
    deptCode: string;
    dxtype: string;
    ICDCode: string;
    ICDExtnCode: string;
    diagVersion: string;
    VisitStatus: string;
    pt_status: string;
    DiagNote: string;
    DiagTime: string;
    DiagdateTime: string;
    userCode: string;
    Hn: string;
    rxNo: string;
    lastDiagdate: string;
    lastDiagTime: string;
    lastuserCode: string;
    flag?: string;
}

export interface RequestPATDIAG {
    Hn: string;
    VisitDate: string;
    DiagNo: number;
    DocCode: string;
    ICDCode: string;
    DiagType: string;
    DiagDate: string;
    DiagNote: string;
    userCode: string;
    pt_status: string;
    dxtype: string;
    doctorID: string;
    oldPat: string;
    deptCode: string;
    VisitStatus: string;
    diagVersion: string;
    rxNo: string;
    DiagTime: string;
    DiagdateTime: string;
    lastDiagdate: string;
    lastDiagTime: string;
    DiagKw: string;
    ICDExtnCode: string;
    lastuserCode: string;
    ICDCodeDiagFirst: string;
    sysID: string;
    regNo: string;
}
export interface DIAGTYPE {
    DIAGTYPECODE: string;
    DIAGTYPENAME: string;
    DIAGTYPECALULATE: string;
}

export interface IcdType {
    code: string;
    name: string;
}

export interface ICD {
    KWCODE: string;
    DES: string;
    CODE?: string;
}

export interface ICDDetail {
    CODE: string;
    DES: string;
    KWCODE: string;
}


export interface ConstuctorState {
    id: string;
    name: string;
}


export const DefaultICDtype = [
    {
        code: "I",
        name: "อาการ ICD"
    },
    {
        code: "E",
        name: "สาเหตุ"
    },
    {
        code: "P",
        name: "ผ่าตัด"
    }
];

export const VStatus = [
    {
        id: "O",
        name: "Opd diag Status"
    },
    {
        id: "I",
        name: "Ipd Diag  Status"
    },
    {
        id: "D",
        name: "Discharge Diag Status"
    },
    {
        id: "X",
        name: "Ipd Dead Diag Status"
    },
    {
        id: "Z",
        name: "Opd Dead Diag Status"
    }
];

export const DiagVersion = [
    {
        id: "1",
        name: "ผป.ใหม่ขใหม่"
    },
    {
        id: "2",
        name: "เก่า-โรคใหม่"
    },
    {
        id: "3",
        name: "เก่า-โรคเก่า"
    }
];

export const VisitVersion = [
    {
        id: "1",
        name: "ผู้ป่วยใหม่"
    },
    {
        id: "2",
        name: "ใหม่ในปี"
    },
    {
        id: "3",
        name: "ใหม่ในเดือน"
    },
    {
        id: "4",
        name: "เก่าในเดือน"
    }
];


export class DiagModel {
    private client: Client<any>;
    private state: Diag[] = [];
    private diagType: DIAGTYPE[] = [];
    private type: IcdType[] = [];
    private icd9: ICD[] = [];
    private icd10: ICD[] = [];
    private icdDetail: ICDDetail[] = [];
    private displayVisitDiag: DisplayVisitDiag[] = [];
    //#region  constructor
    private patientDiagData: DisplayPatientDiagData[] = [];
    private deafultICDtype: IcdType[] = DefaultICDtype;
    private vStatus: ConstuctorState[] = VStatus;
    private diagVersion: ConstuctorState[] = DiagVersion;
    private visitVersion: ConstuctorState[] = VisitVersion;
    //#endregion constructor
    private prepareDiagData: DisplayPatientDiagData[] = [];
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getType() {
        this.type = DefaultICDtype;
        return this.type;
    }

    public VisitDiag(hn: string, regNo: string) {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("visit-diag");
            this.client.getQuery({ query: _params }).then((result: any[]) => {
                if (result && result.length > 0) {
                    this.displayVisitDiag = result;
                    resolve(result);
                } else {
                    this.displayVisitDiag = [];
                    resolve([]);
                }
            }).catch((err: any) => {
                console.log(err);
                rejects(err);
            });
        });
    }

    public getPatientDiagData(hn: string, regNo: string) {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("patient-diag");
            this.client.getQuery({ query: _params }).then((result: any[]) => {
                if (result && result.length > 0) {
                    const datas = result.map((data: any) => {
                        return {
                            DocCode: data.DocCode,
                            docName: data ? data.docName + " " + data.docLName : "",
                            DiagType: data.DiagType ? DefaultICDtype.find(x => x.code === data.DiagType)?.name : "",
                            deptCode: data.deptCode,
                            dxtype: data.dxtype,
                            ICDCode: data.ICDCode,
                            ICDExtnCode: data.ICDExtnCode,
                            diagVersion: data.diagVersion ? VisitVersion.find(x => x.id === data.diagVersion)?.name : "",
                            VisitStatus: data.VisitStatus ? DiagVersion.find(x => x.id === data.VisitStatus)?.name : "",
                            pt_status: data.pt_status ? VStatus.find(x => x.id === data.pt_status)?.name : "",
                            DiagNote: data.DiagNote,
                            DiagTime: data.DiagTime,
                            DiagdateTime: data.DiagdateTime,
                            userCode: data.userCode,
                            Hn: data.Hn,
                            rxNo: data.rxNo,
                            lastDiagdate: data.lastDiagdate,
                            lastDiagTime: data.lastDiagTime,
                            lastuserCode: data.lastuserCode,
                            flag: ""
                        };
                    }) as DisplayPatientDiagData[];
                    this.patientDiagData = datas;
                    resolve(this.patientDiagData);
                } else {
                    this.patientDiagData = [];
                    resolve([]);
                }
            }).catch((err: any) => {
                console.log(err);
                rejects(err);
            });
        });
    }


    public getICD9CMByName(name: string) {
        return new Promise((resolve, rejects) => {
            const _params = { name: name };
            this.client = new Client("icd9cm/name");
            this.client.getQuery({ query: _params }).then((result: ICD[]) => {
                if (result && result.length > 0) {
                    this.icd9 = result;
                    this.getICD9DetailByCode(this.icd9[0].KWCODE);
                } else {
                    this.icd9 = [];
                    this.icdDetail = [];
                }
                resolve(this.icd9);
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }

    public getICD9DetailByCode(code: string) {
        return new Promise((resolve, rejects) => {
            const _params = { code: code };
            this.client = new Client("icd9cm/code");
            this.client.getQuery({ query: _params }).then((result: any[]) => {
                if (result) {
                    this.icdDetail = result.map((data: any) => {
                        return {
                            CODE: data.CODE,
                            DES: data.DES ? data.DES.DES : '',
                            KWCODE: data.KWCODE
                        };
                    });
                    resolve(this.icdDetail);
                } else {
                    this.icdDetail = [];
                    resolve([]);
                }
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }


    public getICD10ByName(name: string) {
        return new Promise((resolve, rejects) => {
            if (name) {
                const _params = { name: name };
                this.client = new Client("icd10/name");
                this.client.getQuery({ query: _params }).then((result: ICD[]) => {
                    if (result && result.length > 0) {
                        this.icd10 = result;
                        this.getICD10DetailByCode(this.icd10[0].KWCODE);
                        resolve(this.icd10);
                    } else {
                        this.icd10 = [];
                        this.icdDetail = [];
                        resolve(this.icd10);
                    }
                }).catch((error: any) => {
                    console.log(error);
                    rejects(error);
                });
            } else {
                this.icd10 = [];
            }

        });
    }

    public updateLastHisNoAndGetLastDiagNo(hn: string, regNo: string): Promise<any> {
        return new Promise((resolve, rejects) => {
            const data = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("update-lasthisno-get-lastdiagno");
            this.client
                .postUpdate({ request: data })
                .then(async (res: any[]) => {
                    if (res && res.length > 0) {
                        resolve(res[0]);
                    } else {
                        resolve({ LastDiagNo: 0 });
                    }
                    // resolve(res[0]);
                }).catch((err: any) => {
                    rejects(err);
                });
        });
    }

    public prepareAddDiag(data: DisplayPatientDiagData) {
        data.DiagType = DefaultICDtype.find(x => x.code === data.DiagType)?.name ?? "";
        data.diagVersion = VisitVersion.find(x => x.id === data.diagVersion)?.name ?? "";
        data.VisitStatus = DiagVersion.find(x => x.id === data.VisitStatus)?.name ?? "";
        data.pt_status = VStatus.find(x => x.id === data.pt_status)?.name ?? "";
        this.PatientDiagData.push(data);
    }

    public createDiag(patient: PatientDto, LastDiagNo: number, nextLastDiagNo: number, reqPATDIAG: RequestPATDIAG): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            reqPATDIAG.Hn = patient.hn ?? '';
            const data = reqPATDIAG;
            const datas = { LastDiagNo, nextLastDiagNo, data };
            this.client = new Client("create-diag");
            this.client.create({ request: datas }).then(async (res: any) => {
                resolve(true);
            }).catch((err: any) => {
                resolve(false);
                rejects(err);
            });
        });
    }

    public getICD10DetailByCode(code: string) {
        return new Promise((resolve, rejects) => {
            const _params = { code: code };
            this.client = new Client("icd10/code");
            this.client.getQuery({ query: _params }).then((result: any[]) => {
                if (result) {
                    this.icdDetail = result.map((data: any) => {
                        return {
                            CODE: data.CODE,
                            DES: data.DES ? data.DES : '',
                            KWCODE: data.KWCODE
                        };
                    });
                    resolve(this.icdDetail);
                } else {
                    this.icdDetail = [];
                    resolve(this.icdDetail);
                }
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }

    public getDiagType() {
        return new Promise((resolve, rejects) => {
            this.client = new Client("diag-type");
            this.client.getAll().then((result: any[]) => {
                if (result && result.length > 0) {
                    this.diagType = result;
                    resolve(this.diagType);
                } else {
                    this.diagType = [];
                    resolve([]);
                }
            });
        });
    }

    //#region constuctor state
    public get DeafultICDtype() {
        return this.deafultICDtype;
    }

    public get VStatus() {
        return this.vStatus;
    }

    public get DiagVersion() {
        return this.diagVersion;
    }

    public get VisitVersion() {
        return this.visitVersion;
    }
    //#endregion

    public get Icd09() {
        return this.icd9;
    }

    public get Icd10() {
        return this.icd10;
    }

    public get Type() {
        return this.type;
    }

    public get DisplayVisitDiag() {
        return this.displayVisitDiag;
    }

    public get PatientDiagData() {
        return this.patientDiagData;
    }

    public get diags() {
        return this.state;
    }

    public get DiagType() {
        return this.diagType;
    }

    public get IcdDetail() {
        return this.icdDetail;
    }
}
