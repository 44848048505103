/* eslint-disable no-async-promise-executor */
import { makeAutoObservable } from "mobx";
// import { v4 as uuidv4 } from 'uuid';
import Client from "@/app/api/client";
export interface ProgressNoteDto {
    Id?: string;
    an: string;
    Note: string;
    orderId?: string;
    CreatedBy?: string;
    CreatedDate?: string;
    CreatedTime?: string;
    UpdatedBy?: string;
    UpdatedDate?: string;
}

export interface ProgressNoteRequest {
    id: string;
    an: string;
    progressNote: string;
    dateNote: string;
}


export class ProgressNoteModel {
    private state: ProgressNoteDto[] = [];
    private prepareProgressNote: ProgressNoteDto | undefined;
    private createState: ProgressNoteDto | undefined;
    private client: Client<any>;
    constructor() {
        this.state = [];
        makeAutoObservable(this);
        this.client = new Client("");
    }


    public get(an: string, flag: string): Promise<ProgressNoteDto[]> {
        return new Promise(async (resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("progressnote-order");

            let result: ProgressNoteDto[] = [];
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res) {
                    result = res;
                    result.forEach((item: ProgressNoteDto) => {
                        if (!this.state.find((stateItem: ProgressNoteDto) => stateItem.Id === item.Id)) {
                            this.state.push(item);
                        }
                    });

                    this.state = result.sort((a: ProgressNoteDto, b: ProgressNoteDto) => {
                        if (a.CreatedDate && b.CreatedDate) {
                            return new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime();
                        }
                        return 0;
                    });
                    if (flag) {
                        resolve(this.state);
                    } else {
                        this.state = [];
                    }
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public create(data: ProgressNoteDto): Promise<ProgressNoteDto> {
        const user = localStorage.getItem("user");
        data.CreatedBy = user ? JSON.parse(user).username : "";
        return new Promise(async (resolve, reject) => {
            this.client = new Client("progressnote-order");
            try {
                // let result: ProgressNoteDto[] = [];
                this.client.create({ request: data }).then((res: any) => {
                    if (res.message) {
                        // result = res;
                        resolve(res.message);
                    }
                    // this.state = [...this.state, result[0]];

                }).catch((err: any) => {
                    reject(err);
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }

    public update(data: ProgressNoteDto): Promise<ProgressNoteDto> {
        return new Promise(async (resolve, reject) => {
            this.client = new Client("progressnote-order");
            try {
                delete data["UpdatedBy"];
                this.client.update({ id: data.Id ?? "", request: data }).then((res: any) => {
                    if (res) {
                        resolve(res);
                    }
                }).catch((err: any) => {
                    reject(err);
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }

    public clearProgressNotes() {
        this.state = [];
    }

    public prepareProgessNote(data: ProgressNoteDto) {
        if (data) {
            this.prepareProgressNote = data;
        }
    }

    public clearPrepareProgressNote() {
        this.prepareProgressNote = undefined;
    }

    public get progressNotes() {
        return this.state;
    }

    public get prepareProgressNoteState() {
        return this.prepareProgressNote;
    }

}
