/* eslint-disable no-async-promise-executor */
import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface MedicineDto {
    abbr: string,
    dform: string,
    strgth: string,
    site: string,
    package: string,
    code: string,
    strgthU: string,
    subCnt: number,
    genShname: string,
    genName: string,
    unitCode: string,
    unit: string,
    packsize: string,
    discF: string,
    chargeC: string,
    opdPrc: string,
    ipdPrc: string,
    buyPrc: number,
    prodType: string,
    equiUnit: number,
    medClassCode: string,
    foreignOpdPrc: string,
    foreignIpdPrc: string,
    staffOpdPrc: string,
    staffIpdPrc: string,
    color: number,
    midPrc: number,
    defDose: string,
    terminate: string,
    hideSelect: string,
    termReason: string,
    specialMed: string,
    manufac: string,
    canFree: string,
    refund: string,
    sysId: string,
    ipdChargeC: string,
    homeChargeC: string,
    medKw: string,
    name: string,
    abbrName: string,
    packForm: string,
    maxuses: string,
    maxdose: string,
    curBal: number,
    vatFlag: string,
    pairCode: string,
    opdSaleU: number,
    sname: string,
    hasInfo: string,
    lastUprice: string,
    sKw: string,
    sequence: string,
    sequenceName: string,
    howUseCode: string,
    howUse: string,
    qty: number,
    EachTime?: number,
    Note?: string,

    // For Calculate
    how_time_docperday?: number,
    how_hour_docperiod?: string,

    unit_time_docperday?: number,
    unit_hour_docperiod?: string,

    time_time_docperday?: number,
    time_hour_docperiod?: string,
    drugUse: DrugUse[];

    amount?: number;
    lamedQty?: number;
    lamedDays?: number;
    // For Past Visit
    qtyPerFeed?: number;
    periodPerDay?: number;
    lamedCpy?: number;
}
export interface DrugUse {
    codeLamed: string,
    codeHow: string,
    unitLamedC: string,
    codeUnit: string,
    period: string,
    codeTime: string,
    codeSpec: string,
    timePerday: number,
    unitChoice: string,
    unitPack: string,
    howName: string,
    howEng: string,
    unitName: string,
    unitEng: string,
    timeName: string,
    timeEng: string,
    specName: string,
    specEng: string,
    timePerDay1: number,
    hrPerday: string;
}
export interface Drug {
    id: string;
    code: string;
    name: string;
    unit: string;
    qty: number;
    potion: number;
    howTo: string;
    time: string;
    type: string;
}

export interface DrugOrderDto {
    id: string;
    items: InvReqD;
}
export interface InvReqD {
    /**
     * รหัสแพทย์
     * @required
     */
    orderDoc: string;
    /**
     * ลำดับรายการ
     * generate by system
     */
    runNo: number;
    /**
     * ประเภทรายการ
     * M = ยา
     * @default M
     */
    invType: string;
    /**
     * รหัสยา
     * @required
     */
    invCode: string;
    /**
     * คลังยา
     * @default 1
     */
    siteType: string;
    /**
     * จำนวน
     * @required
     */
    qtyReq: number;
    /**
     * ราคารวม
     * @required
     */
    amount: number;
    /**
     * รหัสหน่วยยา
     * @required
     */
    unit: string;
    /**
     * วันละ
     * ระยะเวลาในการให้ยา
     * @default 0
     * @example 1 = 1 ชั่วโมง
     */
    hourPeriod: number;
    /**
     * เลขที่ใบสั่งยา
     * @example 660000007
     * generate by system
     */
    reqNo: string;
    /**
     * สถานะการแก้ไข
     * @default ""
     */
    revFlag: string;
    /**
     * ราคาต้นทุน
     * @required
     */
    cost: number;
    /**
     * วิธีใช้ยา
     * @required
     */
    lamedHow: string;
    /**
     * หน่วยการใช้ยา
     * @required
     */
    lamedUnit: string;
    /**
     * เวลาในการให้ยา
     * @required
     */
    lamedTime: string;
    /**
     * คำอธิบายเพิ่มเติม
     * @default "@"
     */
    lamedSpecial: string;
    /**
     * ระยะเวลาในการให้ยา
     * @default 0
     */
    lamedPeriod: number;
    /**
     * จำนวนยาที่ต้องการย้าย
     * @default 0
     */
    qtyTransfer: number;
    /**
     * จำนวนวันที่ให้ยา
     * @default 0
     * @example 1 = 1 วัน
     */
    lamedDays: number;
    /**
     * คำอธิบายเพิ่มเติม
     * @default ""
     */
    lamedText: string;
    /**
     * จำนวนยารวม
     * @required
     */
    unitQty: number;
    /**
     * @default 0
     */
    lamedQty: string;
    /**
     * ครั้งละ
     * @default 1
     */
    unitPerFeed: string;
    /**
     * @default N
     */
    lamedEng: string;
    /**
     * วันที่ request ยา ในรูปแบบ ปปปปดดวว
     * @required
     * @example 25660131
     */
    orgReqDate: string;
    /**
     * วิธีใช้ยาในช่องพิเศษ
     * @default ""
     */
    lamedTimeText: string;
    /**
     * @default N
     */
    refund: string;
    /**
     * ราคารวม เป็นราคาเดียวกับ amount
     * @required
     */
    u_price: string;
    /**
     * วันที่เริ่มให้ยา
     * @required
     * @example 2023-01-31 00:00:00
     * @format date-time
     */
    startIssDate: string;
    /**
     * @default ""
     */
    dispSelect: string;
    /**
     * @default 1
     */
    lamedCpy: number;
    /**
     * @default ""
     */
    dispCause: string;
    /**
     * @default ""
     */
    otherCause: string;
    /**
     * @default ""
     */
    SelectCauseGUID: string;
    /**
     * @default ""
     */
    invGovCode24: string;
    /**
     * @default ""
     */
    invTMTCode10: string;
    /**
     * @default ""
     */
    dueCause: string;
    /**
     * username ผู้ใช้งานที่สั่ง off
     * @default ""
     */
    usrOffmed: string;
    /**
     * วันที่สั่ง off
     * @default ""
     */
    endDate: Date;
    /**
        * วันที่จ่ายครั้งถัดไป
        * @example 2023-01-31 00:00:00
        * @format date-time
        */
    nextIssDate: string;

    /**
        * วันที่จ่ายล่าสุด
        * @example 2023-01-31 00:00:00
        * @format date-time
        */
    lastIssDate: string;
    /**
           *  จ่ายเฉพาะวัน
           * เก็บข้อมูลเป็น 'MON,TUE,WED,THU,FRI'
           */
    ipdlamedDayText: string;

    /**
     * เวลาที่ off ยา
     * ไว้เช็ค off ยา ที่หน้า Order ในตอน get
     */
    OffMedTime: string;

    //#region display only , delete it before send to backend
    drugName?: string;
    lamedHowName?: string;
    lamedUnitName?: string;
    lamedTimeName?: string;
    lamedSpecialName?: string;
    unitName?: string;
    //#endregion
}
export interface InvReqH {

    /**
     * วันที่ลงทะเบียน VN
     * @required
     */
    registDate: string;
    lastRunNo: number;
    /**
     * คลังยา
     * @default M
     */
    srcType: string;
    /**
     * รหัส ward
     * @required
     */
    reqFrom: string;
    /**
     * วันที่สั่งยา ในรูปแบบ YYYYMMDD
     * @required
     */
    reqDate: string;
    /**
     * เวลาที่สั่งยา ในรูปแบบ HH:mm
     * @required
     */
    reqTime: string;
    /**
     * ประเภทการสั่งยา
     * N = ปกติ, S = ต่อเนื่อง
     * @default N
     */
    reqType: string;
    /**
     * หมายเลขผู้ป่วยประจำตัวในโรงพยาบาล
     */
    hn: string;
    /**
     * หมายเลข request ยา
     * @required
     * @example 660000007
     */
    reqNo: string;
    /**
     * @default N
     */
    iconfirm: string;
    /**
     * @default 0
     */
    endCount: number;
    /**
     * @default N
     */
    instructType: string;
    /**
     * @default "1"
     */
    toSite: string;
    /**
     * username ผู้สั่งยา
     */
    reqUser: string;
    /**
     * รหัสแพทย์ผู้สั่งยา
     */
    orderDoc: string;
    /**
     * @default Y
     */
    openReq: string;
    /**
     * @default N
     */
    remoteSent: string;
    /**
     * @default ""
     */
    rxno: string;
    /**
     * @default 1
     */
    reqCycle: number;
    /**
     * @default A
     */
    authFlag: string;
    /**
     * regNo ของผู้ป่วยใน table OPD_h
     */
    registNo: string;
    /**
     * @default ""
     */
    FileVersion: string;
    /**
     * @default ""
     */
    SysID: string;
    items?: InvReqD[];
}

export interface DrugOrder {
    data: any;
    an: string;
    reqNo: string;
    order: InvReqH;
    orderId?: string;

}
export class DrugModel {
    private client: Client<any>;
    private state: Drug[] = [];
    private drugOrders: DrugOrder[] = [];
    private prepareList: InvReqH[] = [];
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    // TODO: add some function for work

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }

        // TODO: uncomment if use api, remove if not
        // this.client.getAll().then(data => {
        //     this.state = data;
        // }).catch(err => {
        //     throw err;
        // });
    }
    public get drugs() {
        return this.state;
    }

    //TODO: please recheck this function, when done remove this comment
    public get(an: string, flag: string): Promise<DrugOrder[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("drugs-order");
            this.client.getQuery({ query: _params }).then((datas: any[]) => {
                if (datas.length > 0) {
                    // eslint-disable-next-line prefer-const
                    let _datas = [] as DrugOrder[];
                    if (this.prepareList.length > 0) {
                        this.prepareList.map((item: InvReqH) => {
                            _datas = [..._datas, { an: an, order: item, data: undefined, reqNo: item.reqNo }];
                            return true;
                        });
                    }
                    if (flag) {
                        datas.forEach((item: DrugOrder) => {
                            if (!this.drugOrders.find(x => x.reqNo === item.reqNo)) {
                                _datas = [..._datas, item];
                            }
                        });
                        _datas = _datas.filter((item: DrugOrder) => item.order);
                        _datas = _datas.sort((a, b) => a.order.reqNo.localeCompare(b.order.reqNo));
                    }

                    resolve(_datas);
                } else {
                    let _datas = [] as DrugOrder[];
                    if (this.prepareList.length > 0) {
                        this.prepareList.map((item: InvReqH) => {
                            _datas = [..._datas, { an: an, order: item, data: undefined, reqNo: item.reqNo }];
                            return true;
                        });
                        resolve(_datas);
                    } else {
                        resolve(_datas);
                    }
                    // if (!this.prepareList) {
                    //     this.drugOrders = [];
                    //     this.clearPreparedDrugOrders();
                    //     resolve([]);
                    // } else {
                    //     let _datas = [] as DrugOrder[];
                    //     this.prepareList.map((item: InvReqH) => {
                    //         _datas = [..._datas, { an: an, order: item, data: undefined, reqNo: item.reqNo }];
                    //         return true;
                    //     });
                    //     _datas = _datas.filter((item: DrugOrder) => item.order);
                    //     _datas = _datas.sort((a, b) => a.order.reqNo.localeCompare(b.order.reqNo));
                    //     resolve(_datas);
                    // }
                }
            }).catch((error: any) => {
                console.log(error);
                reject(error);
            });
        });
    }
    public getLastReqNo(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.client = new Client("drugs-last-req-no");
            this.client.getAll().then((result: any) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(0);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }
    public updateLastReqNo(prevNo: number, lastDate: string, lastNo: number) {
        return new Promise((resolve, reject) => {
            const data = { prevNo, lastDate, lastNo };
            this.client = new Client("drugs-last-req-no");
            this.client.update({ id: '', request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                reject(error);
            });

        });
    }
    public create(order: DrugOrder, waitMed: string, orderId: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const user = localStorage.getItem("user");
            const items = order.order.items;
            delete order.order.items;
            const datas = {
                ward_id: order.order.reqFrom,
                waitMed: waitMed,
                orderData: order.order,
                detailData: items,
                mapData: {
                    an: order.an,
                    InvReqHId: order.order.reqNo,
                    SrcType: 1,
                    orderId: orderId,
                    CreateBy: user ? JSON.parse(user).username : ""
                }
            };
            this.client = new Client("drugs-order");
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    // if (result.reqNo === order.order.reqNo) {
                    //     this.drugOrders = [...this.drugOrders, order];
                    // }
                    this.drugOrders = [...this.drugOrders, order];
                    this.clearPreparedDrugOrders();
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                resolve(false);
                reject(error);
            });
        });
    }
    public offDrug(reqNo: string, runNo: number, invCode: string, endDate: Date, offMedTime: string): Promise<boolean> {
        const user = localStorage.getItem("user");
        const userName = user ? JSON.parse(user).username : "";
        return new Promise((resolve, reject) => {
            this.client = new Client("drugs-off-drug");
            const data = {
                reqNo: reqNo,
                runNo: runNo,
                invCode: invCode,
                userOffmed: userName,
                endDate: endDate,
                offMedTime: offMedTime
            };
            this.client.create({ request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public clearPreparedDrugOrders() {
        this.prepareList = [];
        this.drugOrders = [];
    }


    public prepareDrugOrders(data: InvReqH) {
        if (data) {
            this.prepareList.push(data);
        }
    }
    //#region  Getters
    public get DrugOrders() {
        return this.drugOrders;
    }

    public get preparedDrugOrdersItems() {
        return this.prepareList;
    }

    //#endregion
}
