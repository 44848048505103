import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface LearnHowDto {
    lamedCode?: string;
    lamedEng?: string;
    lamedName?: string;
    translate?: string;
    alias?: string;
    dose?: number;
    timePerday?: number;
    hourperiod?: number;
    hrPerday?: string;
    calcQty?: string;
    hideselect?: string;
    docHideselect?: string;
    timeDocperday?: number;
    lamedNameNew?: string;
    hourDocperiod?: string;
    docCalcQty?: string;
    // new props
    howcode?: string;
    howName?: string;
    unitCode?: string;
    unitName?: string;
    timeCode?: string;
    timeName?: string;
    time_docperday?: number;
    hour_docperiod?: string;
}

export class LearnHowModel {
    private state: LearnHowDto[] = [];
    private allHow: LearnHowDto[] = [];
    private howMaster: LearnHowDto[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("Medicine");
    }

    public getDose() {
        return new Promise((resolve, reject) => {
            this.client = new Client("doses");
            this.client.getAll().then((res: any[]) => {
                let hows: LearnHowDto[] = [];
                hows = res.map((value: any) => {
                    return {
                        lamedCode: value.code_lamed,
                        lamedEng: value.code_how,
                        lamedName: value.how_name,
                        translate: value.code_unit,
                        alias: value.unit_name,
                        dose: value.code_time,
                        timePerday: value.time_name,
                        hourperiod: value.unit_lamed_c,
                        howcode: value.code_how,
                        howName: value.how_name,
                        unitCode: value.code_unit,
                        unitName: value.unit_name,
                        timeCode: value.code_time,
                        timeName: value.time_name,
                    };
                });
                this.state = hows;
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public async getHowByCode(code: string): Promise<LearnHowDto> {
        return new Promise((resolve, reject) => {
            const _params = { code: code };
            this.client = new Client("hows/code");
            this.client.getQuery({ query: _params }).then((res: any) => {
                let hows: LearnHowDto[] = [];
                hows = res.map((value: any) => {
                    return {
                        lamedCode: value.lamed_code,
                        lamedName: value.lamed_name,
                        time_docperday: value.time_docperday,
                        hour_docperiod: value.hour_docperiod
                    };
                });
                this.allHow = hows;
                resolve(hows[0]);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getHowUseWithLimit(limitQty: number) {
        return new Promise((resolve, reject) => {
            const _params = { limit: limitQty.toString() };
            this.client = new Client("hows/limit");
            this.client.getQuery({ query: _params }).then((res: any) => {
                let hows: LearnHowDto[] = [];
                hows = res.map((value: any) => {
                    return {
                        lamedCode: value.lamed_code,
                        lamedName: value.lamed_name,
                        time_docperday: value.time_docperday,
                        hour_docperiod: value.hour_docperiod
                    };
                });
                this.allHow = hows;
                resolve(hows);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public getHowByName(name: string) {
        return new Promise((resolve, reject) => {
            const _params = { name: name };
            this.client = new Client("hows/name");
            this.client.getQuery({ query: _params }).then((res: any) => {
                let hows: LearnHowDto[] = [];
                hows = res.map((value: any) => {
                    return {
                        lamedCode: value.lamed_code,
                        lamedName: value.lamed_name,
                        time_docperday: value.time_docperday,
                        hour_docperiod: value.hour_docperiod
                    };
                });
                this.allHow = hows;
                resolve(hows[0]);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public getAll(): Promise<LearnHowDto[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("lameds");
            this.client.getAll().then((res: any[]) => {
                let hows: LearnHowDto[] = [];
                hows = res.map((value: any) => {
                    return {
                        lamedCode: value.lamed_code,
                        lamedName: value.lamed_name,
                        time_docperday: value.time_docperday,
                        hour_docperiod: value.hour_docperiod
                    };
                });
                this.allHow = hows;
                // this.howMaster = hows;
                resolve(hows);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public get hows() {
        return this.state;
    }


    public get allHows() {
        return this.allHow;
    }

    public get howMasters() {
        return this.howMaster;
    }

}
