import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface LearnUnitDto {
    lamedCode?: string;
    lamedEng?: string;
    lamedName?: string;
    translate?: string;
    alias?: string;
    dose?: number;
    timePerday?: number;
    hourperiod?: number;
    hrPerday?: string;
    calcQty?: string;
    hideselect?: string;
    docHideselect?: string;
    timeDocperday?: number;
    lamedNameNew?: string;
    hourDocperiod?: string;
    docCalcQty?: string;
}

export const mockUnitUse = [
    //ของยา 1
    {
        id: "1", name: "CC", drugId: "1"
    },
    {
        id: "2", name: "KL", drugId: "1"
    },
    //ของยา 2
    {
        id: "3", name: "ML", drugId: "2"
    },
    //ของยา 3
    {
        id: "4", name: "KL", drugId: "3"
    },
    {
        id: "5", name: "Box", drugId: "3"
    },
];

export class LearnUnitModel {
    private state: LearnUnitDto[] = [];
    private client: Client<LearnUnitDto>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("LearnUnit");
    }

    //TODO: dont use param
    public getUnitUse(drugId: string): Promise<LearnUnitDto[]> {
        return new Promise((resolve, reject) => {
            // this.client.getAll("lameds").then(data => {
            //     this.state = data;
            //     resolve(data);
            // }).catch(err => {
            //     reject(err);
            // });
        });
    }

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }

        // TODO: uncomment if use api, remove if not
        // this.client.getAll().then(data => {
        //     this.state = data;
        // }).catch(err => {
        //     throw err;
        // });
    }
    public get learnUnits() {
        return this.state;
    }
}
