
import { configure, makeAutoObservable } from "mobx";
import Client from "../../../api/client";
import { trimObject } from "@/helper/utils";
export interface Ward {
    ward_id: string;
    ward_name: string;
    waitLab: number;
    waitMed: number;
    waitRad: number;
}
export interface SelectWard {
    id: string,
    name?: string,
    label?: string;
}

export interface RoomType {
    room_type?: string;
    sex_check?: string;
    age_check?: string;
    room_des?: string;
    exroom_ratebytype?: string;
    room_ratebytype?: string;
}

export interface Room {
    sex_check?: string;
    ward_id?: string;
    room_type?: string;
    room_rate?: string;
    room_cur_occ?: string;
    room_tot_bed?: string;
    room_status?: string;
    max_age?: string;
    cur_rev?: string;
    extra_bedrate?: string;
    room_no?: string;
    age_check?: string;
    show_flag?: string;
    room_nurse_chr?: string;
    room_food?: string;
    room_service_chr?: string;
    rm_chr_code?: string;
    nurse_chr_code?: string;
    service_chr_code?: string;
    food_chr_code?: string;
    room_chr_type?: string;
    exroom_rate?: string;
    room_desc?: string;
    exroom_desc?: string;
    room_des?: string;
    extra_bedratetype?: string;
    edit_price?: string;
    rm_chr_code_rs?: string;
    roomstatuscode?: string;
    room_nurse_chr_foreign?: string;
    room_food_foreign?: string;
    room_service_chr_foreign?: string;
    room_oth_chr?: string;
    oth_chr_code?: string;
    room_no_new?: string;
    srth_room_zone?: string;
}

export interface displayRoomInWard {
    room_name?: string;
    room_type?: string;
    room_rate?: string;
    room_addition_rate?: string;
    room_status?: string;
    bed_free?: string;
    all_bed?: string;
    room_type2?: string;
    room_rate2?: string;
    check_sex?: string;
    check_age?: string;
    food_rate?: string;
    ser_rate?: string;
    gser_rate?: string;
    room_chr_type?: string;
    room_service_chr?: string;
    exroom_desc?: string;
    exroom_rate?: string;
}


export class SelectWardModel {
    private state: SelectWard[] = [];
    private stateWardData: Ward[] = [];
    private roomType: RoomType[] = [];
    private roomInWard: displayRoomInWard[] = [];
    private client: Client<Ward>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("wards");
        configure({
            enforceActions: "never",
        });
    }

    public getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.getAll().then((res: any) => {
                this.stateWardData = res;
                this.setSelectWard(res);
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public getWardByCode(code: string): Ward | undefined {
        return this.stateWardData.find((value) => {
            return value.ward_id === code;
        });
    }



    public getWaitMedByCode(code: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const _params = { code: code };
            this.client = new Client("wait-med");
            this.client.getQuery({ query: _params }).then((res: any) => {
                resolve(res[0].waitMed);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getRoomCurOcc(ward_id: string, room_id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.client = new Client("rooms-cur-occ");
            const data = { ward_id, room_id };
            this.client.postGet({ request: data }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve("0");
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getCurrentUpdateRoomCurOcc(roomId: string, wardId: string, currentDate: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.client = new Client("current-update-rooms-cur-occ");
            const data = { ward_id: wardId, room_id: roomId, current_date: currentDate };
            this.client.postGet({ request: data }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve("0");
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public searchRoomInWard(wardId?: string, roomType?: string, checkBed?: boolean, checkSpecialRoom?: boolean, roomNo?: string) {
        return new Promise((resolve, reject) => {
            this.client = new Client("search-rooms-in-ward");
            const data = { ward_id: wardId, room_desc: roomType, check_special_room: checkSpecialRoom, room_no: roomNo };
            this.client.postGet({ request: data }).then((res: any) => {
                if (res) {
                    if (checkBed) {
                        res = res.filter((value: any) => {
                            return parseInt(value.room_tot_bed.trim()) - parseInt(value.room_cur_occ.trim()) > 0;
                        });
                    }
                    this.roomInWard = res.map((value: any) => {
                        return {
                            exroom_rate: value.exroom_rate,
                            room_service_chr: value.room_service_chr,
                            room_chr_type: value.room_chr_type,
                            food_rate: value.room_food,
                            room_name: value.room_no,
                            room_type: value.room_desc,
                            room_rate: value.room_rate,
                            room_addition_rate: value.room_addition_rate,
                            room_status: value.room_status,
                            bed_free: value.room_tot_bed - value.room_cur_occ,
                            all_bed: value.room_tot_bed,
                            room_type2: value.room_type,
                            room_rate2: value.room_rate,
                            check_age: value.age_check === "1" ? "ผู้ใหญ่" : value.age_check === "2" ? "ไม่จำกัด" : "เด็ก",
                            check_sex: value.sex_check === "1" ? "ผู้หญิง" : value.age_check === "2" ? "ไม่จำกัด" : "ผู้ชาย",
                        };
                    });
                    resolve(this.roomInWard);
                } else {
                    this.roomInWard = [];
                    reject("ไม่พบข้อมูล");
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }


    public getRoomByWard(ward_id: string, flag: string): Promise<Room[]> {
        return new Promise((resolve, reject) => {
            const _params = { ward_id: ward_id };
            this.client = new Client("rooms");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data && data.length > 0) {
                    if (flag === "free") {
                        data = data.filter((value: any) => {
                            return parseInt(value.room_tot_bed.trim()) - parseInt(value.room_cur_occ.trim()) > 0;
                        });
                    }
                    this.roomInWard = data.map((value: any) => {
                        return {
                            exroom_rate: value.exroom_rate ?? "",
                            room_service_chr: value.room_service_chr ?? "",
                            room_chr_type: value.room_chr_type ?? "",
                            food_rate: value.room_food ?? "",
                            room_name: value.room_no ?? "",
                            room_type: value.room_des ?? "",
                            room_rate: value.room_rate ?? "",
                            room_addition_rate: value.extra_bedrate ?? "",
                            room_status: value.RoomStatusDes ? value.RoomStatusDes : "",
                            bed_free: (parseInt(value.room_tot_bed.trim()) - parseInt(value.room_cur_occ.trim())).toString(),
                            all_bed: value.room_tot_bed ? value.room_tot_bed.trim() : "",
                            room_type2: value.exroom_desc ?? "",
                            room_rate2: value.exroom_rate ?? "",
                            check_age: value.age_check === "1" ? "ผู้ใหญ่" : value.age_check === "2" ? "ไม่จำกัด" : "เด็ก",
                            check_sex: value.sex_check === "1" ? "ผู้หญิง" : value.age_check === "2" ? "ไม่จำกัด" : "ผู้ชาย",
                        };
                    });
                    resolve(this.roomInWard);
                } else {
                    this.roomInWard = [];
                    reject("ไม่พบข้อมูล");
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }


    public getRoomType(): Promise<RoomType[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("room-types");
            this.client.getAll().then((data: any[]) => {
                if (data && data.length > 0) {
                    this.roomType = data;
                    resolve(this.roomType);
                } else {
                    this.roomType = [];
                    reject("ไม่พบข้อมูล");
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    public setSelectWard(data: Ward[]) {
        if (data) {
            data = data.filter(x => x.ward_id);
            this.stateWardData = trimObject(data);
            let selectWard: SelectWard[] = [];
            selectWard = data.map((value: { ward_id: string; ward_name: string; }) => {
                return {
                    id: value.ward_id, name: value.ward_id.toString().trim() + ' - ' +
                        value.ward_name, label: value.ward_id.toString().trim() + ' - ' + value.ward_name.toString().trim()
                };
            });
            this.state = selectWard;
        }
    }
    public get selectWards() {
        return this.state;
    }

    public get RoomType() {
        return this.roomType;
    }

    public get RoomInWard() {
        return this.roomInWard;
    }
}
