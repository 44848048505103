/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface AdmissionNotePhysicalExaminationDto {
    Id?: string;
    an: string | null;
    VitalSign_T: number;
    VitalSign_PR: number;
    VitalSign_RR: number;
    VitalSign_BP: string;
    VitalSign_Wt: number;
    VitalSign_Ht: number;
    VitalSign_BMI: number;
    VitalSign_BSA: number;
    GA: string | null;
    BodyProportion: number;
    EyesENT_IsNormal: boolean;
    EyesENT_Remark: string | null;
    Respiration_IsNormal: boolean;
    Respiration_Remark: string | null;
    Cardiovascular_IsNormal: boolean;
    Cardiovascular_Remark: string | null;
    Abdomen_IsNormal: boolean;
    Abdomen_Remark: string | null;
    Goin_IsNormal: boolean;
    Goin_Remark: string | null;
    Extremities_IsNormal: boolean;
    Extremities_Remark: string | null;
    Lymphnodes_IsNormal: boolean;
    Lymphnodes_Remark: string | null;
    Vascular_IsNormal: boolean;
    Vascular_Remark: string | null;
    Neurological_IsNormal: boolean;
    Neurological_Remark: string | null;
    Breast_IsNotIndicate: boolean;
    Breast_Remark: string | null;
    AnusRectum_IsNotIndicate: boolean;
    AnusRectum_Remark: string | null;
    Genitalia_IsNotIndicate: boolean;
    Genitalia_Remark: string | null;
    Investigation: string | null;
    Impression: string | null;
    Plans: string | null;
    Comment: string | null;
    HeadDrawing: string | null;
    ChestDrawing: string | null;
    BodyDrawing: string | null;
    GenitalDrawing: string | null;
    CreatedDate?: string;
    CreatedBy?: string | null;
    UpdatedDate?: string;
    UpdatedBy?: string | null;
}

export class AdmissionRecordPhysicalModel {
    private client: Client<any>;
    private state: AdmissionNotePhysicalExaminationDto[] = [];
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public get(an: string): Promise<AdmissionNotePhysicalExaminationDto[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("admission-record-physical");
            this.client.getQuery({ query: _params }).then((res: AdmissionNotePhysicalExaminationDto[]) => {
                if (res && res.length > 0) {
                    res.forEach((x) => {
                        if (this.state.find((y) => y.an === x.an) === undefined) {
                            this.state = [...this.state, x];
                        }
                        else {
                            this.state = this.state.map((y) => {
                                if (y.an === x.an) {
                                    return x;
                                }
                                return y;
                            });
                        }
                    });
                    resolve(this.state.filter((x) => x.an === an));
                } else {
                    this.state = [];
                    resolve([]);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public create(data: AdmissionNotePhysicalExaminationDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client = new Client("admission-record-physical");
            this.client.create({ request: data }).then(async (res: AdmissionNotePhysicalExaminationDto) => {
                await this.get(data.an!);
                resolve();
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public update(data: AdmissionNotePhysicalExaminationDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client = new Client("admission-record-physical");
            const Id = data.Id;
            delete data.Id;
            this.client.update({ id: Id ?? '', request: data }).then(async (res: AdmissionNotePhysicalExaminationDto) => {
                await this.get(data.an!);
                resolve();
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * It returns the state of the component.
     * @returns The state of the component.
     */
    public get admissionNotePhysicalExaminations(): AdmissionNotePhysicalExaminationDto[] {
        return this.state;
    }

    /**
     * It clears the state of the component.
     */
    public clearState() {
        this.state = [];
    }
}
