import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import { Bill_h } from "../bills/bill";
import Client from "../../../api/client";
export interface XrayDto {
    id: string;
    an: string;
    orderDate: string;
    xrayOrders: XrayOrder[];
}

export interface XrayRequest {
    id: string;
    an: string;
    orderDate: string;
    xrayOrders: XrayOrder[];
}

export interface XrayOrder {
    [key: string]: any;
    id: string;
    name: string;
    type: string;
}

export interface Xray {
    xpart_code: string;
    xpart_des: string;
    xproc_part: string;
    xproc_des: string;
    xproc_code: string;
    xprice_ipd?: string;
    film_no: string;
    xprice_amt: string;
    xprice_df: string;
    cost: string;
    refund: string;
    df_type: string;
    disc_f: string;
    hideSelect: string;
}

export interface XrayPart {
    xpart_code: string;
    xpart_des: string;
    xopd_count: string;
    xipd_count: string;
    xopd_amt: string;
    xipd_amt: string;
    xprefix: string;
    hiPrice: string;
    exam_code: string;
    hideSelect: string;
}

export interface Xreq_h {
    xrunno: string;
    hn: string;
    xconfirm: string;
    lastRunNo: number;
    xfilm_no: number;
    xreq_date: Date;
    xreq_time: string;
    xreq_doc_code: string;
    totalAmt: number;
    xprice_df: number;
    xres_flag: string;
    xprefix: string;
    xn_no: string;
    waitStat: string;
    ipd_stat: string;
    reqWard: string;
    usrname: string;
    remark: string;
    rxNo: string;
    newXN: string;
    exam_code: string;
    regist_flag: string;
    cfm_user: string;
    cfm_date: string;
    cfm_time: string;
    authFlag: string;
    endReqUser: string;
    items?: Xreq_d[];
}


export interface Xreq_d {
    xprice_amt: number;
    xrunno: string;
    runNo: number;
    xpart_code: string;
    xproc_code: string;
    xreq_date: Date;
    xreq_time: string;
    xprice_code: string;
    xprefix: string;
    bd_item: string;
    xdisc_f: string;
    xprice_df: number;
    filmCount: number;
    refund: string;
    exam_type: string;
    chgCode: string;

    //please remove this before create
    charge_code?: string;
    xproc_des?: string;
    xpart_des?: string;
    charge_des?: string;
    app_flag?: string;
}


export interface XrayOrders {
    an: string;
    orderId?: string;
    XrayReqNo: string;
    order: Xreq_h;
}

export interface Xcon {
    xcon_code: string;
    xprefix: string;
    xrunno: string;
    xn_no: string;
    xhis_rec: string;
    xord_no: string;
    xchr_code: string;
    lastXnDate: Date;
    XnIsHn: string;
    YearlyReset: string;
    xcon_des: string;
    xunit_code: string;
    hideSelect: string;
    commonXN: string;
    sequence: number;
    resformname: string;
    keyCOPD: string;
    AppointLimit: number;
    PACMapCode: string;
    seqence: string;
}

export interface CARDBRW {
    BRW_TYPE: string;
    HN: string;
    BRW_DATE: Date;
    BRW_NO: number;
    BRW_TIME: string;
    DOCCODE: string;
    BRW_DESC: string;
    MAKER: string;
    XN: string;
    XPREFIX: string;
    REMARK: string;
    XBRW_TYPE: string;
}

export interface Bill_d {
    usrname: string;
    running_no: string;
    ioflag: string;
    charge_code: string;
    charge_des: string;
    amount: string;
    date: string;
    time: string;
    hn: string;
    disc_flag: string;
    ref_no: string;
    doccode: string;
    invsite: string;
    sysID: string;
    refund: string;
    reverseFlag: string;
    paidFlag: string;
    rxno: string;
    serviceUnit: string;
    cost: number;
    ucost: number;
    vat_flag: string;
    rev_itm: string;
    RefID: string;
    itemCompName: string;
    itemChargeDate: Date;
    itemCode: string;
    itemPrefix: string;
    itemType: string;
    itemQuant: string;
    itemActionUnit: string;
    cancel_itm_no: string;
    itemSysID: string;
    regist_flag: string;
    itemChargeUnit: string;
}

export interface DisplayRadiologyViewRecord {
    hn?: string;
    regNo?: string;
    dateIn?: string;
    typeIn?: string;
    disease?: string;
    ICDCode?: string;
    status?: string;
    recieveFrom?: string;
    sendTo?: string;
}

export interface RadiologyViewRecordItems {
    xprefix: string;
    xprefixOrder?: RadiologyViewRecordXprefixOrders[];
}

export interface RadiologyViewRecordXprefixOrders {
    name: string;
    xres_flag: string;
    authFlag: string;
    itemList: RadiologyViewRecordItemsDetail[];
}

export interface RadiologyViewRecordItemsDetail {
    xrunno: string;
    xrev_flag: string;
    resultHeader: string;
    resultDetailValue: string;
}

export interface pacLink {
    xprefix: string;
    xrunno: string;
    hn: string;
}

export const mockXrayItems = [
    { id: "1", type: "ตรวจภายใน", name: 'Ultrasound' },
    { id: "2", type: "ตรวจภายใน", name: 'US Breast' },
    { id: "3", type: "ตรวจภายใน", name: 'US Portable' },
    { id: "4", type: "ตรวจภายใน", name: 'US OB' }];


export class XrayModel {
    private client: Client<any>;
    private xrayOrders: XrayDto[] = [];
    private currentOrder: XrayOrder[] = [];
    private xrayItems: XrayOrder[] = [];
    private masterXrayItems: Xray[] = [];
    private xrayOrder: XrayOrders[] = [];
    private prepareSaveXrayOrder: Xreq_h[] = [];
    private partMaster: XrayPart[] = [];
    private xcons: Xcon[] = [];
    private radiologyViewRecord: DisplayRadiologyViewRecord[] = [];
    private xprefixHeader: RadiologyViewRecordItems[] = [];
    private xrayPACResult: string | undefined;
    private pacLink?: pacLink;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public get(an: string): Promise<XrayDto[]> {
        this.xrayItems = mockXrayItems as XrayOrder[];
        return new Promise((resolve, rejact) => {
            if (process.env.REACT_APP_ENV === "mock") {
                this.xrayOrders = [] as XrayDto[];
                resolve(this.xrayOrders);
            } else {
                this.xrayOrders = [] as XrayDto[];
            }
        });
    }

    public getOrder(an: string, flag: string): Promise<XrayOrders[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("xray-order");
            this.client.getQuery({ query: _params }).then((data: any) => {
                // if (res.data.XrayOrder.length > 0) {
                if (data.length > 0) {
                    let _datas = [] as XrayOrders[];
                    if (this.prepareSaveXrayOrder.length > 0) {
                        this.prepareSaveXrayOrder.map((item: Xreq_h) => {
                            _datas = [..._datas, { an: an, XrayReqNo: item.xn_no, order: item }];
                            return true;
                        });
                    }
                    if (flag) {
                        _datas = [...data, ..._datas];
                    }
                    resolve(_datas);
                } else {
                    if (!this.prepareSaveXrayOrder) {
                        this.xrayOrder = [];
                        this.clearPrepareXrayOrder();
                        resolve([]);
                    } else {
                        // eslint-disable-next-line prefer-const
                        let _datas = [] as XrayOrders[];
                        this.prepareSaveXrayOrder.map(item => {
                            _datas = [..._datas, { an: an, XrayReqNo: item.xn_no, order: item }];
                            return true;
                        });
                        resolve(_datas);
                    }
                }
                // resolve(res.data.XrayOrder);
            }).catch((err: any) => {
                reject(err);
            });

        });
    }

    public getLastXrunNo(xprefix: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const _params = { xprefix: xprefix };
            this.client = new Client("xray-xrunnoxcon");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                }
                else {
                    resolve('00000000');
                }
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    public getWradFromIpdH(hn: string, regist_flag: string): Promise<string> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regist_flag: regist_flag };
            this.client = new Client("xray-wrad");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                }
                else {
                    resolve("0");
                }
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }

    public getLastDetailBillH(hn: string, regNo: string): Promise<Bill_h> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("xray-lastdetailbillh");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data as Bill_h);
                }
                else {
                    resolve({} as Bill_h);
                }
            }).catch((error: any) => {
                console.log(error);
                rejects(error);
            });
        });
    }
    public getLastBRW_NO(hn: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("xray-lastbrwno");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                }
                else {
                    resolve(0);
                }
            }).catch((error: any) => {
                console.log(error);
                reject(error);
            });
        });
    }

    public create(data: XrayOrders, lastUpdateDate: Date, newWrad: string, lastBillRunNo: string, billDetail: Bill_d[], cardbrw: CARDBRW, orderId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const user = localStorage.getItem("user");
            this.client = new Client("xray-order");
            const headers = data.order;
            const headersItems = data.order.items;
            delete headers.items;
            const _postData = {
                lastUpd: lastUpdateDate,
                wrad: newWrad,
                xrunno: data.order.xrunno,
                xprefix: data.order.xprefix,
                hn: data.order.hn,
                regist_flag: data.order.regist_flag,
                lastDetail: lastBillRunNo,
                totalAmt: data.order.totalAmt ? data.order.totalAmt.toString() : "0",
                nonDiscAmt: data.order.xprice_df ? data.order.xprice_df.toString() : "",
                header: headers,
                items: headersItems,
                bill_d: billDetail,
                cardbrw: cardbrw,
                mapOrder: {
                    an: data.an,
                    XrayReqNo: data.order.xrunno,
                    orderId: orderId,
                    CreateBy: user ? JSON.parse(user).username : ""
                }
            };
            this.client.create({ request: _postData }).then((res: any) => {
                if (res === data.order.xrunno) {
                    this.xrayOrder = [...this.xrayOrder, data];
                }
                this.getXrayItemByName("", "");
                resolve(true);
            }).catch((err: any) => {
                console.log(err);
                resolve(false);
            });
        });
    }

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }
    }

    public getAllXrayItem() {
        return new Promise((resolve, reject) => {
            this.client = new Client("xrayitems");
            this.client.getAll().then((res: Xray[]) => {
                this.masterXrayItems = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getXrayItemByName(name: string, xrayPart: string) {
        return new Promise((resolve, reject) => {
            this.client = new Client("xray-items-by-name");
            const _postData = {
                xproc_des: name,
                xproc_part: xrayPart
            };
            this.client.postGet({ request: _postData }).then((data: Xray[]) => {
                resolve(data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getXrayType(): Promise<Xcon[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("xraytypes");
            this.client.getAll().then((res: any[]) => {
                this.xcons = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getXrayPart() {
        return new Promise((resolve, reject) => {
            this.client = new Client("xrayparts");
            this.client.getAll().then((res: XrayPart[]) => {
                this.partMaster = res;
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getXrayPartByName(name: string) {
        return new Promise((resolve, reject) => {
            const _params = { xpart_des: name };
            this.client = new Client("xray-parts-by-name");
            this.client.getQuery({ query: _params }).then((data: XrayPart[]) => {
                resolve(data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getXrayPartByXprefix(xprefix: string) {
        return new Promise((resolve, reject) => {
            const _params = { xprefix: xprefix };
            this.client = new Client("xray-parts-by-xprefix");
            this.client.getQuery({ query: _params }).then((data: XrayPart[]) => {
                resolve(data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //#region Xray Radiology
    public getXrayVisitHistory(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            this.xrayPACResult = "";
            this.xprefixHeader = [];
            const _params = { hn: hn.trim() };
            this.client = new Client("xray-visit-histories");
            this.client.getQuery({ query: _params }).then((data: any[]) => {
                if (data && data.length > 0) {
                    this.radiologyViewRecord = data.map((data: any) => {
                        return {
                            regNo: data.regNo,
                            dateIn: dayjs(data.registDate).format("DD/MM/YYYY"),
                            typeIn: data.VisitCat_Relation ? data.VisitCat_Relation.visitcat_desc : "",
                            disease: "",
                            ICDCode: "",
                            status: data.ipdStatus,
                            recieveFrom: data.Refer_Relation ? data.Refer_Relation.ReferHCODE : "",
                            sendTo: "",
                            hn: data.hn,
                        };
                    });
                    resolve(this.radiologyViewRecord);
                } else {
                    this.radiologyViewRecord = [];
                    this.xprefixHeader = [];
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getXprefixHeader(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("xray-xprefixs-header");
            this.client.getQuery({ query: _params }).then(async (data: any[]) => {
                if (data && data.length > 0) {
                    const groupedData = data.reduce((result, currentObject) => {
                        const { xprefix, ...rest } = currentObject;

                        // eslint-disable-next-line security/detect-object-injection
                        if (result[xprefix]) {
                            // eslint-disable-next-line security/detect-object-injection
                            result[xprefix].xprefixOrder.push(rest);
                        } else {
                            // eslint-disable-next-line security/detect-object-injection
                            result[xprefix] = {
                                xprefix: xprefix,
                                xprefixOrder: [rest],
                            };
                        }
                        return result;
                    }, {});

                    const convertResult = Object.values(groupedData);
                    // eslint-disable-next-line prefer-const        
                    const result = await Promise.all(convertResult.map(async (data: any) => {
                        let prefixOrder = [];
                        if (data.xprefixOrder && data.xprefixOrder.length > 0) {
                            // eslint-disable-next-line prefer-const
                            data.xprefixOrder = data.xprefixOrder.map(async (OrderData: any) => {
                                // eslint-disable-next-line prefer-const
                                let itemList = [] as RadiologyViewRecordItemsDetail[];
                                this.client = new Client("xray-xprefixs-items");
                                const _params = { xprefix: data.xprefix, xrunno: OrderData.xrunno };
                                const reqDResponse = await this.client.getQuery({ query: _params });
                                if (reqDResponse && reqDResponse.length > 0) {
                                    const xItemList = reqDResponse.map((dataItem: any) => {
                                        return {
                                            xrunno: OrderData.xrunno,
                                            resultHeader: dataItem.bd_item + " " + dayjs(data.xreq_date).format("DD/MM/YYYY"),
                                            resultDetailValue: dataItem.xproc_code ? dataItem.xproc_code + " " + dataItem.xproc_des : "",
                                            xrev_flag: dataItem.xrev_flag
                                        };
                                    });
                                    itemList.push(...xItemList);
                                }
                                return {
                                    name: OrderData.xrunno + " " + dayjs(OrderData.xreq_date).format("DD/MM/YYYY"),
                                    authFlag: OrderData.authFlag,
                                    xres_flag: OrderData.xres_flag,
                                    itemList: itemList
                                };
                            });
                            prefixOrder = await Promise.all(data.xprefixOrder);
                        }
                        return {
                            xprefix: data.xprefix,
                            xprefixOrder: prefixOrder
                        };
                    }));
                    // this.xprefixHeader = prefixResult;
                    resolve(this.xprefixHeader = result);
                } else {
                    this.xprefixHeader = [];
                    resolve(this.xprefixHeader);
                }
            });
        });
    }

    public getXrayResultFromPAC(xprefix: string, xrunno: string) {
        return new Promise((resolve, reject) => {
            const _params = { xprefix: xprefix, xrunno: xrunno };
            this.client = new Client("xray-result-pacs");
            this.client.getQuery({ query: _params }).then((data: any[]) => {
                if (data && data.length > 0) {
                    this.xrayPACResult = data[0].xresTxt;
                    this.pacLink = {
                        xprefix: data[0].xprefix,
                        xrunno: data[0].xrunno,
                        hn: data[0].hn
                    };
                    resolve(this.xrayPACResult);
                } else {
                    this.xrayPACResult = "รออ่านผล....";
                    this.pacLink = undefined;
                    resolve(this.xrayPACResult);
                }
            });
        });
    }

    public get patientRadiologyViewRecord() {
        return this.radiologyViewRecord;
    }

    public get XprefixHeader() {
        return this.xprefixHeader;
    }

    public get xrayPACResultText() {
        return this.xrayPACResult;
    }
    //#endregion

    public clearXrayOrder() {
        this.xrayOrder = [] as XrayOrders[];
        this.clearPrepareXrayOrder();
    }

    public clearPrepareXrayOrder() {
        this.prepareSaveXrayOrder = [];
    }

    public setPrepareXrayOrder(data: Xreq_h) {
        this.prepareSaveXrayOrder.push(data);
    }

    public get xrays() {
        return this.xrayOrders;
    }

    public get xrayItem() {
        return this.xrayItems;
    }

    public setCurrentState(data: XrayOrder[]) {
        this.currentOrder = data;
    }

    public clearCurrentState() {
        this.currentOrder = [];
    }

    public get currentXrayOrder() {
        return this.currentOrder;
    }

    public get xrayOrderState(): XrayOrders[] {
        return this.xrayOrder;
    }

    public get xrayPartState(): XrayPart[] {
        return this.partMaster;
    }

    public get prepareSaveXrayOrderState(): Xreq_h[] {
        return this.prepareSaveXrayOrder;
    }

    public get xrayTypeState(): Xcon[] {
        return this.xcons;
    }

    public get masterXrayItemState(): Xray[] {
        return this.masterXrayItems;
    }

    public get pacLinkState() {
        return this.pacLink;
    }

}
