import { AuthModel } from "../models/domain/auths/Auth";
import { LabModel } from "../models/domain/labs/lab";
import { LearnHowModel } from "../models/domain/learnHows/LearnHow";
import { MedicineModel } from "../models/domain/medicines/Medicine";
import { PatientModel } from "../models/domain/patients/Patient";
import { SelectWardModel } from "../models/domain/selectWards/SelectWard";
import { UserModel } from "../models/domain/users/User";
import { XrayModel } from "../models/domain/xrays/Xray";

export default class AppViewModel {
    private userModel: UserModel;
    private selectWardModel: SelectWardModel;
    private medicineModel: MedicineModel;
    private labModel: LabModel;
    private xrayModel: XrayModel;
    private learnHowModel: LearnHowModel;
    private patientModel: PatientModel;
    private authModel: AuthModel;
    constructor(_user: UserModel, _selectWard: SelectWardModel, _medicine: MedicineModel, _lab: LabModel, _xray: XrayModel, _learnHow: LearnHowModel, _patient: PatientModel, _auth: AuthModel) {
        this.userModel = _user;
        this.selectWardModel = _selectWard;
        this.medicineModel = _medicine;
        this.labModel = _lab;
        this.xrayModel = _xray;
        this.learnHowModel = _learnHow;
        this.patientModel = _patient;
        this.authModel = _auth;
    }
    public logout() {
        this.userModel.delete();
    }

    public get users() {
        return this.userModel.users;
    }

    public get selectWards() {
        return this.selectWardModel.selectWards;
    }

    public get labType() {
        return this.labModel.LabTypes;
    }

    public get xrayPartState() {
        return this.xrayModel.xrayPartState;
    }

    public get howMasters() {
        return this.learnHowModel.howMasters;
    }

    public get howsWithDose() {
        return this.learnHowModel.hows;
    }

    public get xrayType() {
        return this.xrayModel.xrayTypeState;
    }

    public get masterXrayItems() {
        return this.xrayModel.masterXrayItemState;
    }

    public get masterReligion() {
        return this.patientModel.MasterReligion;
    }

    public getAllWard() {
        this.selectWardModel.getAll();
    }

    public getLabType() {
        return this.labModel.getLabTypes();
    }

    public getAllXrayItems() {
        return this.xrayModel.getAllXrayItem();
    }

    public getXrayPart() {
        return this.xrayModel.getXrayPart();
    }

    public getXrayType() {
        return this.xrayModel.getXrayType();
    }

    public getAllHowMaster() {
        return this.learnHowModel.getAll();
    }

    public getDose() {
        this.learnHowModel.getDose();
    }


    public getMasterReligion() {
        return this.patientModel.getReligion();
    }

    public verifyToken() {
        return this.authModel.verifyToken();
    }
}