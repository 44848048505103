import { CssBaseline, ThemeProvider } from "@mui/material";
import Routes from "../../../routes";
import { theme } from "../../../themes";
import { ViewProps } from "../../@types/App";

const AppView = (props: ViewProps) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes user={props.user} onLogout={props.onLogout} />
    </ThemeProvider>
);
export default AppView;