import { makeAutoObservable } from "mobx";
import Client from "@/app/api/client";

export interface OtherOrder {
    Id?: string;
    an: string;
    Note: string;
    orderId?: string;
    orderType?: string;
    CreateAt?: string;
    CreatedBy?: string;
}

export interface OtherOrderHeader {
    hn: string;
    req_no: string;
    req_date: string;
    req_time: string;
    items: OtherOrderItem[];
}

export interface OtherOrderItem {
    req_no: string;
    req_date: string;
    req_time: string;
    desc: string;
}
export class OtherModel {
    private otherOrder: OtherOrder[] = [];
    private prepareSaveOtherOrder: OtherOrder[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }
    }

    public getOrder(an: string, flag: string): Promise<OtherOrder[]> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("other-order");
            this.client.getQuery({ query: _params }).then((res: any[]) => {
                // if (res.data.OtherOrder.length > 0) {
                if (res && res.length > 0) {
                    if (flag) {
                        resolve(this.otherOrder = res);
                    } else {
                        resolve(this.otherOrder = this.prepareSaveOtherOrder);
                    }
                } else {
                    if (!this.prepareSaveOtherOrder) {
                        this.otherOrder = [];
                        this.clearPrepareOtherOrder();
                        resolve([]);
                    } else {
                        // eslint-disable-next-line prefer-const
                        let _datas = [] as OtherOrder[];
                        _datas = this.prepareSaveOtherOrder;
                        resolve(this.otherOrder = _datas);
                    }
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }

    public createOrder(data: OtherOrder): Promise<OtherOrder> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const user = localStorage.getItem("user");
            data.CreatedBy = user ? JSON.parse(user).username : "";
            try {
                this.client = new Client("other-order");
                this.client.create({ request: data }).then((res: any) => {
                    if (res.message) {
                        resolve(res.message);
                    }
                }).catch((err: any) => {
                    console.log(err);
                    reject(err);
                });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }
    public clearPrepareOtherOrder() {
        this.prepareSaveOtherOrder = [];
    }

    public setPrepareOtherOrder(data: OtherOrder) {
        this.prepareSaveOtherOrder.push(data);
    }

    public get others() {
        return this.otherOrder;
    }

    public get prepareSaveOtherOrderState(): OtherOrder[] {
        return this.prepareSaveOtherOrder;
    }

}
