import Client from "@/app/api/client";
import { configure, makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";

export interface displayRoomHistory {
    status?: string;
    room_name?: string;
    room_type?: string;
    ward_name?: string;
    doc_name?: string;
    price?: string;
    from_room?: string;
    date_in?: string;
    time_in?: string;
    date_out?: string;
    time_out?: string;
    no?: string;
    summary_date?: string;
    summary_time?: string;
}

export interface requestTransfer {
    hn?: string;
    new_ward?: string;
    old_ward?: string;
    new_room?: string;
    old_room?: string;
    prev_room_cur?: string;
    next_room_cur?: string;
    prev_ward_cur?: string;
    next_ward_cur?: string;
    /** Current Date and Time */
    check_out_date?: string | null;
    check_out_time?: string | null;
    /** Current Date and Time */
    res_status?: string;
    obs_deduct?: string;
    tranfflage?: string;
    regist_flag?: string;
    res_backup_stat?: string;
    resident?: Resident;
    check_in_date?: string;
    check_in_time?: string;
}

export interface Resident {
    last_charge_date: string;
    last_charge_time: string;
    hn: string;
    runno?: string;
    fromroom?: string;
    sex?: string;
    age?: string;
    check_in_date?: string;
    check_in_time?: string;
    room_no?: string;
    res_status?: string;
    room_rate?: string;
    rate_change_flag?: string;
    bed_occ?: string;
    usrname?: string;
    ward_id?: string;
    food_rate?: string;
    ser_rate?: string;
    gser_rate?: string;
    room_des?: string;
    regist_flag?: string;
    oth_rate: string;
}
export class TransferModel {
    private roomOcc: number = 0;
    private transferHistory: displayRoomHistory[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        dayjs.extend(buddhistEra);
        this.client = new Client("");
        configure({
            enforceActions: "never",
        });
    }


    public convertTime(data: any) {
        const timeObj = dayjs(data, "HHmm");
        const time = dayjs(timeObj).format("HH:mm");
        return time.toString();
    }

    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }
    }

    public updateRoomOccupancy(wardId: string, roomNo: string, roomCurOcc: string) {
        return new Promise((resolve, reject) => {
            this.client = new Client("update-room-occupancies");
            const datas = {
                ward_id: wardId,
                room_no: roomNo,
                room_cur_occ: roomCurOcc,
            };
            this.client.update({ id: "", request: datas }).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }


    public updateOldRoom = (old_wardId: string, old_room: string, prev_ward_cur: string, prev_room_cur: string) => {
        return new Promise((resolve, reject) => {
            this.client = new Client("update-old-room");
            const datas = {
                old_ward_id: old_wardId,
                old_room: old_room,
                prev_room_cur: prev_room_cur,
                prev_ward_cur: prev_ward_cur,
            };
            this.client.update({ id: "", request: datas }).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    };

    public updateNewRoom = (new_wardId: string, new_room: string, next_ward_cur: string, next_room_cur: string) => {
        return new Promise((resolve, reject) => {
            this.client = new Client("update-old-room");
            const datas = {
                old_ward_id: new_wardId,
                old_room: new_room,
                prev_room_cur: next_room_cur,
                prev_ward_cur: next_ward_cur,
            };
            this.client.update({ id: "", request: datas }).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    };


    public getResidentRunno = (hn: string, room_no: string) => {
        return new Promise((resolve, reject) => {
            this.client = new Client("residents-runno");
            const data = { hn: hn.trim(), room_no: room_no };
            this.client.postGet({ request: data }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve("0");
                }
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    };

    public TransferRoom(data: requestTransfer, editdatetime: Date): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("transfer-room");
            const datas = {
                hn: data.hn?.trim(),
                old_ward: data.old_ward,
                old_room: data.old_room,
                new_room: data.new_room,
                regist_flag: data.regist_flag,
                check_out_date: data.check_out_date,
                check_out_time: data.check_out_time,
                res_status: data.res_status,
                obs_deduct: data.obs_deduct,
                editdatetime: editdatetime,
                check_in_date: data.check_in_date,
                check_in_time: data.check_in_time,
                resident: data.resident
            };
            this.client.create({ request: datas }).then((res: any) => {
                resolve(true);
            }).catch((err: any) => {
                console.log(err);
                reject(err);
            });
        });
    }


    public getTransferHistory(hn: string, regisNo: string) {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regis_no: regisNo.trim() };
            this.client = new Client("transfer-history");
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res && res.length > 0) {
                    this.transferHistory = res.map((value: any) => {
                        return {
                            status: value.res_status === "C" ? "ย้ายห้อง" : value.res_status === "N" && value.obs_deduct === "Y" ? "จองไว้" : "พักอยู่",
                            room_name: value.room_no ?? "",
                            room_type: value.room_des ?? "",
                            ward_name: value.ward_name ? value.ward_name : "",
                            doc_name: `${value.docCode} ${value.docName ?? ""} ${value.docLName ?? ""}`,
                            price: value.room_rate ?? "",
                            from_room: value.fromroom ?? "",
                            date_in: value.check_in_date ? dayjs(value.check_in_date).format("DD/MM/YYYY") : "",
                            time_in: value.check_in_time ? this.convertTime(value.check_in_time) : "",
                            date_out: value.check_out_date && value.check_out_date.trim() ? dayjs(value.check_out_date).format("DD/MM/YYYY") : "",
                            time_out: value.check_out_time && value.check_out_time.trim() ? this.convertTime(value.check_out_time) : "",
                            no: value.runno ?? "00",
                            summary_date: value.last_charge_date ? dayjs(value.last_charge_date).format("DD/MM/YYYY") : "",
                            summary_time: value.last_charge_time ? this.convertTime(value.last_charge_time) : ""
                        } as displayRoomHistory;
                    });
                    this.roomOcc = this.transferHistory.length;
                    resolve(this.transferHistory);
                } else {
                    this.roomOcc = 0;
                    this.transferHistory = [];
                    reject("ไม่พบข้อมูล");
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public get TranferHistory() {
        return this.transferHistory;
    }
}
