import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";
import FirebaseClient from "../../../api/firebase";

export interface DischargeDto {
    Id?: string;
    an?: string;
    principalDiagosis?: string;
    comorbidity?: InfDischarge501Item[];
    complication?: InfDischarge501Item[];
    otherDiagosis?: string;
    externalCauseOfInjuryPoisoning?: string;
    pdx?: string;
    co1?: string;
    co2?: string;
    co3?: string;
    com1?: string;
    com2?: string;
    com3?: string;
    other?: string;
    op1?: string;
    op2?: string;
    op3?: string;
    op4?: string;
    ext?: string;
    nonOrProcedure?: InfDischarge501Item[];
    paracentesisAbdominal: boolean;
    paracentesisChest: boolean;
    arterialCatheterization: boolean;
    venousCatheterization: boolean;
    venousCutDown: boolean;
    cvpMonitoring: boolean;
    arterialMonitoring: boolean;
    tracheostorny: boolean;
    etTube: boolean;
    cpr: boolean;
    defibrillation: boolean;
    ventilatorLess96: boolean;
    ventilatorMore96: boolean;
    hemodialysis: boolean;
    hemodialysisQty: number;
    lp: boolean;
    icd: boolean;
    capd: boolean;
    capdQty: number;
    prc: boolean;
    platelet: boolean;
    ffp: boolean;
    injectionOfThrombolyticAgent: boolean;
    chemotherapy: boolean;
    currentDate: string;
    //#region Admission
    admissionDate: string;
    admissionTime: string;
    admissionWard: string;
    //#endregion
    //#region Discharge
    dischargeDate: string;
    dischargeTime: string;
    dischargeStatus: number;
    dischargeType: number;
    dischargeNote: string;
    dischargeWard: string;
    //#endregion
    los: number;
    from: string;
    referReason: string;
    referNo: number;
    lengthOfStay: number;
    hospitalReferName?: string;
    weight: number;
    hhc: boolean;
    qtyBackToHomeDay: number;
    // Note: Typo here 'orProcedure -> orProcedue'
    orProcedue?: InfDischarge501Item[];
    CreateBy?: string;
}

export interface InfDischarge501Item {
    Id?: string;
    dischargeId?: string;
    type?: string;
    title?: string;
    detailValue?: string;
    checked?: boolean;
    note?: string;
    date?: string;
    timeIn?: string;
    timeOut?: string;
    CreateBy?: string;
}


export interface DischargeRequest {
    id: string;
    an?: string;
    principalDiagosis?: string;
    comorbidity?: CheckboxItemDto[];
    complication?: CheckboxItemDto[];
    otherDiagosis?: string;
    externalCauseOfInjuryPoisoning?: string;
    pdx?: string;
    co1?: string;
    co2?: string;
    co3?: string;
    com1?: string;
    com2?: string;
    com3?: string;
    other?: string;
    op1?: string;
    op2?: string;
    op3?: string;
    op4?: string;
    ext?: string;
    nonOrProcedure?: CheckboxItemDto[];
    paracentesisAbdominal: boolean;
    paracentesisChest: boolean;
    arterialCatheterization: boolean;
    venousCatheterization: boolean;
    venousCutDown: boolean;
    cvpMonitoring: boolean;
    arterialMonitoring: boolean;
    tracheostorny: boolean;
    etTube: boolean;
    cpr: boolean;
    defibrillation: boolean;
    ventilatorLess96: boolean;
    ventilatorMore96: boolean;
    hemodialysis: boolean;
    hemodialysisQty: number;
    lp: boolean;
    icd: boolean;
    capd: boolean;
    capdQty: number;
    prc: boolean;
    platelet: boolean;
    ffp: boolean;
    injectionOfThrombolyticAgent: boolean;
    chemotherapy: boolean;
    currentDate: string;
    //#region Admission
    admissionDate: string;
    admissionTime: string;
    admissionWard: string;
    //#endregion
    //#region Discharge
    dischargeDate: string;
    dischargeTime: string;
    dischargeStatus: number;
    dischargeType: number;
    dischargeNote: string;
    dischargeWard: string;
    //#endregion
    los: number;
    from: string;
    referReason: string;
    referNo: number;
    lengthOfStay: number;
    hospitalReferName?: string;
    weight: number;
    hhc: boolean;
    qtyBackToHomeDay: number;
    orProcedue: OrProcedure[];
}

export interface CheckboxItemDto {
    // i use id for handle data when add new item
    // { gonna handle id before save to hasura }
    Id?: string;
    title?: string;
    detailValue?: string;
    checked?: boolean;
    // need to use type for check type of item
    type?: string;
    CreateBy?: string;

}
export interface HospitalsList {
    id: string;
    name: string;
    province: string;
    hospitalId: number;
}

export interface Discharge_log {
    hn?: string;
    regist_flag: string;
    userID: string;
    Discharge_date: string;
    Discharge_time: string;
    /**
     * Discharge_status 
     * ยกเลิก = 0
     */
    Discharge_status: string;
    /**
     * Discharge_type
     * ยกเลิก = 0
     */
    Discharge_type: string;
    /**
     * DC_Flag
     * D = Discharge
     * U = Update (ยกเลิก)
     */
    DC_Flag: string;
    Remark: string;
}

export interface Discharging {
    item: Discharge_log;
    ward_id: string;
    codeName: string;
    codeLName: string;
    an: string;
    usrname: string;
    adm_weight: string;
    leaveDay: number;
    hhc: string;
    room_id: string;
}
export interface Discharge_Ipd {
    ladmit_n: string;
    hn: string;
    admit_date: string;
    admit_time: string;
    current_room: string;
    ward_id: string;
    old_room: string;
    old_room_runno: string;
    ward_old_room: string;
    discharge_date: string;
    discharge_time: string;
    discharge_status: string;
    note_text: string;
    dept_code: string;
    doccode: string;
    discharge_type: string;
    usr_discharge: string;
    undousr_discharge: string;
    leaveDay: number;
    hhc: string;
    adm_weight: string;
}


export const mockHospitalList = [
    { id: "1", name: "รพ. ลพบุรี", province: "ลพบุรี", hospitalId: 15770 },
    { id: "2", name: "รพ. บ้านใหม่โพธิ์งาม ,สอต", province: "สุโขทัย", hospitalId: 14854 },
    { id: "3", name: "รพ. ศรีนคร ,สสอ", province: "สุโขทัย", hospitalId: 18954 }
];

export const defaultComorbidity = [
    {
        "title": "Acute respiratory failure",
        "checked": false,
    },
    {
        "title": "UTI agent",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Shock from",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Sepsis",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "DM type",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "HT",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "CKD",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "CAD type",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Dyslipidemia",
        "checked": false,
    },
    {
        "title": "CVD type",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Pneumonia agent",
        "checked": false,
    },
    {
        "title": "COPD with",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Diabetic polyneuropathy (E14.4.G63.2)",
        "checked": false,
    },
    {
        "title": "Anemia from",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Diabetic peripheral angiopathy (E14.5179.2)",
        "checked": false,
    }
];

export const defaultComplication = [
    {
        "title": "Hospital acquired pneumonia agent",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Malnutrition",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Hypokalemia (E87.6)",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Hyponatremia (E87.1)",
        "detailValue": "",
        "checked": false,
    },
];

export const defaultNonOrProcedure = [
    {
        "title": "Echocardiogram (88.72)",
        "checked": false,
    },
    {
        "title": "Ultrasound",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "CT",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "MRI",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "Gastroscope biopsy (44.14)",
        "checked": false,
    },
    {
        "title": "Gastroscope No biopsy (44.13)",
        "checked": false,
    },
];

export const defaultNonOrProcedureExtra = [
    {
        "title": "Paracentesis abdominal (54.91)",
        "checked": false,
    },
    {
        "title": "Paracentesis chest (34.91)",
        "checked": false,
    },
    {
        "title": "Arterial catheterization (38.91)",
        "checked": false,
    },
    {
        "title": "Venous catheterization (38.93)",
        "checked": false,
    },
    {
        "title": "Venous cut down (38.94)",
        "checked": false,
    },
    {
        "title": "CVP monitoring (89.62)",
        "checked": false,
    },
    {
        "title": "Arterial monitoring (89.61)",
        "checked": false,
    },
    {
        "title": "Tracheostorny (31.1)",
        "checked": false,
    },
    {
        "title": "ET tube (96.04)",
        "checked": false,
    },
    {
        "title": "CPR (99.60)",
        "checked": false,
    },
    {
        "title": "Defibrillation (99.62)",
        "checked": false,
    },
    {
        "title": "Ventilator < 96 hours (96.71)",
        "checked": false,
    },
    {
        "title": "Ventilator > 96 hours (96.72)",
        "checked": false,
    },
    {
        "title": "Hemodialysis (39.95)",
        "checked": false,
    },
    {
        "title": "LP (03.31)",
        "checked": false,
    },
    {
        "title": "ICD (34.04)",
        "checked": false,
    },
    {
        "title": "CAPD (54.98)",
        "detailValue": "",
        "checked": false,
    },
    {
        "title": "PRC (99.04)",
        "checked": false,
    },
    {
        "title": "Platelet (99.5)",
        "checked": false,
    },
    {
        "title": "FFP (99.07)",
        "checked": false,
    },
    {
        "title": "Injection of thrombolytic Agent (99.10)",
        "checked": false,
    },
    {
        "title": "Chemotherapy (99.25)",
        "checked": false,
    },
];

export interface OrProcedure {
    id: string;
    note: string;
    date: string;
    timeIn: string;
    timeOut: string;

}
export interface DischargeTypesDto {
    DischargeType?: string;
    DischargeCode?: string;
    DischargeDes?: string;
    DischargeSSN?: string;
    hideSelect?: string;
}


export interface DischargeStatusDto {
    DischargeType?: string;
    DischargeCode?: string;
    DischargeDes?: string;
    DischargeSSN?: string;
    hideSelect?: string;
}

export interface DischargeSummary {
    Id?: string;
    hn?: string;
    an?: string;
    descriptions?: string;
    CreatedDate?: string;
    CreatedBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
}
export interface DischargeSummaryTemplate {
    Id?: string;
    name?: string;
    descriptions?: string;
    userFullname?: string;
    CreatedDate?: string;
    CreatedBy?: string;
    UpdatedDate?: string;
    UpdatedBy?: string;
}
export class DischargeModel {
    private state: DischargeDto[] = [];
    private currentState?: DischargeDto;
    private hospitals: HospitalsList[] = [];
    private dischargeTypes: DischargeTypesDto[] = [];
    private dischargeStatus: DischargeStatusDto[] = [];
    private firebaseClient: FirebaseClient;
    private client: Client<any>;
    private discharge_Ipd: Discharge_Ipd[] = [];
    private discharge501?: DischargeDto;
    private previosDischarge?: DischargeDto;
    private dischargeSummary?: DischargeSummary;
    private dischargeSummaryTemplate?: DischargeSummaryTemplate[] = [];
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("Discharge");
        this.firebaseClient = new FirebaseClient();
    }

    public searchHospitalName(hospName: string) {
        if (hospName) {
            // handle With API  
            this.hospitals = [] as HospitalsList[];
            const findHosp = mockHospitalList.find(x => x.name === hospName);
            if (findHosp) {
                this.hospitals.push(findHosp);
            }
        } else {
            this.hospitals = mockHospitalList as HospitalsList[];
        }
    }


    public getAll() {
        if (process.env.REACT_APP_ENV === "mock") {
            // TODO: create mock data
            return;
        }

        // TODO: uncomment if use api, remove if not
        // this.client.getAll().then(data => {
        //     this.state = data;
        // }).catch(err => {
        //     throw err;
        // });
    }
    public get discharges() {
        return this.state;
    }

    public get hospitalsReferList() {
        return this.hospitals;
    }

    public setCurrentDischargeData(data: DischargeDto) {
        this.currentState = data;
    }

    public undoDischarge() {
        this.currentState = {} as DischargeDto;
    }

    public get currentDischargeData() {
        return this.currentState;
    }

    public getDischargeTypes(): Promise<DischargeTypesDto[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("discharge-types");
            this.client.getAll().then((data: DischargeTypesDto[]) => {
                if (data && data.length > 0) {
                    this.dischargeTypes = data;
                    resolve(this.dischargeTypes);
                } else {
                    this.dischargeTypes = [];
                    reject("No data");
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    public getDischargeStatus(): Promise<DischargeStatusDto[]> {
        return new Promise((resolve, reject) => {
            this.client = new Client("discharge-status");
            this.client.getAll().then((data: DischargeStatusDto[]) => {
                if (data && data.length > 0) {
                    this.dischargeStatus = data;
                    resolve(this.dischargeStatus);
                } else {
                    this.dischargeStatus = [];
                    reject("No data");
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    public get dischargeTypesList() {
        return this.dischargeTypes;
    }

    public get dischargeStatusList() {
        return this.dischargeStatus;
    }

    public update(id: string, data: DischargeRequest): Promise<DischargeDto> {
        return new Promise((resolve, reject) => {
            // this.firebaseClient.collection("Discharge").doc(id).set(data).then(value => {
            //     this.state = [{ ...data, id: id }];
            //     this.currentState = { ...data, id: id };
            //     resolve(this.currentState);
            // }).catch(err => {
            //     reject(err);
            // });
        });
    }

    public getWardCurOcc(ward_id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const _params = { ward_id: ward_id };
            this.client = new Client("ward-cur-occ");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data.wardCurOcc) {
                    resolve(data.wardCurOcc);
                } else {
                    resolve("0");
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    /** 
    * hhc:"Y"|""
    */
    public create(data: Discharge_log, codeName: string, codeLName: string, an: string, usrname: string, adm_weight: string, hhc: string, ward_cur_occ: string, leaveDay: number, room_no: string, wardId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("create-discharge");
            const datas = {
                hn: data.hn?.trim(),
                regNo: data.regist_flag,
                codeName: codeName,
                codeLName: codeLName,
                an: an,
                discharge_date: data.Discharge_date,
                discharge_time: data.Discharge_time,
                discharge_status: data.Discharge_status,
                note_text: data.Remark,
                usrname: usrname,
                discharge_type: data.Discharge_type,
                usr_discharge: usrname,
                adm_weightL: adm_weight,
                leaveDay: leaveDay,
                editCompname: "Hospital",
                hhc: hhc,
                ward_cur_occ: ward_cur_occ,
                ward_id: wardId,
                room_no: room_no,
                data: data
            };
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }
    public cancelDischarge(data: Discharge_log, codeName: string, codeLName: string, usrname: string, ward_cur_occ: string
        , leaveDay: number, room_no: string, wardId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("cancel-discharge");
            const datas = {
                hn: data.hn?.trim(),
                regNo: data.regist_flag,
                codeName: codeName,
                codeLName: codeLName,
                undousr_discharge: usrname,
                ward_cur_occ: ward_cur_occ,
                ward_id: wardId,
                room_no: room_no,
                data: data
            };
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getDischargeFromIpd_h(hn: string): Promise<Discharge_Ipd> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim() };
            this.client = new Client("discharge");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data && data.length > 0) {
                    resolve(data[0]);
                } else {
                    resolve({} as Discharge_Ipd);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    public createDischarge501(objectData: DischargeDto): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const orProcedue = objectData.orProcedue?.map((item: any) => {
                delete item.id;
                return item;
            });

            const nonOrProcedure = objectData.nonOrProcedure?.map((item: any) => {
                delete item.id;
                return item;
            });

            const complication = objectData.complication?.map((item: any) => {
                delete item.id;
                return item;
            });

            const comorbidity = objectData.comorbidity?.map((item: any) => {
                delete item.id;
                return item;
            });
            const items = [...orProcedue ?? [], ...nonOrProcedure ?? [], ...complication ?? [], ...comorbidity ?? []];


            delete objectData.orProcedue;
            delete objectData.nonOrProcedure;
            delete objectData.complication;
            delete objectData.comorbidity;

            const data = objectData;
            const datas = { data, items };
            this.client = new Client("create-discharge-501");
            this.client.create({ request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public updateDischarge501(objectData: DischargeDto): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const arrays = [] as any;
            const dischargeId = objectData.Id;
            // arrays.push(objectData.orProcedue);
            // arrays.push(objectData.nonOrProcedure);
            // arrays.push(objectData.complication);
            // arrays.push(objectData.comorbidity);
            if (objectData.orProcedue && objectData.orProcedue.length > 0) {
                objectData.orProcedue.forEach((item: any) => {
                    if (!item.dischargeId) {
                        item.dischargeId = dischargeId;
                    }
                    if (item.Id) {
                        arrays.push(item);
                    } else {
                        delete item.Id;
                        arrays.push(item);
                    }
                });
            }

            if (objectData.nonOrProcedure && objectData.nonOrProcedure.length > 0) {
                objectData.nonOrProcedure.forEach((item: any) => {
                    if (!item.dischargeId) {
                        item.dischargeId = dischargeId;
                    }
                    if (item.Id) {
                        arrays.push(item);
                    } else {
                        delete item.Id;
                        arrays.push(item);
                    }
                });
            }

            if (objectData.complication && objectData.complication.length > 0) {
                objectData.complication.forEach((item: any) => {
                    if (!item.dischargeId) {
                        item.dischargeId = dischargeId;
                    }
                    if (item.Id) {
                        arrays.push(item);
                    } else {
                        delete item.Id;
                        arrays.push(item);
                    }
                });
            }

            if (objectData.comorbidity && objectData.comorbidity.length > 0) {
                objectData.comorbidity.forEach((item: any) => {
                    if (!item.dischargeId) {
                        item.dischargeId = dischargeId;
                    }
                    if (item.Id) {
                        arrays.push(item);
                    } else {
                        delete item.Id;
                        arrays.push(item);
                    }
                });
            }

            delete objectData.orProcedue;
            delete objectData.nonOrProcedure;
            delete objectData.complication;
            delete objectData.comorbidity;

            const data = objectData;
            const items = arrays;
            const datas = { dischargeId, data, items };
            this.client = new Client("update-discharge-501");
            this.client.update({ id: "", request: datas }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public getDischarge501ByAn(an: string): Promise<DischargeDto> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim() };
            this.client = new Client("discharge-501");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.items && datas.items.length > 0) {
                    const orProcedue = datas.items.filter((x: any) => x.type === "orProcedue");
                    const nonOrProcedure = datas.items.filter((x: any) => x.type === "nonOrProcedure");
                    const comorbidity = datas.items.filter((x: any) => x.type === "comorbidity");
                    const complication = datas.items.filter((x: any) => x.type === "complication");

                    delete datas.items;

                    this.previosDischarge = { ...datas, comorbidity: comorbidity, complication: complication, nonOrProcedure: nonOrProcedure, orProcedue: orProcedue };
                    // eslint-disable-next-line prefer-const
                    let sortComorbidity: InfDischarge501Item[] = [];
                    // eslint-disable-next-line prefer-const
                    for (let i = 0; i < defaultComorbidity.length; i++) {
                        // eslint-disable-next-line security/detect-object-injection
                        const element = defaultComorbidity[i];
                        const filterData = comorbidity.filter((x: any) => x.title === element.title);
                        if (filterData.length === 0) {
                            sortComorbidity.push(element);
                        } else {
                            sortComorbidity.push(filterData[0]);
                        }
                    }
                    let additionalComorbidity: InfDischarge501Item[] = [];
                    additionalComorbidity = comorbidity.filter((x: any) => !defaultComorbidity.some((y) => y.title === x.title));
                    sortComorbidity.push(...additionalComorbidity);
                    // eslint-disable-next-line prefer-const
                    let sortComplication: InfDischarge501Item[] = [];
                    for (let i = 0; i < defaultComplication.length; i++) {
                        // eslint-disable-next-line security/detect-object-injection
                        const element = defaultComplication[i];
                        const filterData = complication.filter((x: any) => x.title === element.title);
                        if (filterData.length === 0) {
                            sortComplication.push(element);
                        } else {
                            sortComplication.push(filterData[0]);
                        }
                    }
                    let additionalComplication: InfDischarge501Item[] = [];
                    additionalComplication = complication.filter((x: any) => !defaultComplication.some((y) => y.title === x.title));
                    sortComplication.push(...additionalComplication);
                    // eslint-disable-next-line prefer-const
                    let sortNonOrProcedure: InfDischarge501Item[] = [];
                    // eslint-disable-next-line prefer-const
                    for (let i = 0; i < defaultNonOrProcedure.length; i++) {
                        // eslint-disable-next-line security/detect-object-injection
                        const element = defaultNonOrProcedure[i];
                        const filterData = nonOrProcedure.filter((x: any) => x.title === element.title);
                        if (filterData.length === 0) {
                            sortNonOrProcedure.push(element);
                        } else {
                            sortNonOrProcedure.push(filterData[0]);
                        }
                    }
                    let additionalNonOrProcedure: InfDischarge501Item[] = [];
                    additionalNonOrProcedure = nonOrProcedure.filter((x: any) => !defaultNonOrProcedure.some((y) => y.title === x.title));
                    sortNonOrProcedure.push(...additionalNonOrProcedure);
                    resolve(this.discharge501 = { ...datas, comorbidity: sortComorbidity, complication: sortComplication, nonOrProcedure: sortNonOrProcedure, orProcedue: orProcedue });

                    resolve(this.discharge501 = datas);
                } else {
                    resolve(this.discharge501 = {
                        ...this.currentState,
                        comorbidity: defaultComorbidity,
                        complication: defaultComplication,
                        nonOrProcedure: defaultNonOrProcedure,
                    } as DischargeDto);
                }
            });
        });
    }

    public getDischargeSummaryTemplateWithLimit(limit: number): Promise<DischargeSummaryTemplate[]> {
        return new Promise((resolve, reject) => {
            const _params = { limit: limit };
            this.client = new Client("discharge-summary-template/limit");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    const _data = datas.map((item: DischargeSummaryTemplate) => {
                        return { ...item, userFullname: item.userFullname ? item.userFullname : item.CreatedBy };
                    });
                    resolve(this.dischargeSummaryTemplate = _data);
                } else {
                    resolve(this.dischargeSummaryTemplate = []);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getDischargeSummaryTemplateWithName(name: string): Promise<DischargeSummaryTemplate[]> {
        return new Promise((resolve, reject) => {
            const _params = { name: name };
            this.client = new Client("discharge-summary-template");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    const _data = datas.map((item: DischargeSummaryTemplate) => {
                        return { ...item, userFullname: item.userFullname ? item.userFullname : item.CreatedBy };
                    });
                    resolve(this.dischargeSummaryTemplate = _data);
                } else {
                    resolve(this.dischargeSummaryTemplate = []);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getDischargeSummaryWithAnAndHn(an: string, hn: string): Promise<DischargeSummary> {
        return new Promise((resolve, reject) => {
            const _params = { an: an.trim(), hn: hn.trim() };
            this.client = new Client("discharge-summary");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas && datas.length > 0) {
                    resolve(this.dischargeSummary = datas[0]);
                } else {
                    resolve(this.dischargeSummary = {} as DischargeSummary);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    public createDischargeSummaryTemplate(data: DischargeSummaryTemplate): Promise<boolean> {
        const user = localStorage.getItem("user");
        data.CreatedBy = user ? JSON.parse(user).username : "";
        return new Promise((resolve, reject) => {
            this.client = new Client("create-discharge-summary-template");
            this.client.create({ request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public createDischargeSummary(datas: DischargeSummary): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const object = { data: datas };
            this.client = new Client("create-discharge-summary");
            this.client.create({ request: object }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public updateDischargeSummary(data: DischargeSummary): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("update-discharge-summary");
            this.client.update({ id: "", request: data }).then((result: any) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    public get Discharge_Ipd() {
        return this.discharge_Ipd;
    }

    public get Discharge501() {
        return this.discharge501;
    }

    public get DischargeSummaryTemplates() {
        return this.dischargeSummaryTemplate;
    }

    public get DischargeSummary() {
        return this.dischargeSummary;
    }

}
