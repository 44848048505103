import { makeAutoObservable } from "mobx";
import Client from "../../../api/client";

export interface DepartmentDto {
    deptCode: string;
    deptDesc: string;
    qID?: string;
}

export class DepartmentModel {
    private state: DepartmentDto[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getAll() {
        this.client = new Client("departments");
        this.client.getAll().then((datas: DepartmentDto[]) => {
            if (datas && datas.length > 0) {
                this.state = datas;
            } else {
                this.state = [] as DepartmentDto[];
            }
        });
    }

    public async searchClinicByCode(code: string): Promise<DepartmentDto> {
        return new Promise((resolve, reject) => {
            const _params = { code: code.trim() };
            this.client = new Client("departments");
            this.client.getQuery({ query: _params }).then((datas: DepartmentDto[]) => {
                if (datas && datas.length > 0) {
                    resolve(datas[0]);
                } else {
                    resolve({} as DepartmentDto);
                }
            });
        });
    }

    public getClinicWithLimit(limit: number): Promise<DepartmentDto[]> {
        return new Promise((resolve, rejects) => {
            const _params = { limit: limit.toString() };
            this.client = new Client("departments/limit");
            this.client.getQuery({ query: _params }).then((datas: DepartmentDto[]) => {
                if (datas && datas.length > 0) {
                    this.state = datas;
                    resolve(datas);
                } else {
                    resolve([] as DepartmentDto[]);
                }
            });
        });
    }

    public searchClinicByName(name: string) {
        if (!name) {
            this.state = [] as DepartmentDto[];
            return;
        }
        const _params = { name: name.trim() };
        this.client = new Client("departments/name");
        this.client.getQuery({ query: _params }).then((datas: DepartmentDto[]) => {
            if (datas && datas.length > 0) {
                this.state = datas;
            } else {
                this.state = [] as DepartmentDto[];
            }
        });
    }

    public get clinic() {
        return this.state;
    }
}
