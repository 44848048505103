/* eslint-disable array-callback-return */
import Client from "@/app/api/client";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";

export interface VitalSignHistoryDto {
    id: number;
    rxNo?: string | null;
    visitDate: string;
    weight?: number | null;
    height?: number | null;
    dbp1?: number | null;
    dbp2?: number | null;
    sbp1?: number | null;
    sbp2?: number | null;
    temp?: number | null;
    breathe?: number | null;
    pulse?: number | null;
    smoke?: boolean | null;
    alcohol?: boolean | null;
    headCircumference?: number | null;
    maker?: string | null;
    vitalSignNo: number;
    regNo: string;
    ekg?: any | null;
    brokenBone?: boolean | null;
    Lbloodpress?: number | null;
    Hbloodpress?: number | null;
    Temperature?: number | null;
    WriteTime?: Date | null;
    treatment?: string | null;
}

export interface ReqCreateVitalSign {
    [key: string]: null | undefined | string | number | boolean | Date;
    hn: string | null;
    /** Format like this 2022-10-19 00:00:00 */
    VisitDate?: Date | null;
    VitalSignNo?: number | null;
    Weight?: number | null;
    Height?: number | null;
    Lbloodpress?: number | null;
    Hbloodpress?: number | null;
    Temperature?: number | null;
    /** @required
     * not sure but it error if not have this
     */
    Symtom?: string | null;
    /** Format YYYY-MM-DD HH?:mm?:sssZ 
     * example 2023-05-07 17?:04?:16.133
    */
    WriteTime?: Date | null;
    Breathe?: number | null;
    Pulse?: number | null;
    maker?: string;
    Treatment?: string | null;
    Smoke?: string | null;
    Alchohol?: string | null;
    Headcircumference?: number | null;
    RegNo?: string | null;
    FootRad?: string | null;
    EyeRad?: string | null;
    TeethRad?: string | null;
    EyeRemark?: string | null;
    NO_ALCOHOL?: string | null;
    NO_SMOKE?: string | null;
    EKG?: string | null;
    /** maybe fix IPD */
    SYSID?: string | null;
    PE?: string | null;
    Suggestion?: string | null;
    Note?: string | null;
    docCode?: string | null;
    BSA?: number | null;
    IsHomeOxy?: string | null;
    ACT_Value?: number | null;
    Cat_Score?: string | null;
    Act_Score?: string | null;
    mMRC?: number | null;
    Sp02?: number | null;
    Peak_Flow?: number | null;
    ADL_Value?: number | null;
    MRS_Value?: number | null;
    Swallow_Pb?: string | null;
    Undertying_DM?: string | null;
    Undertying_HT?: string | null;
    Undertying_HEART?: string | null;
    Undertying_DLP?: string | null;
    Lbloodpress2?: string | null;
    Hbloodpress2?: number | null;
    PI_exam?: string | null;
    PH?: string | null;
    FH?: string | null;
    Cheif_complaint?: string | null;
    Physical_Exam?: string | null;
    Diagnosisnote?: string | null;
    pulse2?: number | null;
    RXNO?: string | null;
    SmokePerDay_ex?: string | null;
    Alchohol_ex?: string | null;
    Smoke_ex?: string | null;
    AlchoholPerDays?: number | null;
    ManualTime?: string | null;
    brokenbone?: string | null;
    brokenbone_in6m?: string;
    brokenbone_over6m?: string | null;
    brokenbone_fracture?: string | null;
    Pregnancy?: string | null;
    Pregnancy_GA?: string | null;
    gangrene?: string | null;
    gangrene_ar?: string | null;
    Waist?: number | null;
    Hip?: number | null;
    /** หายใจออก Breath Out */
    ChestBO?: number | null;
    /** หายใจเข้า Breath In */
    ChestBI?: number | null;
    DTX_FBS?: number | null;
    WHR?: number | null;
    SmokePerDay?: number | null;
    AlchoholPerWeek?: number | null;
    Eyechoice?: string | null;
    EyeRight?: string | null;
    EyeLeft?: string | null;
    EyeVALeft?: string | null;
    EyeVARight?: string | null;
    EyeTnLeft?: string | null;
    EyeTnRight?: string | null;
    /** การให้ภูมิคุ้มกัน */
    Immune?: string | null;
}

export interface SSREGIST {
    [key: string]: null | undefined | string | number | boolean | Date;
    HospitalServiceCode?: string | null;
    /** maybe get from patient data */
    VisitDate?: Date | null;
    RecpHID?: string | null;
    SendDate?: Date | null;
    AdmitDate?: Date | null;
    An?: string | null;
    DischargeDate?: Date | null;
    hn?: string;
    OfficeCode?: string | null;
    RightService?: string | null;
    DischargeStatus?: string | null;
    DischargeType?: string | null;
    ReferHospType?: string | null;
    ReferHospital?: string | null;
    ResvRegistSS?: string | null;
    Weight?: number | null;
    Height?: number | null;
    Lbloodpress?: number | null;
    Hbloodpress?: number | null;
    Temperature?: number | null;
    Symtom?: string | null;
    Treatment?: string | null;
    TemperatureUnit?: string | null;
    VitalSignNo?: number | null;
    Breathe?: number | null;
    Pulse?: number | null;
    /** user login */
    maker?: string | null;
    WriteTime?: Date | null;
    WHR?: number | null;
    Smoke?: string | null;
    Alchohol?: string | null;
    SmokePerDay?: number | null;
    AlchoholPerWeek?: number | null;
    Waist?: number | null;
    Hip?: number | null;
    Immune?: string | null;
    Headcircumference?: number | null;
    RegNo?: string | null;
    CheckupFoot?: string | null;
    CheckupEye?: string | null;
    FootRad?: string | null;
    EyeRad?: string | null;
    TeethRad?: string | null;
    EyeRemark?: string | null;
    Eyechoice?: string | null;
    DTX_FBS?: number | null;
    NO_SMOKE?: string | null;
    NO_ALCOHOL?: string | null;
    EKG?: string | null;
    /** maybe fix in table value is OPDNS */
    SYSID?: string | null;
    PE?: string | null;
    Suggestion?: string | null;
    Note?: string | null;
    /** อาจจะเอามาจาก แพทย์เจ้าของไข้ (ไม่แน่ใจว่ามีใน query ที่ get patient ไหม) */
    docCode?: string | null;
    BSA?: number | null;
    IsHomeOxy?: string | null;
    ACT_Value?: number | null;
    Cat_Score?: string | null;
    Act_Score?: string | null;
    mMRC?: number | null;
    Sp02?: number | null;
    Peak_Flow?: number | null;
    ADL_Value?: number | null;
    MRS_Value?: number | null;
    Swallow_Pb?: string | null;
    Undertying_DM?: string | null;
    Undertying_HT?: string | null;
    Undertying_HEART?: string | null;
    Undertying_DLP?: string | null;
    Lbloodpress2?: number | null;
    Hbloodpress2?: number | null;
    RR?: number | null;
    HR?: number | null;
    DTX?: string | null;
    LMP?: string | null;
    PI_exam?: string | null;
    PH?: string | null;
    FH?: string | null;
    DTX_RBS?: number | null;
    EyeRight?: string | null;
    EyeRight_A?: string | null;
    Right_PH1?: string | null;
    Right_PH2?: string | null;
    Right_PH3?: string | null;
    Right_PH4?: string | null;
    Right_PH5?: string | null;
    EyeLeft?: string | null;
    EyeLeft_B?: string | null;
    Left_PH1?: string | null;
    Left_PH2?: string | null;
    Left_PH3?: string | null;
    Left_PH4?: string | null;
    Left_PH5?: string | null;
    NC_Right?: string | null;
    NC_Left?: string | null;
    SCHIOTZ_Right?: string | null;
    SCHIOTZ_Left?: string | null;
    Cheif_complaint?: string | null;
    Physical_Exam?: string | null;
    Diagnosisnote?: string | null;
    Pulse2?: number | null;
    /** เลขที่ใบยา (ไม่แน่ใจว่ามีใน query ที่ get patient ไหม) */
    RXNO?: string | null;
    PE_Others_normal?: string | null;
    PE_GA_normal?: string | null;
    DiagNote?: string | null;
    PE_Head_normal?: string | null;
    PE_Neck_normal?: string | null;
    PE_Chest_normal?: string | null;
    PE_Abdomen_normal?: string | null;
    PE_Extremity_normal?: string | null;
    SmokePerDay_ex?: string | null;
    Alchohol_ex?: string | null;
    Smoke_ex?: string | null;
    AlchoholPerDays?: number | null;
    EyeVALeft?: string | null;
    EyeTnLeft?: string | null;
    EyeVARight?: string | null;
    EyeTnRight?: string | null;
    ManualTime?: string | null;
    brokenbone?: string | null;
    brokenbone_in6m?: string | null;
    brokenbone_over6m?: string | null;
    brokenbone_fracture?: string | null;
    Pregnancy?: string | null;
    Pregnancy_GA?: string | null;
    gangrene?: string | null;
    gangrene_ar?: string | null;
    ChestBO?: number | null;
    ChestBI?: number | null;
    painScore?: string | null;
    Diagtext?: string | null;
    PI?: string | null;
    MounthRad?: string | null;
    DocCode1?: string | null;
    InstrumentID?: string | null;
    AppointDuration?: string | null;
}

export interface VitalSignChartDayValuesDto {
    date: string;

    hbp: number;
    lbp: number;
    temp: number;
    breathe: number;
    pulse: number;
    weight: number;
}

export interface VitalSignChartDataDto {
    labels: string[];
    datasets: any[];
}

export const validSSREGISTDbAttrs = ['hn', 'VisitDate', 'RecpHID', 'SendDate', 'AdmitDate', 'An', 'DischargeDate', 'hn', 'OfficeCode', 'RightService', 'DischargeStatus', 'DischargeType', 'ReferHospType', 'ReferHospital', 'ResvRegistSS', 'Weight', 'Height', 'Lbloodpress', 'Hbloodpress', 'Temperature', 'Symtom', 'Treatment', 'TemperatureUnit', 'VitalSignNo', 'Breathe', 'Pulse', 'maker', 'WriteTime', 'WHR', 'Smoke', 'Alchohol', 'SmokePerDay', 'AlchoholPerWeek', 'Waist', 'Hip', 'Immune', 'Headcircumference', 'RegNo', 'CheckupFoot', 'CheckupEye', 'FootRad', 'EyeRad', 'TeethRad', 'EyeRemark', 'Eyechoice', 'DTX_FBS', 'NO_SMOKE', 'NO_ALCOHOL', 'EKG', 'SYSID', 'PE', 'Suggestion', 'Note', 'docCode', 'BSA', 'IsHomeOxy', 'ACT_Value', 'Cat_Score', 'Act_Score', 'mMRC', 'Sp02', 'Peak_Flow', 'ADL_Value', 'MRS_Value', 'Swallow_Pb', 'Undertying_DM', 'Undertying_HT', 'Undertying_HEART', 'Undertying_DLP', 'Lbloodpress2', 'Hbloodpress2', 'RR', 'HR', 'DTX', 'LMP', 'PI_exam', 'PH', 'FH', 'DTX_RBS', 'EyeRight', 'EyeRight_A', 'Right_PH1', 'Right_PH2', 'Right_PH3', 'Right_PH4', 'Right_PH5', 'EyeLeft', 'EyeLeft_B', 'Left_PH1', 'Left_PH2', 'Left_PH3', 'Left_PH4', 'Left_PH5', 'NC_Right', 'NC_Left', 'SCHIOTZ_Right', 'SCHIOTZ_Left', 'Cheif_complaint', 'Physical_Exam', 'Diagnosisnote', 'Pulse2', 'RXNO', 'PE_Others_normal', 'PE_GA_normal', 'DiagNote', 'PE_Head_normal', 'PE_Neck_normal', 'PE_Chest_normal', 'PE_Abdomen_normal', 'PE_Extremity_normal', 'SmokePerDay_ex', 'Alchohol_ex', 'Smoke_ex', 'AlchoholPerDays', 'EyeVALeft', 'EyeTnLeft', 'EyeVARight', 'EyeTnRight', 'ManualTime', 'brokenbone', 'brokenbone_in6m', 'brokenbone_over6m', 'brokenbone_fracture', 'Pregnancy', 'Pregnancy_GA', 'gangrene', 'gangrene_ar', 'ChestBO', 'ChestBI', 'painScore', 'Diagtext', 'PI', 'MounthRad', 'DocCode1', 'InstrumentID', 'AppointDuration'];

export const validVitalSignDbAttrs = ['hn', 'VisitDate', 'VitalSignNo', 'RegNo2', 'Weight', 'Height', 'Lbloodpress', 'Hbloodpress', 'Temperature', 'Symtom', 'WriteTime', 'Breathe', 'Pulse', 'maker', 'Treatment', 'Smoke', 'Alchohol', 'Headcircumference', 'RegNo', 'CheckupFoot', 'FootRad', 'EyeRad', 'TeethRad', 'EyeRemark', 'Eyechoice', 'NO_ALCOHOL', 'NO_SMOKE', 'VialSignDeleteFlag', 'EKG', 'SYSID', 'PE', 'Suggestion', 'Note', 'docCode', 'BSA', 'IsHomeOxy', 'ACT_Value', 'Cat_Score', 'Act_Score', 'mMRC', 'Sp02', 'Peak_Flow', 'ADL_Value', 'MRS_Value', 'Swallow_Pb', 'Undertying_DM', 'Undertying_HT', 'Undertying_HEART', 'Undertying_DLP', 'CheckupEye', 'Lbloodpress2', 'Hbloodpress2', 'RR', 'HR', 'DTX', 'LMP', 'PI_exam', 'PH', 'FH', 'WHR', 'DTX_FBS', 'DTX_RBS', 'EyeRight', 'EyeRight_A', 'Right_PH1', 'Right_PH2', 'Right_PH3', 'Right_PH4', 'Right_PH5', 'EyeLeft', 'EyeLeft_B', 'Left_PH1', 'Left_PH2', 'Left_PH3', 'Left_PH4', 'Left_PH5', 'NC_Right', 'NC_Left', 'SCHIOTZ_Right', 'SCHIOTZ_Left', 'Cheif_complaint', 'Physical_Exam', 'Diagnosisnote', 'pulse2', 'RXNO', 'SmokePerDay_ex', 'Alchohol_ex', 'Smoke_ex', 'AlchoholPerDays', 'EyeVALeft', 'EyeTnLeft', 'EyeVARight', 'EyeTnRight', 'ManualTime', 'brokenbone', 'brokenbone_in6m', 'brokenbone_over6m', 'brokenbone_fracture', 'Pregnancy', 'Pregnancy_GA', 'gangrene', 'gangrene_ar', 'PE_Abdomen_normal', 'PE_Chest_normal', 'PE_Extremity_normal', 'PE_GA_normal', 'PE_Head_normal', 'PE_Neck_normal', 'PE_Others_normal', 'DiagNote', 'painScore', 'InstrumentID', 'AppointDuration'];

export class VitalSignModel {
    private histories: VitalSignHistoryDto[] = [];
    private chartData: VitalSignChartDataDto = { labels: [], datasets: [] };
    private _ssregistData: SSREGIST | null = { hn: '' };
    private mainVitalSignData: SSREGIST | null = { hn: '' };
    private client: Client<any>;

    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getVitalSigns(hn: string, regNo: string = '') {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("vital-sign");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    this._ssregistData = data ?? {} as SSREGIST;
                    this.mainVitalSignData = data ?? {} as SSREGIST;
                    resolve(true);
                } else {
                    resolve(true);
                }

            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * Create/Update SSREGIST & Create VitalSign record
     */
    public CreateVitalSign(vitalSign: ReqCreateVitalSign, ssregist: SSREGIST, prevSSREGISTVitalSignNo: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("vital-sign");
            const _postData = {
                hn: vitalSign.hn ? vitalSign.hn : "",
                RegNo: vitalSign.RegNo ? vitalSign.RegNo : "",
                prevSSREGISTVitalSignNo: prevSSREGISTVitalSignNo,
                ssregist: ssregist,
                vitalsign: vitalSign
            };
            this.client.create({ request: _postData }).then((data: any) => {
                resolve(data ?? false);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getVitalSignHistories(hn: string, regNo: string): Promise<VitalSignHistoryDto[]> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("vital-sign-history");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data && data.length > 0) {
                    this.histories = data.map((data: any, index: number) => {
                        return {
                            id: index,
                            // map filed with VitalSignHistoryDto
                            rxNo: data.RXNO,
                            visitDate: data.VisitDate,
                            weight: data.Weight,
                            height: data.Height,
                            fbs: data.DTX_FBS,
                            rbs: data.DTX_RBS,
                            schiotzLeft: data.SCHIOTZ_Left,
                            schiotzRight: data.SCHIOTZ_Right,
                            temperature: data.Temperature,
                            pulse: data.Pulse,
                            breathe: data.Breathe,
                            smoke: data.Smoke,
                            alchohol: data.Alchohol,
                            headcircumference: data.Headcircumference,
                            maker: data.maker,
                            vitalSignNo: data.VitalSignNo,
                            regNo: data.RegNo,
                            ekg: data.EKG,
                            brokenbone: data.brokenbone,
                            Lbloodpress: data.Lbloodpress,
                            Hbloodpress: data.Hbloodpress,
                            Symtom: data.Symtom,
                            WriteTime: data.WriteTime,
                            treatment: data.Treatment,
                            dbp1: data.Lbloodpress,
                            dbp2: data.Lbloodpress2,
                            sbp1: data.Hbloodpress,
                            sbp2: data.Hbloodpress2,
                        } as VitalSignHistoryDto;
                    });
                    this.generateVitalSignChartData();
                    resolve(this.histories);
                } else {
                    this.histories = [];
                    resolve(this.histories);
                }
            }).catch((err: any) => {
                this.histories = [];
                console.error(err);
                reject(err);
            });
        });
    }

    public getSSREGISTVitalSignNo(hn: string, regNo: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("ssregist-no");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getVitalSignNo(hn: string, regNo: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo.trim() };
            this.client = new Client("vital-sign-no");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(0);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public deleteVitalSign(hn: string, regNo: string, vitalSignNo: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.client = new Client("vital-sign");
            const _deleteData = {
                hn: hn.trim(),
                RegNo: regNo,
                VitalSignNo: vitalSignNo
            };
            this.client.deleteObject({ request: _deleteData }).then((data: any) => {
                resolve(data);
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    private generateVitalSignChartData(length: number = 30): VitalSignChartDataDto {
        const items = this.histories.slice(0, length).reverse();
        const dates = items.map(o => dayjs(o.WriteTime).format('DD/MM/BBBB'));
        const dataEachDays = items.map(o => {
            // dataset
            return {
                hbp: o.Hbloodpress ?? null,
                lbp: o.Lbloodpress ?? null,
                temp: o.Temperature ?? null,
                breathe: o.breathe ?? null,
                pulse: o.pulse ?? null,
                weight: o.weight ?? null,
            } as VitalSignChartDayValuesDto;
        });

        // generate dataset for each day
        const datasets = [
            {
                label: 'S-BP',
                data: dataEachDays.map(o => o.hbp),
                borderColor: 'rgb(255, 10, 0)',
                backgroundColor: 'rgba(255, 10, 0, 0.5)',
            },
            {
                label: 'L-BP',
                data: dataEachDays.map(o => o.lbp),
                borderColor: 'rgb(0, 200, 0)',
                backgroundColor: 'rgba(0, 200, 0, 0.5)',
            },
            {
                label: 'Temp.',
                data: dataEachDays.map(o => o.temp),
                borderColor: 'rgb(170, 170, 0)',
                backgroundColor: 'rgba(170, 170, 0, 0.5)',
            },
            {
                label: 'Breathe',
                data: dataEachDays.map(o => o.breathe),
                borderColor: 'rgb(255, 0, 255)',
                backgroundColor: 'rgba(255, 0, 255, 0.5)',
            },
            {
                label: 'Pulse',
                data: dataEachDays.map(o => o.pulse),
                borderColor: 'rgb(0, 170, 170)',
                backgroundColor: 'rgba(0, 170, 170, 0.5)',
            },
            {
                label: 'Weight',
                data: dataEachDays.map(o => o.weight),
                borderColor: 'rgb(128, 1, 255)',
                backgroundColor: 'rgba(128, 1, 255, 0.5)',
            }
        ];

        const chartData = {
            labels: dates,
            datasets: datasets,
        } as VitalSignChartDataDto;

        this.chartData = chartData;

        return this.chartData;
    }

    public get vitalSignHistories(): VitalSignHistoryDto[] {
        return this.histories;
    }

    public get vitalSignChartData(): VitalSignChartDataDto {
        return this.chartData;
    }

    public get ssregistData(): SSREGIST | null {
        return this._ssregistData;
    }

    public get mainVitalSign(): SSREGIST | null {
        return this.mainVitalSignData;
    }

    public setSsregistData(data: SSREGIST): SSREGIST | null {
        if (!data) {
            return this._ssregistData;
        }

        this._ssregistData = {
            ...this._ssregistData,
            ...data
        };

        return this._ssregistData;
    }

    public setMainVitalSignData(data: SSREGIST): SSREGIST | null {
        if (!data) {
            return this.mainVitalSignData;
        }

        this.mainVitalSignData = {
            ...this.mainVitalSignData,
            ...data
        };

        return this.mainVitalSignData;
    }
}
