import { inject } from "mobx-react";
import { Component, ReactNode } from "react";
import AppController from "../controllers/App";
import models from "../models";
import AppViewModel from "../view-model/App";

@inject(models.type.USER_MODEL, models.type.SELECT_WARD_MODEL, models.type.MEDICINE_MODEL, models.type.LAB_MODEL, models.type.XRAY_MODEL, models.type.LEARN_HOW_MODEL, models.type.PATIENT_MODEL, models.type.AUTH_MODEL)
export default class App extends Component {
    private viewModel: AppViewModel;
    constructor(props: any) {
        super(props);
        const _user = props[models.type.USER_MODEL];
        const _selectWard = props[models.type.SELECT_WARD_MODEL];
        const _medicine = props[models.type.MEDICINE_MODEL];
        const _lab = props[models.type.LAB_MODEL];
        const _xray = props[models.type.XRAY_MODEL];
        const _learnHow = props[models.type.LEARN_HOW_MODEL];
        const _patient = props[models.type.PATIENT_MODEL];
        const _auth = props[models.type.AUTH_MODEL];
        this.viewModel = new AppViewModel(_user, _selectWard, _medicine, _lab, _xray, _learnHow, _patient, _auth);
    }
    render(): ReactNode {
        return <AppController viewModel={this.viewModel} />;
    }
}