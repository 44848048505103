import { makeAutoObservable } from "mobx";
import Client from "@/app/api/client";
export interface RequestConsultOpdDto {
    an: string;
    type: 'department' | 'doctor';
    /** id of department or doctor */
    consultantId: string;
    reason: string;
}

export interface requestConsultOPD {
    hn?: string;
    qId?: string;
    qNo?: string;
    completeType?: string;
    regNo?: string;
    deptCode?: string;
    docCode?: string;
    DEPTQ: DEPTQ;
}

export interface DEPTQ {
    unProc?: string;
    updDate?: string;
    updTime?: string;
    lastQEntry?: string;
    labQ?: string;
}
export interface Deptq_d {
    completeType?: string;
}

export class RequestConsultOpdModel {
    private state: RequestConsultOpdDto[] = [];
    private client: Client<any>;

    constructor() {
        this.state = [];
        makeAutoObservable(this);
        this.client = new Client("");
    }

    public getDeptq_d(hn: string, regNo: string) {
        return new Promise((resolve, reject) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("deptq-d");
            this.client.getQuery({ query: _params }).then((datas: any) => {
                if (datas) {
                    resolve(datas);
                } else {
                    resolve(null);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }

    public getDEPTQ(qId: string) {
        return new Promise((resolve, reject) => {
            const _params = { qId: qId.trim() };
            this.client = new Client("deptq");
            this.client.getQuery({ query: _params }).then((data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(null);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }
    //#region 
    public ConsultOPD(lastUpdate: Date, currentDate: string, Deptq_d: any, DEPTQ: any, deptq_insert: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const datas = {
                hn: Deptq_d.hn,
                toQId: Deptq_d.toQId,
                toQNo: Deptq_d.toQNo,
                qStatus: Deptq_d.qStatus,
                deptCode: Deptq_d.deptCode,
                docCode: Deptq_d.docCode,
                newPat: Deptq_d.newPat,
                lastDetailNo: parseInt(Deptq_d.lastDetailNo),
                rxNo: Deptq_d.rxNo,
                fromQId: Deptq_d.fromQId,
                fromQNo: Deptq_d.fromQNo,
                regNo: Deptq_d.regNo,
                DEPTQ_Qid: DEPTQ.qId,
                unProc: DEPTQ.unProc,
                lastUpd: lastUpdate,
                updTime: DEPTQ.updTime,
                lastQEntry: DEPTQ.lastQEntry,
                currentDate: currentDate,
                data: deptq_insert
            };
            this.client = new Client("request-consult-opd");
            this.client.create({ request: datas }).then((data: any) => {
                if (data.message) {
                    resolve(data.message);
                }
            }).catch((err: any) => {
                console.error(err);
                reject(err);
            });
        });
    }
    //#endregion
    public get requestConsultOpds() {
        return this.state;
    }
}
