import { useRoutes } from "react-router-dom";
import { User } from "../app/models/domain/users/User";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

interface Props {
    user?: User;
    onLogout: () => void;
}

const AppRoutes = (props: Props) => {
    return useRoutes([MainRoutes(props), LoginRoutes]);
};
export default AppRoutes;