import { lazy } from "react";
import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";

const Login = Loadable(lazy(() => import('../app/providers/Login')));
const SelectWard = Loadable(lazy(() => import("../app/providers/SelectWard")));

const LoginRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <Login />

        },
        {
            path: 'selectward',
            element: <SelectWard />
        },
    ]
};
export default LoginRoutes;