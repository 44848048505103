
import { makeAutoObservable } from "mobx";
import Client from "@/app/api/client";
import { Bill_d } from "../xrays/Xray";
export interface RecordExpenses {
    id: string;
}

export interface DisplayRecordExpenses {
    itemNo?: string;
    typeOrderCode?: string;
    typeOrderName?: string;
    orderCode?: string;
    orderName?: string;
    price?: number;
    paid?: number;
    dateOrder?: string;
    timeOrder?: string;
    doctorOrderCode?: string;
    doctorOrderName?: string;
    IpdStatus?: string;
    discount?: boolean;
    expose?: boolean;
    rxNo?: string;
    DRG?: string;
    tag?: string;
    maker?: string;
    sysID?: string;
    fromCancel?: boolean;
    HN?: string;
    RegNo?: string;
    CompName?: string;
    MergeRefer?: string;
}

export interface TypeGroup {
    chr_code?: string;
    chr_des?: string;
    disc_flag?: string;
    family_code?: string;
    controlPrice?: string;
    vat_flag?: string;
    refund?: string;
    DRGchr_codeIpd?: string;
    DRGchr_code?: string;
}

export interface Gn_price {
    gnPriceCode?: string;
    gnPriceName?: string;
    opdPrice?: string;
    ipdPrice?: string;
    allowChange?: boolean;
    chargeCode?: string;
    chargeName?: string;
    cost?: string;
    vat_flag?: string;
}

export class RecordExpensesModel {
    private state: RecordExpenses[] = [];
    private patientRecordExpenses: DisplayRecordExpenses[] = [];
    private typeGroup: TypeGroup[] = [];
    private gn_prices: Gn_price[] = [];
    private client: Client<any>;
    constructor() {
        makeAutoObservable(this);
        this.client = new Client("");
    }


    public getPatientRecordExpenses = (hn: string, regist_flag: string) => {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regist_flag: regist_flag };
            this.client = new Client("record-patient-expenses");
            this.client.getQuery({ query: _params }).then((datas: any[]) => {
                if (datas && datas.length > 0) {
                    this.patientRecordExpenses = datas.map((data: any) => {
                        return {
                            itemNo: data.running_no,
                            typeOrderCode: data.charge_code,
                            typeOrderName: data.charge_des,
                            orderCode: data.charge_code,
                            orderName: data.charge_des,
                            price: data.amount,
                            paid: data.paidAmt,
                            dateOrder: data.date,
                            timeOrder: data.time,
                            doctorOrderCode: data.doccode,
                            doctorOrderName: data.docName && data.docLName ? data.docName + " " + data.docLName : "",
                            IpdStatus: data.ioflag,
                            discount: data.disc_flag,
                            expose: data.refund,
                            rxNo: data.rxno,
                            // DRG: data.ref_no,
                            DRG: "",
                            tag: data.ref_no,
                            maker: data.usrname,
                            sysID: data.sysID,
                            fromCancel: data.rev_itm,
                            HN: data.hn,
                            RegNo: data.regist_flag,
                            CompName: data.itemCompName,
                            MergeRefer: ""
                        };
                    });
                    resolve(this.patientRecordExpenses);
                } else {
                    this.patientRecordExpenses = [];
                    resolve(this.patientRecordExpenses);
                }
                resolve(this.patientRecordExpenses);
            }).catch((err) => {
                console.log(err);
                rejects(err);
            });
        });
    };

    public getTypeGroup = () => {
        return new Promise((resolve, rejects) => {
            this.client = new Client("chr-type-group");
            this.client.getAll().then((datas: any[]) => {
                if (datas && datas.length > 0) {
                    this.typeGroup = datas;
                } else {
                    this.typeGroup = [];
                }
                resolve(datas);
            }).catch((err) => {
                console.log(err);
                rejects(err);
            });
        });
    };

    public getGn_price = (chargeCode: string, name: string) => {
        return new Promise((resolve, rejects) => {
            const data = { chargeCode, name };
            this.client = new Client("gn-price");
            this.client.postGet({ request: data }).then((datas: any[]) => {
                if (datas && datas.length > 0) {
                    this.gn_prices = datas.map((data: any) => {
                        return {
                            gnPriceCode: data.gnPriceCode,
                            gnPriceName: data.gnPriceName,
                            opdPrice: data.opdPrice,
                            ipdPrice: data.ipdPrice,
                            allowChange: data.allowChange,
                            chargeCode: data.chargeCode,
                            chargeName: data.chr_des ? data.chr_des : "",
                            cost: data.cost
                        };
                    });
                } else {
                    this.gn_prices = [];
                }
                resolve(this.gn_prices);
            }).catch((err) => {
                console.log(err);
                rejects(err);
            });
        });
    };

    public getLastDetailBillHData(hn: string, regNo: string): Promise<any> {
        return new Promise((resolve, rejects) => {
            const _params = { hn: hn.trim(), regNo: regNo };
            this.client = new Client("record-expenses");
            this.client.getQuery({ query: _params }).then((res: any) => {
                if (res) {
                    resolve(res);
                } else {
                    resolve("");
                }
            }).catch((err: any) => {
                rejects(err);
            });
        });
    }

    public create(Runno: string, totalAmt: string, totalPaid: string, nonDiscAmt: string, Bill_d: Bill_d, createFlag: string): Promise<boolean> {
        this.client = new Client("record-expenses");
        return new Promise((resolve, rejects) => {
            const _billD = Bill_d as any;
            const datas = {
                hn: _billD.hn,
                regNo: _billD.regist_flag,
                runno: Runno,
                totalAmt: totalAmt,
                totalPaid: totalPaid,
                nonDiscAmt: nonDiscAmt,
                createFlag: createFlag,
                Bill_d: _billD
            };
            this.client.create({ request: datas }).then((res: any) => {
                if (res) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: any) => {
                rejects(err);
            });
        });
    }

    public get recordExpensess() {
        return this.state;
    }

    public get PatientRecordExpenses() {
        return this.patientRecordExpenses;
    }

    public get TypeGroup() {
        return this.typeGroup;
    }

    public get Gn_prices() {
        return this.gn_prices;
    }
}
